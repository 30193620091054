/**
 * Landing - About sections
 */

// Styles
import './AboutSections.scss';

// Dependencies
import classNames from 'classnames';
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';
import BalanceText from '../text/BalanceText';

// Components
export default class AboutSections extends CoreComponent {
  // Render
  render() {
    // Data to help with repeating parts
    const triplesSections = [
      {
        title: 'What We Do',
        bgClass: 'bg-logo-md',
        description:
          'VoteShield analyzes publicly available voter databases and mail ballot processing files to identify unexpected or improper changes that could affect the administration of, or confidence in, an election.',
        triples: [
          {
            icon: 'sparkline',
            title: 'Track Changes',
            description:
              'VoteShield tracks changes to public voter records — such as new registrations, removals, inactivations, address changes, name changes — over time and creates visualizations of these changes in order to ensure the completeness and accuracy of voter rolls.',
          },
          {
            icon: 'scatterplot',
            title: 'Recognize Patterns',
            description:
              'Using statistics and machine learning, we can highlight unusual (not necessarily problematic) changes in voter data. By providing greater visibility for election officials into these modifications, we can catch potential issues before they impact an upcoming election.',
          },
          {
            icon: 'people-network',
            title: 'Analyze Behavior',
            description:
              'Election administrators can compare changes in their data against their local population, see the prior history of impacted records (for instance, when recently removed records were previously inactivated), and have confidence that their voter rolls are secure.',
          },
        ],
      },
    ];

    // Content
    return (
      <>
        {triplesSections.map((section, si) => (
          <div
            key={section.title}
            className={classNames({ [section.bgClass]: section.bgClass })}
          >
            <div className="container-lg">
              <section>
                <div className="container-copy-large center-horizontal text-center">
                  <h1>{section.title}</h1>
                  <p className="heading-copy">
                    <BalanceText>{section.description}</BalanceText>
                  </p>
                </div>

                <div className="row triple-layout-wrapper">
                  {section.triples.map((part) => (
                    <div key={part.title} className="col-sm-4">
                      <div className="triple-layout">
                        <i
                          role="img"
                          alt="VoteShield logo"
                          className={`icon-image icon-${part.icon}`}
                        />

                        <div>
                          <h2 className="h3">{part.title}</h2>
                          <p className="mb-0">
                            <BalanceText>{part.description}</BalanceText>
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </section>
            </div>
          </div>
        ))}

        <div className="bg-logo-md-alt1">
          <div className="container-lg">
            <section className="values">
              <div className="container-copy-large center-horizontal text-center">
                <h1>Our Values</h1>
              </div>

              <div className="row triple-layout-wrapper">
                <div className="col-sm-4">
                  <div className="triple-layout">
                    <div>
                      <p className="heading-copy">
                        All qualified voters deserve to participate in{' '}
                        <strong>free & fair elections</strong>.
                      </p>
                    </div>

                    <i
                      role="img"
                      alt="VoteShield logo"
                      className="icon-image icon-candidate-vote"
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="triple-layout">
                    <div>
                      <p className="heading-copy">
                        A <strong>non-partisan approach</strong> leads to
                        complete and accurate voter registration databases.
                      </p>
                    </div>

                    <i
                      role="img"
                      alt="VoteShield logo"
                      className="icon-image icon-computer-vote"
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="triple-layout">
                    <div>
                      <p className="heading-copy">
                        Election officials are working in good faith and are{' '}
                        <strong>trusted partners</strong>.
                      </p>
                    </div>

                    <i
                      role="img"
                      alt="VoteShield logo"
                      className="icon-image icon-conversation"
                    />
                  </div>
                </div>
              </div>
            </section>

            <section className="public-trust">
              <div className="container-copy-large center-horizontal text-center">
                <h2>Improving Public Confidence</h2>
                <p className="heading-copy">
                  Spotting potential issues before they impact an election can{' '}
                  <strong>boost public confidence</strong> in elections and
                  debunk partisan narratives regarding election administration.
                </p>
              </div>
            </section>
          </div>
        </div>
      </>
    );
  }
}

/**
 * Simple component provider to check if user is logged in
 * and does not have have legal agreement done.
 */

// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { Children } from 'react';
import { Navigate } from 'react-router-dom';

import { constants, user } from '../../config';
import CoreComponents from '../../extensions/CoreComponent';

// Component
export default class AuthorizedAuthenticatedNeedsLegalAgreement extends CoreComponents {
  static propTypes = {
    redirect: PropTypes.string,
  };
  static defaultProps = {};

  render() {
    const { redirect } = this.props;

    if (
      user &&
      user.isAuthenticated() &&
      user.authorization === constants.AUTHORIZATION.AUTHENTICATED &&
      !user.hasLegalAgreedTo()
    ) {
      return Children.only(this.props.children);
    }

    return redirect ? <Navigate to={redirect} /> : '';
  }
}

const prePostComparison = {
  // Options for limiting sample size
  limitOptions: [100, 150, 200],

  // Default filter options
  defaultLimit: 100,
  defaultVisibleWhitespace: true,

  // Change types with pre/post data comparison available
  changeTypesAvailable: ['name', 'address'],
};

export default prePostComparison;

/**
 * Landing - Publication section
 */

// Styles
import './PublicationSection.scss';

// Dependencies
import React from 'react';

import publicationVoterVerificationAssessmentPaper from '../../assets/publications/VoteShield - An Assessment of Absentee Voter Verification Methods - 2022-04.pdf';
import publicationAnomalyDetectionPaper from '../../assets/publications/VoteShield - Anomaly Detection and Automated Labeling for Voter Registration File Changes - 2021-08.pdf';
import publicationGASignatureRejectionsPaper from '../../assets/publications/VoteShield - Invalid Signature Rejections in Georgia Absentee Voting - 2021-07.pdf';
import publicationNCElectionBoardLetter from '../../assets/publications/VoteShield - NC Signature Match Public Comment - 2022-07-05.pdf';
import CoreComponent from '../../extensions/CoreComponent';
import IconMaterial from '../icons/IconMaterial';
import BalanceText from '../text/BalanceText';

// Components
export default class PublicationSection extends CoreComponent {
  // Render
  render() {
    // Published papers
    const publications = [
      {
        title:
          'Public Comment Letter to the North Carolina State Board of Elections regarding Signature Verification',
        date: 'July, 2022',
        pdf: publicationNCElectionBoardLetter,
      },
      {
        title:
          'An Assessment of Absentee Voter Verification Methods: Landscape analysis and mission-driven recommendations',
        date: 'April, 2022',
        pdf: publicationVoterVerificationAssessmentPaper,
      },
      {
        title:
          'Anomaly Detection and Automated Labeling for Voter Registration File Changes',
        date: 'August, 2021',
        pdf: publicationAnomalyDetectionPaper,
      },
      {
        title:
          'Invalid Signature Rejections in Georgia Absentee Voting: Comparing the 2020 General to the 2021 Senate Runoff',
        date: 'July, 2021',
        pdf: publicationGASignatureRejectionsPaper,
      },
    ];

    // Content
    return (
      <section className="publications">
        <div className="container-copy-large center-horizontal text-center">
          <h1>
            <BalanceText>Publications</BalanceText>
          </h1>
          <p className="heading-copy">
            <BalanceText>
              VoteShield&apos;s published work on election administration and
              machine learning.
            </BalanceText>
          </p>
        </div>

        <div className="news-article-center">
          <div className="row">
            {publications.map((publication, index) => (
              <div className="col-md-4 publication" key={`pub${index}`}>
                <a href={publication.pdf}>
                  {publication.date} &mdash; {publication.title}{' '}
                  <IconMaterial id="article" />
                  <span className="sr-only">Download PDF of letter</span>
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }
}

/**
 * Login form; wraps password and mfa forms.
 */

// Dependencies
import PropTypes from 'prop-types';
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';
import MfaForm from './MfaForm';
import PasswordFrom from './PasswordForm';

// Login page componet
export default class Login extends CoreComponent {
  // Prop types
  static propTypes = {
    redirect: PropTypes.any,
    buttonClassName: PropTypes.any,
  };

  // Default props
  static defaultProps = {};

  // Default state
  state = {
    requiresMfa: false,
  };

  // When Mfa is needed
  requiresMfa = () => {
    this.setState({
      requiresMfa: true,
    });
  };

  // When Mfa is not needed
  finishedMfa = () => {
    this.setState({
      requiresMfa: false,
    });
  };

  // Main render
  render() {
    const { redirect, buttonClassName } = this.props;
    const { requiresMfa } = this.state;

    return (
      <div className="login-form">
        {!requiresMfa && (
          <PasswordFrom
            redirect={redirect}
            buttonClassName={buttonClassName}
            onRequiresMfa={this.requiresMfa}
          />
        )}
        {!!requiresMfa && (
          <MfaForm
            redirect={redirect}
            buttonClassName={buttonClassName}
            onMfaSuccess={this.finishedMfa}
            onMfaCancel={this.finishedMfa}
          />
        )}
      </div>
    );
  }
}

/**
 * Configuration for the locale matrix metrics normalizations that
 * are common to each metric (though not always available).
 *
 * TODO: Combine with the regular normalizations
 */

const localeMatricMetricsNormalizations = [
  // This is the default for demographic variables
  // {
  //   dataDefault: true,
  //   id: 'demographic-mean-multiplier',
  //   apiId: [],
  //   name: 'Mean Multipler',
  //   units: 'as compared',
  //   notes: 'This is the subpop / refpop and is used by the machine learning models.'
  // },
  {
    id: 'population-mean',
    uiDefault: true,
    apiId: ['subpop_mean'],
    name: 'Average',
    description: 'The average or mean value for all the voters affected.',
    // This is usually assumed or mentioned elsewhere
    //unitPrefix: 'average',
    useMetricConversion: true,
  },
  {
    id: 'proportion',
    uiDefault: true,
    // For categorical variables, the "mean" is actually
    // the proportion.  So, this comes through as
    // subpop_mean, but we have to manually connect them
    apiId: ['subpop_mean----alt'],
    name: 'Per Voters Affected',
    description:
      'The proportion of voters in this category compared to all the voters affected.',
    units: 'percent',
    conversion: (x) => x * 100,
  },
  {
    id: 'per-category',
    apiId: ['per_capita'],
    name: 'Per Category',
    description:
      'The voters affected in this category compared to all voters in the same category in the locale.',
    unitSuffix: 'per 1,000 of the same category',
  },
  {
    id: 'per-day-kilocapita',
    apiId: ['per_day_kilocapita'],
    name: 'Per Capita + Day',
    description:
      'Daily average voters affected per 1,000 voters in the locale.',
    unitPrefix: 'daily avg.',
    unitSuffix: 'per 1k voters',
  },
  {
    id: 'no-normalization',
    name: 'None',
    description:
      'Shows full count of voters affected, no normalization applied.',
  },
];

export default localeMatricMetricsNormalizations;

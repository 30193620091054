/**
 * Convert from a query-string string to the relavant value.
 * Handles string, null, undefined, boolean.
 *
 * @param {string} input - Value to convert
 * @returns {*} Converted value
 */
export default function toValueFromQueryString(input) {
  let parsed;
  try {
    parsed = input.match(/^(\[|\{)/i) ? JSON.parse(input) : parsed;
  }
  catch (e) {
    parsed = undefined;
  }

  return input === 'null'
    ? null
    : input === 'undefined'
      ? undefined
      : input === ''
        ? undefined
        : input === 'true'
          ? true
          : input === 'false'
            ? false
            : parsed
              ? parsed
              : input;
}

/**
 * Forgot password form.
 */

// Dependencies
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';

import { user } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';
import handleFormattedResponseErrors from '../../modules/api/handle-formatted-response-errors';
import dispatch from '../../modules/events/dispatch';
import gaEventWrapper from '../../modules/ga/event-wrapper';
import captureException from '../../modules/sentry/capture-exception';
import FormItem from '../forms/FormItem';
import FormSubmit from '../forms/FormSubmit';

// Login page componet
export default class ForgotPasswordForm extends CoreComponent {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      // We use this to set the submit button
      // disabled by default
      hasValidated: false,
    };

    // Allow override
    this.overrideStateWithProp(this.state);
  }

  // Prop types
  static propTypes = {
    onResetSuccess: PropTypes.func,
    onResetError: PropTypes.func,
  };

  // Default props
  static defaultProps = {};

  // Unmount
  componentWillUnmount() {
    this._unmounted = true;
  }

  // Validation
  validate() {
    return (values) => {
      this.setState({ hasValidated: true });
      let errors = {};

      if (!values.username) {
        errors.username = 'Username is required.';
      }

      return errors;
    };
  }

  // Initiate the reset
  reset = async (values, actions) => {
    // Login
    let response;
    try {
      response = await user.api('initiate_reset_password', {
        body: { username: values.username },
      });
      handleFormattedResponseErrors(response, 'initiate_reset_password', null);
    }
    catch (e) {
      console.error(e);
      captureException(e, {
        tags: { feature: 'user action - initiate reset password' },
      });

      // Rename any errors
      let errorOutput = e.toString();
      if (errorOutput.toString().match(/UserNotFoundException/i)) {
        errorOutput = 'Something went wrong; please try again.';
      }

      // Handle error
      actions.setErrors({ form: errorOutput });
      actions.setSubmitting(false);
      dispatch(this.props.onResetError, e, this);
      return;
    }

    // Make sure we are not unmounted
    if (this._unmounted) {
      return;
    }

    // Do something with response
    if (response && response.ok) {
      actions.setStatus({
        success: 'Reset started; look for an email or text with a code.',
      });

      actions.setSubmitting(false);
      dispatch(this.props.onResetSuccess, values, this);
    }
    else {
      actions.setErrors({
        form: 'Something unknown happened resetting your password, please contact an administrator.',
      });

      actions.setSubmitting(false);
      dispatch(this.props.onResetError, undefined, this);
    }
  };

  // Main render
  render() {
    const { hasValidated } = this.state || {};

    return (
      <Formik
        initialValues={{ username: '' }}
        validateOnBlur
        validateOnChange
        validate={this.validate()}
        onSubmit={gaEventWrapper(this.reset, {
          category: 'user',
          action: 'initiate reset password',
        })}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormItem
              id="username"
              inputType="text"
              label="Username"
              help="Your username is most likely your email address."
              helpHide={false}
              error={errors.username}
              value={values.username}
              touched={touched.username}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                required: true,
                autoComplete: 'username',
              }}
            />

            <FormSubmit
              errors={errors}
              error={errors.form}
              success={status && status.success ? status.success : null}
              hasValidated={hasValidated}
              isSubmitting={isSubmitting}
              buttonLabel="Reset"
              submittingButtonLabel="Initiating..."
              successButtonLabel="Initiated"
            />
          </form>
        )}
      </Formik>
    );
  }
}

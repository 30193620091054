/**
 * Simple component provider to check if user is not logged in
 */

// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { Children } from 'react';
import { Navigate } from 'react-router-dom';

import { user } from '../../config';
import CoreComponents from '../../extensions/CoreComponent';

// Component
export default class AuthorizedUnauthenticated extends CoreComponents {
  static propTypes = {
    redirect: PropTypes.string,
  };
  static defaultProps = {};

  render() {
    const { redirect } = this.props;

    if (!user || !user.isAuthenticated()) {
      return Children.only(this.props.children);
    }

    return redirect ? <Navigate to={redirect} /> : '';
  }
}

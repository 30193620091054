// Dependencies
import defaults from 'lodash/defaults';

import toMoment from '../convert/to-moment';

/**
 * Tries to convert to date and uses Moment to format
 *
 * @param {*} input Input to convert and format to number
 * @param {object} [options] Options to use
 * @param {string} [options.format='Y-M-D'] Moment formatter string
 *   TODO: This should be something 'YYYY-MM-DD', but this is current default
 * @param {boolean} [options.handleError=true] If true, will output the notValid
 *   option.
 * @param {string} [options.notValid=''] What to display if a moment is not found.
 * @param {string} [options.inputFormat] How to parse a string
 * @param {string} [options.utc=true] Whether to assume utc
 * @param {string} [options.timestamp=milliseconds] Type of timestamp to use,
 *   defaults to 'milliseconds', but can be 'seconds'
 * @returns {string} Formatted date or notValid option
 */
export default function outputDate(input, options = {}) {
  // Options
  options = defaults(options, {
    format: 'Y-M-D',
    handleError: true,
    notValid: '',
    utc: true,
    timestamp: 'milliseconds',
  });

  // Convert
  try {
    return toMoment(
      input,
      options.inputFormat,
      options.utc,
      options.timestamp,
    ).format(options.format);
  }
  catch (e) {
    if (options.handleError) {
      return options.notValid;
    }

    throw e;
  }
}

/**
 * Landing page content
 */

// Dependencies
import React from 'react';

import CoreComponent from '../../../extensions/CoreComponent';
import PageTitle from '../../document/PageTitle';
import LandingContent from '../../landing/LandingContent';

// Main component
export default class LandingPage extends CoreComponent {
  static propTypes = {};
  static defaultProps = {};
  state = {};

  // Main render
  render() {
    return (
      <>
        <PageTitle title="Home" />
        <LandingContent />
      </>
    );
  }
}

// Dependencies
import booleanMapToArray from '../convert/boolean-map-to-array';
import fromArrayToQueryString from './from-array';

/**
 * Convert from boolean Map to query-string string.
 *
 * @param {any} input Value to convert to query string, ideally a boolean Map
 * @param {boolean} handleEmpty Whether to represent an empty value or not
 * @returns {string} Converted string value
 */
export default function fromBooleanMapToQueryString(
  input,
  handleEmpty = false,
) {
  let a = booleanMapToArray(input);
  return a && a.length
    ? fromArrayToQueryString()(a)
    : handleEmpty
      ? 'null'
      : '';
}

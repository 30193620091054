/**
 * A core component that can be the base for most other
 * components.
 */

// Dependencies
import assign from 'lodash/assign';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isPlainObject from 'lodash/isPlainObject';
import keys from 'lodash/keys';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import React, { Component } from 'react';

export default class CoreComponent extends Component {
  /**
   * Override setState to be able to validate state
   * properties.  This seems to be the only way to validate
   * state without a custom method, or some how using
   * lifecycle hooks (and rendering more than once)
   *
   * @param {object|Function} updater Updated function or object, like React.setState
   * @param {Function} callback Callback function when state is done setting
   * @returns {*} Returns whatever setState returns
   */
  setState(updater, callback) {
    if (!this._validateState) {
      return super.setState(updater, callback);
    }

    return super.setState((stagingState, stagingProps) => {
      let updated = isFunction(updater)
        ? updater(stagingState, stagingProps)
        : updater;
      let combinedStateToCompare = assign(
        {},
        stagingState || {},
        updated || {},
      );

      return !updated
        ? null
        : this._validateState(
          updated,
          combinedStateToCompare,
          stagingState,
          stagingProps,
        ) || null;
    }, callback);
  }

  /**
   * Validate state wrapper.
   *
   * @param {...} args Arguments to use
   * @param {object} args.newState New state to validate
   * @param {object} args.combinedStateToCompare Combined new state and existing staging state
   * @param {object} args.stagingState Staging state
   * @param {object} args.stagingProps Staging props
   * @returns {*} Returns the new state to stage
   */
  validateState(...args) {
    if (isFunction(this._validateState)) {
      return !args[1]
        ? this._validateState(args[0], args[0])
        : this._validateState(...args);
    }

    return args[0];
  }

  /**
   * Allow for props property to override/merge with state,
   * intended for constructor.
   *
   * @param {object} state State of the component
   * @param {string} property Name of property in this.props to use
   * @returns {object} Returns the altered state
   */
  overrideStateWithProp(state, property = 'initialState') {
    if (this.props && isPlainObject(this.props[property])) {
      state = merge(this.props[property], state);
    }

    return state;
  }

  /**
   * Render a prop assuming its a function or
   * an element.
   *
   * @param {Function|React.element} prop Prop to render
   * @param {...} params Parameters to pass
   * @returns {React.Element} Element to render
   */
  renderProp(prop, ...params) {
    return isFunction(prop) ? prop(...params) : prop ? prop : '';
  }

  /**
   * A simple async function to wait until setState is done
   *
   * @async
   * @returns {Promise} Promise resolves to when setState is done
   */
  async setStateTick() {
    return new Promise((resolve) => {
      super.setState(() => {
        resolve();
      });
    });
  }

  /**
   * A simple async function to get state.  This should not be used
   * unless necessary.
   *
   * @async
   * @returns {Promise} Promise resolves to when setState is done
   */
  async getState() {
    return new Promise((resolve) => {
      super.setState((state) => resolve(state));
    });
  }

  /**
   * Set state if values in new state are different
   *
   * @param {object} newState - New state to check
   */
  setStateIfDifferent(newState = {}) {
    this.setState((state) => {
      if (isEqual(pick(state, keys(newState)), newState)) {
        return null;
      }

      return newState;
    });
  }

  /**
   * Clear URL
   */
  urlQueryClear() {}
}

/**
 * User Mfa form.
 */

// Dependencies
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';

import { constants, supportEmail, user } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';
import dispatch from '../../modules/events/dispatch';
import gaEventWrapper from '../../modules/ga/event-wrapper';
import captureException from '../../modules/sentry/capture-exception';
import FormItem from '../forms/FormItem';
import EmailLink from '../links/EmailLink';

// Login page componet
export default class MfaForm extends CoreComponent {
  constructor(props) {
    super(props);
    this.codeInputRef = React.createRef();
  }

  // Prop types
  static propTypes = {
    onMfaSuccess: PropTypes.func,
    onMfaError: PropTypes.func,
    onMfaCancel: PropTypes.func,
    buttonClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  // Default props
  static defaultProps = {};

  // Default state
  state = {
    // We use this to set the submit button
    // disabled by default
    hasValidated: false,
  };

  // On mount
  componentDidMount() {
    // Given that this is the main action that should be taken
    // when it comes up, we auto-focus to it:
    this.codeInputRef.current.focus();
  }

  // Unmount
  componentWillUnmount() {
    this._unmounted = true;
  }

  // Validation
  validate() {
    return (values) => {
      this.setState({ hasValidated: true });
      let errors = {};

      if (!values.code) {
        errors.code = 'Authentication code is required.';
      }

      return errors;
    };
  }

  // Login
  login = async (values, actions) => {
    // Login
    try {
      await user.authenticateMfa(values.code, {
        redirect: this.props.redirect,
      });

      // TODO: The user has events such as "login" that may
      // be used to redirect when logging in, but if that
      // is the case, we don't want to do anything below here.
    }
    catch (e) {
      console.error(e);
      captureException(e, {
        tags: { feature: 'user action - mfa authentication' },
      });

      // Handle error
      actions.setErrors({ form: e.toString() });
      actions.setSubmitting(false);
      dispatch(this.props.onMfaError, e, this);
      return;
    }

    // Make sure we are not unmounted
    if (this._unmounted) {
      return;
    }

    // Check if authentication
    if (user.authorization === constants.AUTHORIZATION.AUTHENTICATED) {
      actions.resetForm();
      actions.setStatus({ success: 'Authentication successful.' });

      actions.setSubmitting(false);
      dispatch(this.props.onMfaSuccess, this);
    }
    else {
      actions.setErrors({
        form: 'Something unknown happened with authenticating.',
      });

      actions.setSubmitting(false);
      dispatch(this.props.onMfaError, this);
    }
  };

  // Cancel
  cancel = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    dispatch(this.props.onMfaCancel, this);
  };

  // Main render
  render() {
    // Help text depending on method
    let codeHelp =
      user.authorizationMfaType === 'sms'
        ? 'An authentication code should have been sent to your phone via SMS (text message)'
        : 'A time-based one time password is a available on the application that you setup with your account.';

    return (
      <Formik
        initialValues={{ code: '' }}
        validateOnBlur
        validateOnChange
        validate={this.validate()}
        onSubmit={gaEventWrapper(this.login, {
          category: 'user',
          action: 'mfa authenticate',
        })}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormItem
              id="code"
              inputType="text"
              label="Authentication Code"
              help={codeHelp}
              helpHide={false}
              error={errors.code}
              value={values.code}
              touched={touched.code}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                required: true,
                autoComplete: 'one-time-code',
                ref: this.codeInputRef,
              }}
            />

            {errors.form && (
              <small className="error">
                {errors.form} If you need help, please{' '}
                <EmailLink
                  email={supportEmail}
                  subject="Help with logging in"
                  body={`I received the following error trying to log in: ${errors.form}\n`}
                >
                  contact support
                </EmailLink>
                .
              </small>
            )}

            {status && status.success && <small>{status.success}</small>}

            <button
              type="submit"
              className={this.props.buttonClassName}
              disabled={
                !this.state.hasValidated || !isEmpty(errors) || isSubmitting
              }
            >
              {isSubmitting ? 'Submitting...' : 'Authenticate'}
            </button>

            <button
              type="button"
              className={this.props.buttonClassName}
              disabled={isSubmitting}
              onClick={this.cancel}
            >
              Cancel
            </button>
          </form>
        )}
      </Formik>
    );
  }
}

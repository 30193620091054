/**
 * Takes a Promise response from a window.fetch and wraps
 * it and attaches status and ok.
 *
 * @param {Promise} response Fetch promise response object
 * @returns {Promise} Resolves to formatted response object with attached
 *   meta data included ok and status
 */
export default async function formatResponse(response) {
  if (!response) {
    throw new Error('Response not an object.');
  }
  if (!response.json) {
    throw new Error(
      'Response does not have a valid json method, may not be a fetch response.',
    );
  }

  // Make formatted response
  let formatted = {
    status: response.status,
    ok: response.ok,
  };

  // Attempt json
  try {
    formatted.json = await response.clone().json();
  }
  catch (e) {
    formatted.json = false;
    formatted.text = await response.text();
  }

  return formatted;
}

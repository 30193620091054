/**
 * Checks if string (or stringable) has a numeric character
 * in it (0-9).
 *
 * @param {string} input String input to check
 * @param {number} length Minimum number of numbers to find
 * @returns {boolean} Whether validated or not
 */
export default function hasNumbers(input, length = 1) {
  if (!input || !input.toString) {
    return false;
  }

  let matches = input.toString().match(/[0-9]/g) || [];
  return matches.length >= length;
}

/**
 * Logout component.
 */

// Dependencies
import isString from 'lodash/isString';
import React from 'react';

import { user } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';
import AlertBox from '../alerts/AlertBox';

// Login page componet
export default class Logout extends CoreComponent {
  // Prop types
  static propTypes = {};

  // Default props
  static defaultProps = {};

  // Default state
  state = {
    loggingOut: false,
    loggedOut: false,
    errorLoggingOut: null,
  };

  // On mount
  componentDidMount() {
    this.logout();
  }

  // On unmount
  componentWillUnmount() {
    // Since the logout will do redirect, we don't
    // want to try to re-render
    this._willUnmount = true;
  }

  // Log user out
  async logout() {
    if (!user) {
      return;
    }

    this.setState({
      loggingOut: false,
      loggedOut: false,
      errorLoggingOut: null,
    });

    // Check if currently logging out
    if (user.isLoggingOut) {
      this.setState({
        loggingOut: true,
        loggedOut: false,
        errorLoggingOut: 'Already logging out',
      });

      return;
    }

    // Sleep for a better UX experience
    await this.sleep(2000);

    // Actual logout
    let loggedOut = false;
    try {
      loggedOut = await user.logout();
    }
    catch (e) {
      // Logout method already sends exceptions to sentry
      this.setState({
        loggingOut: false,
        loggedOut: false,
        errorLoggingOut: e.toString(),
      });

      return;
    }

    // Set state
    if (!this._willUnmount) {
      this.setState({
        loggingOut: false,
        loggedOut,
        errorLoggingOut: loggedOut === false ? true : false,
      });
    }
  }

  // Sleep
  async sleep(time) {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), time);
    });
  }

  // Main render
  render() {
    // Shortcuts
    const { loggingOut, loggedOut, errorLoggingOut } = this.state;

    // Currently logging out
    if (!loggingOut && errorLoggingOut) {
      return (
        <div className="logout-message error-logging-out">
          <AlertBox
            variant="error"
            closable={false}
            title="Error logging out"
            description={
              <p>
                There was an error logging you out of our system securely.{' '}
                {isString(errorLoggingOut) ? errorLoggingOut : ''}
              </p>
            }
          />
        </div>
      );
    }
    else if (!loggingOut && loggedOut) {
      return (
        <div className="logout-message logged-out">
          <AlertBox
            variant="success"
            closable={false}
            title="Successfully logged out"
          />
        </div>
      );
    }

    return (
      <div className="logout-message logging-out">
        <AlertBox
          variant="loading"
          closable={false}
          title="Logging out"
          progress="indeterminate"
        />
      </div>
    );
  }
}

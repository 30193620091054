/**
 * A wrapper around inputs mostly to alleviate boilerplate code
 */

// Dependencies
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';
import FormItemLayout from './FormItemLayout';

// Components
export default class FormItem extends CoreComponent {
  // Prop types
  static propTypes = {
    id: PropTypes.string.isRequired,
    name: PropTypes.string,
    variant: PropTypes.oneOf(['', 'split', 'split-small']),
    label: PropTypes.string.isRequired,
    labelHide: PropTypes.bool,
    help: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    helpHide: PropTypes.bool,
    error: PropTypes.string,
    touched: PropTypes.bool,
    disabled: PropTypes.bool,
    readonly: PropTypes.bool,
    inputType: PropTypes.string,
    inputClassName: PropTypes.any,
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    inputProps: PropTypes.object,
    extraHelpIds: PropTypes.string,
    afterInput: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    beforeInput: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    top: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    bottom: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  };

  // Default props
  static defaultProps = {
    inputType: 'text',
    labelHide: false,
    helpHide: false,
    disabled: false,
    readonly: false,
    touched: false,
    error: '',
  };

  // Render
  render() {
    // Short cuts
    const {
      id,
      name,
      variant,
      label,
      labelHide,
      help,
      helpHide,
      error,
      touched,
      inputType,
      inputProps,
      value,
      disabled,
      readonly,
      onChange,
      onBlur,
      extraHelpIds,
      top,
      bottom,
      inputClassName,
      afterInput,
      beforeInput,
    } = this.props;

    // Input props shortcust
    const { required } = inputProps || {};

    return (
      <FormItemLayout
        variant={variant}
        top={
          <>
            <label
              htmlFor={id}
              className={classNames({ 'sr-only': labelHide })}
            >
              {label}
              {!required && <span className="sub-label"> - Optional</span>}
            </label>

            {help && (
              <small
                id={`${id}-help`}
                className={classNames({ 'sr-only': helpHide })}
              >
                {help}
              </small>
            )}
            {top && this.renderProp(top)}
          </>
        }
        beforeInput={
          <>
            {touched && error && (
              <small id={`${id}-error`} className="error" role="alert">
                {error}
              </small>
            )}

            {beforeInput && this.renderProp(beforeInput)}
          </>
        }
        input={
          <>
            {this.props.children || (
              <input
                id={id}
                name={name}
                aria-describedby={`${id}-help ${id}-error ${extraHelpIds}`}
                aria-invalid={touched && error ? 'true' : ''}
                type={inputType}
                className={classNames(
                  {
                    error: touched && error,
                    valid: touched && !error,
                  },
                  inputClassName,
                )}
                value={value}
                onChange={onChange}
                onBlur={onBlur}
                disabled={disabled}
                readOnly={readonly ? 'readonly' : false}
                {...inputProps}
              />
            )}
          </>
        }
        afterInput={<>{afterInput && this.renderProp(afterInput)}</>}
        bottom={<>{bottom && this.renderProp(bottom)}</>}
      />
    );
  }
}

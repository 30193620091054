/**
 * Error report button
 */

// Dependencies
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { bugReportEmail } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';
import EmailLink from './EmailLink';

// Components
export default class ErrorReportButton extends CoreComponent {
  // Prop types
  static propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    email: PropTypes.string,
    error: PropTypes.string,
    subject: PropTypes.string,
    section: PropTypes.string,
  };

  // Prop defaults
  static defaultProps = {
    email: bugReportEmail,
    subject: 'Ran into an error on VoteShield',
  };

  // Render
  render() {
    // Shortcuts
    let { className, children, email, subject, body, error, section } =
      this.props;

    // Make body
    body =
      body ||
      `
Dear VoteShield,

I was doing ....

${
  section ? 'In the "' + section + '" section, ' : ''
}I ran into the error on the following page:
${document.location.href}

Error:
${error.toString()}
    `;

    return (
      <EmailLink
        email={email}
        subject={subject}
        body={body}
        variant="button"
        className={classNames('error', className)}
      >
        {children ? children : 'Report bug'}
      </EmailLink>
    );
  }
}

/**
 * Manage the query string, aimed at keeping a
 * components state in sync with the query string
 */

// Dependencies
import clone from 'lodash/clone';
import debounce from 'lodash/debounce';
import _each from 'lodash/each';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import isNil from 'lodash/isNil';
import isRegExp from 'lodash/isRegExp';
import omit from 'lodash/omit';
import pick from 'lodash/pick';

import history from '../../config/history';
import fromValueToQueryString from './from-value';
import queryStringIsEmpty from './is-empty';
import toValueFromQueryString from './to-value';

// Object to manage IDs so they don't overlap
let globalIds = {};

// Query string manager
export default class QueryStringManager {
  /**
   * Create a query string manager object.
   *
   * @param {object} options Options
   * @param {string} options.id Id that will get prefixed to the
   *   query string key.  Will add suffix if already in use.
   *   Note that id with suffix won't be guaranteed.
   * @param {boolean} options.singular Whether the component is assumed
   *   to be the only one rendered on the page.  This means a
   *   suffix won't be created.  Default is true.
   *   TODO: Maybe there is a way to get an ID of a component
   *   that is consistent, even if it gets removed and re-rendered.
   * @param {Array} options.include Array of properties to include
   *   to set the query string by and read the query string from.
   *   Note that include and exclude options should not be used
   *   together.  Include will supercede exclude.
   * @param {Array} options.exclude Array of properties to exclude
   *   to set the query string by and read the query string from.
   *   Note that include and exclude options should not be used
   *   together.  Include will supercede exclude.
   * @param {object} options.serialization Object describing how
   *   to serialize and unserialize a value.  Key should be property
   *   that is getting used.  And value shoudl be an array where
   *     - First value is how to go from data to query string
   *     - Second value is how to from query string to data
   * @param {RegExp|Function} options.canWrite A test on the path
   *   or a function on whether write can update the query parameters
   * @param {boolean} options.noop Turn on to not do any actual actions; useful
   *   to turn off without changing implementation code.
   * @param {object} options.defaults Object of default values for
   *   when properties are not available in the query string.
   */
  constructor(options = {}) {
    // Defaults
    options.singular = options.singular === false ? false : true;

    // Generate properties
    this.id = this.makeId(options.id, options.singular);
    this.defaults = options.defaults || {};
    this.serialization = options.serialization || {};

    // Add options
    this.options = options;

    // Make sure write only happens so often.  For instance, if
    // a variable is tied to a text change event, it would happen too
    // often.
    //
    // We also want to make sure that if there is an read, there
    // isn't an instance write.
    //
    // This might cause a state not being written, but we will
    // still end with a state/url write
    this.write = debounce(this.write.bind(this), 500);
  }

  /**
   * Make id.  Add suffix if already used.
   *
   * @param {string} id Base id
   * @param {boolean} singular Is singular, and should not generate unique Id
   * @returns {string} New id.
   */
  makeId(id, singular = true) {
    id = id || 'component';

    if (singular) {
      return id;
    }

    // Make sure the id is not overlapping
    globalIds[id] =
      globalIds[id] === 0 ? 1 : !globalIds[id] ? 0 : globalIds[id] + 1;
    return `${id}${globalIds[id] ? globalIds[id] : ''}`;
  }

  /**
   * Listen for URL changes
   *
   * @param {Function} callback Callback function when URL changes
   * @returns {Function} Function to stop listening
   */
  listen(callback) {
    if (this.options.noop) {
      return;
    }

    this.unlisten();

    this._listener = (location, action) => {
      if (callback) {
        callback(this.read(), location, action);
      }
    };

    return window.addEventListener('popstate', this._listener, false);
  }

  /**
   * Stop listening Listen for URL changes
   */
  unlisten() {
    if (this._listener) {
      window.removeEventListener('popstate', this._listener);
    }
  }

  /**
   * Read values from query
   *
   * @returns {object} Values from query
   */
  read() {
    if (this.options.noop) {
      return {};
    }

    // Get params
    let currentUrl = new URL(window.location);
    let searchParams = currentUrl.searchParams || {};

    // Initialize values
    let values = this.filteredValues(clone(this.defaults));

    // Go through params
    for (let [key, value] of searchParams) {
      // Make sure the property is for this manager
      if (key.indexOf(`${this.id}-`) === 0) {
        let valueKey = key.replace(`${this.id}-`, '');
        if (this.isValidProperty(valueKey)) {
          // Unserialize
          values[valueKey] = this.serialization[valueKey]
            ? this.serialization[valueKey][1](value)
            : toValueFromQueryString(value);
        }

        // TODO: Delete param from URL if not include/excluded
      }
    }

    return values;
  }

  /**
   * Update URL from values
   *
   * @param {object} values Object of values to update from, if this used in a
   *   react component
   * @param {object} prevValues If provided, will try to compare to see if
   *   anything has changed before updating URL
   * @param {boolean} replace Whether to use the history replace method
   *   or the push method
   * @returns {boolean} Whether or not values had changes and if the
   *   url was altered
   */
  write(values, prevValues, replace = false) {
    if (this.options.noop) {
      return false;
    }

    let filteredValues = this.filteredValues(values);

    // Ideally would like to test out here, but things like Map
    // are always the same.
    // Check previous values
    // if (prevValues && !hasDifferentProps(filteredValues, this.filteredValues(prevValues))) {
    //   return;
    // }

    // Convert
    _each(filteredValues, (value, key) => {
      filteredValues[key] = this.serialization[key]
        ? this.serialization[key][0](value)
        : fromValueToQueryString(value);
    });

    // Don't set values that are explicity undefined
    _each(filteredValues, (value, key) => {
      if (value === undefined) {
        delete filteredValues[key];
      }
    });

    // Get current query string
    let currentUrl = new URL(window.location);
    let searchParams = currentUrl.searchParams || {};

    // Go through each item and change if necessary
    let changed = false;
    _each(filteredValues, (value, key) => {
      // URL key
      let scopedKey = `${this.id}-${key}`;

      // Get current value
      let currentParam = searchParams.get(scopedKey);

      // Update if needed
      if (
        !(queryStringIsEmpty(currentParam) && queryStringIsEmpty(value)) &&
        !isEqual(currentParam, value)
      ) {
        if (isNil(value) || value === '') {
          searchParams.delete(scopedKey);
        }
        else {
          searchParams.set(scopedKey, value);
        }
        changed = true;
      }
    });

    // Only update if different
    if (changed && this.canWrite()) {
      history[replace ? 'replace' : 'push']({
        search: `?${searchParams.toString()}`,
      });
    }

    return changed;
  }

  /**
   * Using options.canWrite, determines whether can write.
   *
   * @returns {boolean} Whether can write
   */
  canWrite() {
    if (this.options.noop) {
      return true;
    }

    return !this.options.canWrite
      ? true
      : isRegExp(this.options.canWrite) && window.location.pathname
        ? !!window.location.pathname.match(this.options.canWrite)
        : isFunction(this.options.canWrite)
          ? !!this.options.canWrite()
          : true;
  }

  /**
   * Clear URL
   *
   * @param {boolean} replace Whether to use the history replace or
   *   push method.
   */
  clear(replace = true) {
    let currentUrl = new URL(window.location);
    let searchParams = currentUrl.searchParams || {};

    for (let [key] of searchParams) {
      if (key.indexOf(`${this.id}-`) === 0) {
        searchParams.delete(key);
      }
    }

    history[replace ? 'replace' : 'push']({
      search: `?${searchParams.toString()}`,
    });
  }

  /**
   * Check if filtered item given the include and exclude options.
   *
   * @param {string} name Name of property to search for
   * @returns {boolean} Whether is a valid item
   */
  isValidProperty(name) {
    const include = this.options.include;
    const exclude = this.options.exclude;

    if (include && include.length && ~include.indexOf(name)) {
      return true;
    }
    else if (exclude && exclude.length && !~exclude.indexOf(name)) {
      return true;
    }

    return false;
  }

  /**
   * Get filtered items (propsrties from an object).  Uses the include
   * or exclude options set in the constructor.  Prioritizes include over
   * exclude option.
   *
   * @param {object} values The object to filter
   * @returns {object} Filtered object.
   */
  filteredValues(values = {}) {
    if (this.options.include && this.options.include.length) {
      return pick(values, this.options.include);
    }

    return omit(values, this.options.exclude);
  }
}

/**
 * Access denied
 */

// Dependencies
import PropTypes from 'prop-types';
import React from 'react';

import { supportEmail } from '../../../config';
import CoreComponent from '../../../extensions/CoreComponent';
import PageTitle from '../../document/PageTitle';
import EmailLink from '../../links/EmailLink';

// Component
export default class AccessDeniedPage extends CoreComponent {
  static propTypes = {
    title: PropTypes.string,
    message: PropTypes.string,
  };

  static defaultProps = {
    title: 'Access denied',
    message: 'You do not have access to this page.',
  };

  // Main render
  render() {
    return (
      <div className="container-lg mb-4">
        <PageTitle title={this.props.title} />

        <h1 className="page-title">{this.props.title}</h1>

        <p>
          {this.props.message} If you believe that this is an error, please{' '}
          <EmailLink
            email={supportEmail}
            subject="Access denied issue"
            body={`I received an Access Denied on VoteShield, but believe this may be an error.  I was visiting:\n${window.location.href}`}
          >
            contact support
          </EmailLink>
          .
        </p>
      </div>
    );
  }
}

// Special characters, here so we can attach to validator
const specialCharacters = ' !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~';

/**
 * Checks if string (or stringable) has a special character.
 *
 * @param {string} input String input to check
 * @param {number} length Minimum number of characters to find
 * @returns {boolean} Whether validated or not
 */
function hasSpecialCharacters(input, length = 1) {
  if (!input || !input.toString) {
    return false;
  }

  let reg = new RegExp(`[${specialCharacters.replace(/\\/g, '\\\\\\')}]`, 'g');
  let matches = input.toString().match(reg) || [];
  return matches.length >= length;
}

// Exports
hasSpecialCharacters.specialCharacters = specialCharacters;
export default hasSpecialCharacters;

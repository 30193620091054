/**
 * Set of functions to interact with Google Analytics.  We create wrappers
 * for the functions we use so that we can easily swap out the underlying
 * code as well as document what GA4 actually wants.
 *
 * Originally we had used react-ga, but this seems to be unmaintained and
 * Google Analytics has switched to version 4 which is a breaking change.
 * There is something like react-ga4, isn't actually a good drop-in
 * replacement and also doesn't seem to actually be accurate for the GA4 API.
 *
 * https://developers.google.com/tag-platform/gtagjs/reference
 */

// Dependencies
import isNumber from 'lodash/isNumber';

// Top level var for holding some data across functions without
// doing a class or singleton.
let googleAnalytics = {};

/**
 * Wrapper for gtag
 */
function gtag() {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(arguments);
}

/**
 * Initialize Google Analytics
 *
 * @param {string} googleAnalyticsId ID for GA account/property
 * @param {object} options Object of options to send through
 * @param {object} options.testMode If true, then don't initialize GA
 * @param {object} options.debugMode If true, Set debug mode on GA
 */
function initializeGoogleAnalytics(googleAnalyticsId, options = {}) {
  googleAnalytics.googleAnalyticsId = googleAnalyticsId;

  // GA wants this, though in debug, this comes up as invalid. :/
  gtag({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });

  // Add script to end of body
  const script = document.createElement('script');
  script.async = true;
  script.src = `https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`;
  if (options.nonce) {
    script.setAttribute('nonce', options.nonce);
  }

  // Don't actually include for testing
  if (!options.testMode) {
    document.body.appendChild(script);

    // Mark as initialized
    googleAnalytics.initiated = true;
  }

  // Initial entries
  gtag('js', new Date());
  gtag('config', googleAnalyticsId, { debug_mode: !!options.debugMode });
}

/**
 * Generic events for GA.
 *
 * https://developers.google.com/tag-platform/gtagjs/reference#event
 * https://developers.google.com/analytics/devguides/collection/ga4/events?client_type=gtag&sjid=7024534276548923017-NA
 * https://support.google.com/analytics/answer/12229021
 *
 * @param {string} name Name of event
 * @param {object} options Options to send through
 */
function gaEvent(name, options = {}) {
  // Make sure we have some specific values
  if (!name) {
    throw new Error('Must provide name for GA event.');
  }

  gtag('event', name, options);
}

/**
 * Generic events that align more with the UA API.  Ideally we would
 * move to having more specific and standardized properties, but this
 * should be reasonable for now.
 *
 * https://developers.google.com/tag-platform/gtagjs/reference#event
 *
 * @param {string} name Name of event, such as "click", "save", "review", "sort"
 * @param {object} options Options to send through
 * @param {string} options.category Name of category in breadcrumb
 *   section format with dashes, such as "ballots - election - addresses table"
 * @param {string} options.label (optional) Label for the event, such
 *   as "ascending" or "descending" or "name" or "email"
 * @param {number} options.value Integer value for event where appropriate
 * @param {boolean} options.interactive Whether event is a non-interactive,
 *   user-initiated event (defaults to true)
 */
function gaUaEquivalentEvent(name, options = {}) {
  // Make sure we have some specific values
  if (!name) {
    throw new Error('Must provide name for GA event.');
  }

  gaEvent(name, {
    event_category: options.category,
    event_label: options.label,
    value: options.value,
    non_interaction: options.interactive === false ? true : false,
  });
}

/**
 * Send a page view to GA.
 * https://developers.google.com/tag-platform/gtagjs/reference/events#page_view
 *
 * @param {string|object} optionsOrPath Can be location or an object with
 *   options
 * @param {string} optionsOrPath.location Location to send to GA
 * @param {string} optionsOrPath.title Title to send to GA
 */
function gaPageView(optionsOrPath) {
  let options = optionsOrPath;
  if (typeof optionsOrPath === 'string') {
    options = { location: optionsOrPath };
  }
  if (!options || !options.location) {
    throw new Error('Must provide location for GA page view.');
  }

  gtag('event', 'page_view', {
    page_location: options.location,
    page_title: options.title,
  });
}

/**
 * Send an API timing to GA.
 *
 * Timing used to be in UA, but is not a specific event in GA4.
 *
 * @param {object} options Options to send through
 * @param {string} options.name Name of timing, such as "load time"
 * @param {string} options.endpoint Route to endpoint
 * @param {number} options.ms Time number value
 */
function gaApiTiming(options = {}) {
  // Make sure we have some specific values.  For tests, it might
  // happen so fast that the value is 0.
  if (!options || !options.name || !isNumber(options.ms)) {
    throw new Error('Must provide name and value for GA timing.');
  }

  gtag('event', 'timing_api', {
    timing_name: options.name,
    timing_endpoint: options.endpoint,
    timing_ms: options.ms,
  });
}

/**
 * Send login event to GA.
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/events?sjid=7024534276548923017-NA&client_type=gtag#login
 *
 * @param {string} method Method to send to login even
 */
function gaLogin(method = 'username/password') {
  gtag('event', 'login', { method });
}

/**
 * Send an exception to GA.  In general, make sure exceptions go to
 * Sentry and use this sparingly.
 *
 * Shows up here, but not in API reference:
 * https://support.google.com/analytics/answer/11150547?hl=en
 * https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions
 *
 * @param {string} description Description for exception
 * @param {boolean} fatal Whether exception is fatal
 */
function gaException(description, fatal = false) {
  // Make sure we have some specific values
  if (!description) {
    throw new Error('Must provide description for GA exception.');
  }

  gtag('event', 'exception', {
    description,
    fatal,
  });
}

/**
 * Wrapper around set
 * https://developers.google.com/tag-platform/gtagjs/reference#set
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/config
 *
 * @param {object} options Options to send through
 */
function gaSet(options = {}) {
  gtag('set', options);
}

/**
 * Set user properties
 * https://support.google.com/analytics/answer/9355671?hl=en
 * https://support.google.com/analytics/answer/12370404?sjid=15259056839976151146-EU#zippy=%2Cgoogle-tag-websites
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_id
 *
 * Custom dimensions have to be defined in GA, and can be managed here:
 * https://analytics.google.com/analytics/web/#/a135030346w195125809p190419669/admin/custom-dimensions/
 *
 * @param {object} properties Key value object of properties to set.
 */
function gaUserProperties(properties = {}) {
  gtag('set', 'user_properties', properties);
}

/**
 * Set user ID for GA.
 * https://support.google.com/analytics/answer/9213390?hl=en
 * https://developers.google.com/analytics/devguides/collection/ga4/user-id?client_type=gtag
 * https://developers.google.com/analytics/devguides/collection/ga4/reference/config#user_id
 *
 * @param {string} userId User ID to set
 */
function gaSetUserId(userId) {
  gtag('set', 'user_id', userId);
}

export {
  gaApiTiming,
  gaEvent,
  gaException,
  gaLogin,
  gaPageView,
  gaSet,
  gaSetUserId,
  gaUaEquivalentEvent,
  gaUserProperties,
  gtag,
  initializeGoogleAnalytics,
};

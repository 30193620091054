/**
 * All validators.  A validator shoudl return true if valid
 * and false if not.
 */

// Dependencies
import validators from 'validator';

import hasCapitalLetters from './has-capital-letters';
import hasNumbers from './has-numbers';
import hasSpecialCharacters from './has-special-characters';

// Export
const allValidators = {
  ...validators,
  hasCapitalLetters,
  hasNumbers,
  hasSpecialCharacters,
};

export default allValidators;

/**
 * Try to convert an input to a string that is meant for sorting
 *
 * @param {*} input Input to convert
 * @returns {string} A lowercased string for sorting
 */
export default function ToStringSort(input) {
  // Check for input that cannot be a string
  if (input === undefined || input === null || !input.toString) {
    return '';
  }

  let converted = input.toString();

  // Lowercase
  converted = converted.toLowerCase().trim();

  // Remove any non-alphanumeric letters
  converted = converted.replace(/[^a-z\d\s.-]/g, ' ').replace(/\s+/g, ' ');

  // Remove any article at the beginning
  converted = converted.replace(/^(the|a|an)\s+/, '');

  // Pad numbers
  converted = converted.replace(/([0-9.]+)/g, (match, p1) =>
    p1.padStart(20, '0'),
  );

  return converted;
}

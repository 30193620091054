// Dependencies
import React from 'react';
import { matchPath } from 'react-router';

import history from '../../config/history';

// TODO: The main problem with this approach is that we have
// to manually know the routes to match
const routesToMatch = [
  { path: '/states/:state/timeline', end: true },
  { path: '/states/:state/anomalies', end: true },
  { path: '/states/:state/locales', end: true },
  { path: '/states/:state/populations', end: true },
  { path: '/states/:state/ballots', end: true },
  { path: '/states/:state/ballots/election', end: true },
  { path: '/states/:state/ballots/report', end: true },
  { path: '/states/:state/elections', end: true },
];

/**
 * A wrapper around withRouter to support the idea of global
 * routes
 *
 * @see https://github.com/ReactTraining/react-router/issues/5870
 * @param {*} Component - Component to wrap
 * @returns {React.element} - React element
 */
export default function withGlobalRouteMatch(Component) {
  let match;
  for (let r of routesToMatch) {
    match = match ? match : matchPath(r, history.location.pathname);
  }

  let WithGlobalRouteMatch = (props) => (
    <Component {...props} globalMatch={match} />
  );
  return WithGlobalRouteMatch;
}

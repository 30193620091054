/**
 * Forgot password form.
 */

// Dependencies
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import isAlphanumeric from 'validator/lib/isAlphanumeric';

import { user } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';
import handleFormattedResponseErrors from '../../modules/api/handle-formatted-response-errors';
import dispatch from '../../modules/events/dispatch';
import gaEventWrapper from '../../modules/ga/event-wrapper';
import captureException from '../../modules/sentry/capture-exception';
import FormItem from '../forms/FormItem';
import FormSubmit from '../forms/FormSubmit';
import PasswordValidator from '../password/PasswordValidator';

// Login page componet
export default class ForgotPasswordConfirmForm extends CoreComponent {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      // We use this to set the submit button
      // disabled by default
      hasValidated: false,
    };

    // Allow override
    this.overrideStateWithProp(this.state);

    // Refs
    this.passwordValidator = React.createRef();
  }

  // Prop types
  static propTypes = {
    onConfirmSuccess: PropTypes.func,
    onConfirmError: PropTypes.func,
    onConfirmCancel: PropTypes.func,
    username: PropTypes.string,
  };

  // Default props
  static defaultProps = {};

  // Unmount
  componentWillUnmount() {
    this._unmounted = true;
  }

  // Validation
  validate() {
    return (values) => {
      this.setState({ hasValidated: true });
      let errors = {};

      if (!values.username) {
        errors.username = 'Username is required.';
      }

      if (!values.code) {
        errors.code =
          'Verification code is required; check your email or phone for code.';
      }
      else if (!isAlphanumeric(values.code)) {
        errors.code = 'Verification code should only be numbers or letters.';
      }

      if (!values.password) {
        errors.password = 'New password is required.';
      }
      else if (
        !this.passwordValidator ||
        !this.passwordValidator.current.validate()
      ) {
        errors.password = 'Password must meet the requirements; see below.';
      }

      return errors;
    };
  }

  // Confirm the reset
  reset = async (values, actions) => {
    // Login
    let response;
    try {
      response = await user.api('confirm_reset_password', {
        body: {
          username: values.username,
          reset_code: values.code,
          password: values.password,
        },
      });
      handleFormattedResponseErrors(response, 'confirm_reset_password', null);
    }
    catch (e) {
      console.error(e);
      captureException(e, {
        tags: { feature: 'user action - confirm reset password' },
      });

      // Rename any errors
      let errorOutput = e.toString();
      if (errorOutput.toString().match(/UserNotFoundException/i)) {
        errorOutput = 'Code or username does not match.';
      }

      // Handle error
      actions.setErrors({ form: errorOutput });
      actions.setSubmitting(false);
      dispatch(this.props.onConfirmError, e, this);
      return;
    }

    // Make sure we are not unmounted
    if (this._unmounted) {
      return;
    }

    // Do something with response
    if (response && response.ok) {
      actions.setStatus({
        success: 'Password reset; please login with your new password.',
      });

      actions.setSubmitting(false);
      dispatch(this.props.onConfirmSuccess, this);
    }
    else {
      actions.setErrors({
        form: 'Something unknown happened resetting your password, please contact an administrator.',
      });

      actions.setSubmitting(false);
      dispatch(this.props.onConfirmError, undefined, this);
    }
  };

  // Cancel
  cancel = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }
    dispatch(this.props.onConfirmCancel, undefined, this);
  };

  // Main render
  render() {
    const { hasValidated } = this.state || {};

    return (
      <Formik
        initialValues={{
          username: this.props.username || '',
          code: '',
          password: '',
        }}
        validateOnBlur
        validateOnChange
        validate={this.validate()}
        onSubmit={gaEventWrapper(this.reset, {
          category: 'user',
          action: 'confirm reset password',
        })}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          resetForm,
        }) => (
          <form onSubmit={handleSubmit}>
            {/* Gets put on same page as ForgotPasswordForm */}
            <FormItem
              id="username-confirm"
              inputType="text"
              label="Username"
              help="Your username is most likely your email address."
              error={errors.username}
              value={values.username}
              touched={touched.username}
              onChange={handleChange}
              onBlur={handleBlur}
              readonly={!!this.props.username}
              inputProps={{
                required: true,
                autoComplete: 'username',
              }}
            />

            <FormItem
              id="code"
              inputType="text"
              label="Verification Code"
              help="The code sent to your email or phone."
              error={errors.code}
              value={values.code}
              touched={touched.code}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{ required: true }}
            />

            <FormItem
              id="password"
              inputType="password"
              label="New Password"
              help="Your new password."
              helpHide={false}
              error={errors.password}
              value={values.password}
              touched={touched.password}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                required: true,
                minLength: '10',
                maxLength: '64',
                autoComplete: 'new-password',
              }}
              afterInput={() => (
                <PasswordValidator
                  ref={this.passwordValidator}
                  password={values.password}
                />
              )}
            />

            <FormSubmit
              errors={errors}
              error={errors.form}
              success={status && status.success ? status.success : null}
              hasValidated={hasValidated}
              isSubmitting={isSubmitting}
              buttonLabel="Update password"
              submittingButtonLabel="Updating..."
              successButtonLabel="Updated"
              afterSubmit={
                <button
                  type="button"
                  className="outline"
                  onClick={(e) => {
                    resetForm();
                    this.cancel(e);
                  }}
                >
                  Cancel
                </button>
              }
            />
          </form>
        )}
      </Formik>
    );
  }
}

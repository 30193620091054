// Dependencies
import booleanArrayToMap from '../convert/boolean-array-to-map';
import queryStringParseValue from './to-value';

/**
 * Convert from query-string string to boolean Map.
 *
 * @param {string} input Value from query string
 * @param {boolean} handleEmpty Whether to represent an empty value as an empty Map or undefined
 * @returns {*} Converted string value to Map
 */
export default function toBooleanMapFromQueryString(
  input,
  handleEmpty = false,
) {
  // Convert from query string should be silent
  try {
    input = queryStringParseValue(input);
    return input
      ? booleanArrayToMap(input.split(','))
      : handleEmpty
        ? new Map()
        : undefined;
  }
  catch (e) {
    console.error(e);
    return undefined;
  }
}

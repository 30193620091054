/**
 * Change types
 *
 * Note that "id" should be what is used in the API
 */

const changeTypes = [
  {
    id: 'total_registered_voters',
    name: 'Total Voters',
    adjective: '',
    help: 'The total amount of voters in the voter file based on the voter identification.  Total voters is not a change type, and may not be available in certain views such as Anomalies.',
    hasAnalysis: false,
    // Valid normalizations, if not included, assumed to use all
    normalizations: ['per_diem', 'voters_affected'],
  },
  {
    id: 'removal',
    name: 'Removals',
    adjective: 'removed',
    help: 'Voters who were in the previous voter file, but are now removed. This is based on voter ID numbers that have been removed from the file.',
    hasAnalysis: true,
  },
  {
    id: 'registration',
    name: 'Registrations',
    adjective: 'registered',
    help: 'Voters who are newly registered. More specifically, this is a count of new voter identification numbers in the file.',
    hasAnalysis: true,
  },
  {
    id: 'reregistration',
    name: 'Re-registrations',
    adjective: 're-registered',
    help: 'Voters who received new voter identification numbers. This can happen for many reasons, including movement between counties or other miscellaneous state specific maintenance practices.  Re-registrations are not one of the core change types we monitor for anomalous events and may not show up in certain views.',
    hasAnalysis: true,
  },
  {
    id: 'deactivated',
    name: 'Inactivations',
    adjective: 'deactivated',
    help: 'Voters who were listed in the voter file last snapshot as "active", but are now "inactive". Voters are deactivated for many reasons, check the state\'s individual registration rules for details. Typically these people can still vote, but may be removed after the next two federal elections.',
    hasAnalysis: true,
  },
  {
    id: 'activated',
    name: 'Reactivations',
    adjective: 'reactivated',
    help: 'Voters who were listed in the voter file last week as "inactive", but are now "active". Voters are typically reactivated when they return a postcard from the board of elections, vote, or sign a petition.',
    hasAnalysis: true,
  },
  {
    id: 'address',
    name: 'Address Changes',
    adjective: 'with address changes',
    help: 'Voters whose address has been changed. This includes all address fields across both residential and mailing addresses.',
    hasAnalysis: true,
  },
  {
    id: 'name',
    name: 'Name Changes',
    adjective: 'with name changes',
    help: 'Voters whose names have been changed. This includes first, middle, last, and suffix changes.',
    hasAnalysis: true,
  },
  {
    id: 'party',
    name: 'Party Changes',
    adjective: 'with party changes',
    help: 'Voters whose party field changed. Directional change is offered on the analysis page for any group of voters.',
    hasAnalysis: true,
  },
  {
    id: 'birthday',
    name: 'Birthday Changes',
    adjective: 'with birthday changes',
    help: 'Voters whose birthday field changed.',
    hasAnalysis: true,
  },
  {
    id: 'early_voter',
    name: 'Permanent Absentee Status Changes',
    adjective: 'with permanent absentee status changes',
    help: 'Voters whose permanent absentee status field changed.',
    hasAnalysis: true,
  },
];

export default changeTypes;

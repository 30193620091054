// Dependencies
import _filter from 'lodash/filter';

import fromValueToQueryString from './from-value';

/**
 * From array to a query-string string (generator).
 *
 * @param {boolean} handleEmpty - Whether to represent empty values.
 * @returns {string} - Function to convert from array to query-string string.
 */
export default function fromArrayToQueryString(handleEmpty = false) {
  return (input) => {
    return input && input.length
      ? _filter(input.map(fromValueToQueryString)).join(',')
      : handleEmpty
        ? ','
        : '';
  };
}

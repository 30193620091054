/**
 * Scale types
 *
 * method should be a Recharts axis scale value (unless custom behavior will be built)
 * https://recharts.org/en-US/api/YAxis#scale
 */

const scales = [
  {
    id: 'linear',
    name: 'Linear',
    description: 'Linear',
    method: 'auto',
  },
  {
    id: 'log',
    name: 'Logarithmic',
    description: 'Logarithmic',
    method: 'log',
  },
];

export default scales;

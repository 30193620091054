/**
 * Landing - Media section
 */

// Styles
import './MediaSection.scss';

// Dependencies
import React from 'react';

import imageMediaAjc from '../../assets/images/media-ajc.png';
import imageMediaFreeThink from '../../assets/images/media-freethink.png';
import imageMediaGoverning from '../../assets/images/media-governing.png';
import imageMediaGovTech from '../../assets/images/media-government-technology.png';
import imageMediaGazette from '../../assets/images/media-iowa-gazette.png';
import imageMediaNcsl from '../../assets/images/media-ncsl.png';
import imageMediaPrnewswire from '../../assets/images/media-prnewswire.png';
import imageMediaTacl from '../../assets/images/media-tacl.png';
import imageMediaVotebeat from '../../assets/images/media-votebeat.png';
import CoreComponent from '../../extensions/CoreComponent';
import BalanceText from '../text/BalanceText';

// Components
export default class MediaSection extends CoreComponent {
  constructor(props) {
    super(props);
    this.mediaRef = React.createRef();
  }

  static propTypes = {};
  static defaultProps = {};
  state = {};

  // On mount
  componentDidMount() {
    // News link
    if (
      this.mediaRef &&
      this.mediaRef.current &&
      this.mediaRef.current.scrollIntoView &&
      window.location.href.includes('#media')
    ) {
      this.mediaRef.current.scrollIntoView({
        behaviour: 'smooth',
        block: 'start',
        inline: 'center',
      });
    }
  }

  // Render
  render() {
    // News articles mentioning VoteShield
    // We do not currently display the date but it's nice for reference & curation
    const articles = [
      {
        title:
          'AI technology sparks new worries, but poses familiar challenges to elections',
        date: 'February 5, 2024',
        source: 'Votebeat',
        link: 'https://www.votebeat.org/2024/02/05/ai-elections-2024-robocallschatbots/',
        logo: imageMediaVotebeat,
      },
      {
        title: 'Opinion: Here’s what cleaning up voter rolls means',
        date: 'Aug 31, 2023',
        source: 'The Atlanta Journal-Constitution',
        link: 'https://www.ajc.com/opinion/opinion-heres-what-cleaning-up-voter-rolls-means/WZVRODR5DREDZDN65I73DUFIMU/',
        logo: imageMediaAjc,
      },
      {
        title: 'Election Tech Monitors Voter Records, Helps Secure Midterms',
        date: 'March 27, 2022',
        source: 'Governing',
        link: 'https://www.governing.com/security/election-tech-monitors-voter-records-helps-secure-midterms',
        logo: imageMediaGoverning,
      },
      {
        title: 'Securing the Midterms: Smarter Tools Watch Over Voter Records',
        date: 'March 23, 2022',
        source: 'Government Technology',
        link: 'https://www.govtech.com/security/securing-the-midterms-smarter-tools-watch-over-voter-records',
        logo: imageMediaGovTech,
      },
      {
        title: 'Election Tech Providers Offer Advice on Cybersecurity and More',
        date: 'August 30, 2021',
        source: 'National Conference of State Legislatures',
        link: 'https://www.ncsl.org/newsletter/details/the-canvass-september-2021',
        logo: imageMediaNcsl,
      },
      {
        title:
          'VoteShield Nominated for National Association of Secretaries of State (NASS) IDEAS Award by Iowa Secretary of State',
        date: 'May 04, 2021',
        source: 'PR Newswire',
        link: 'https://www.prnewswire.com/news-releases/voteshield-nominated-for-national-association-of-secretaries-of-state-nass-ideas-award-by-iowa-secretary-of-state-301282878.html',
        logo: imageMediaPrnewswire,
      },
      {
        title: '30 Ways Election Officials Boost Voter Confidence and Trust',
        date: 'December 8, 2020',
        source: 'Center for Tech and Civic Life',
        link: 'https://www.techandciviclife.org/boosting-voter-trust/',
        logo: imageMediaTacl,
      },
      {
        title: 'Keeping Democracy Safe in the 2020 Election',
        date: 'October 7, 2020',
        source: 'FreeThink',
        link: 'https://www.freethink.com/videos/election-security',
        logo: imageMediaFreeThink,
      },
      {
        title:
          'Election Commissioners: Working Together to Protect Iowans\' Votes',
        date: 'Dec. 20, 2019',
        source: 'Iowa Gazette',
        link: 'https://www.thegazette.com/subject/opinion/guest-columnist/election-commissioners-working-together-to-protect-iowans-votes-20191220',
        logo: imageMediaGazette,
      },
      {
        title: 'Nonprofit\'s Free App Flags Suspicious Changes to Voter Rolls',
        date: 'May 29, 2019',
        source: 'Government Technology',
        link: 'https://www.govtech.com/security/Nonprofits-Free-App-Flags-Suspicious-Changes-to-Voter-Rolls.html',
        logo: imageMediaGovTech,
      },
    ];

    return (
      <section id="media" ref={this.mediaRef} className="in-the-news">
        <div className="container-copy-large center-horizontal text-center">
          <h1>
            <BalanceText>In the Media</BalanceText>
          </h1>
          <p className="heading-copy">
            <BalanceText>
              Learn more about how VoteShield is supporting election officials
              around the country.
            </BalanceText>
          </p>
        </div>

        <div className="row mb-1">
          {articles.map((article, index) => (
            <div className="col-md-6 news-article" key={`article${index}`}>
              {article.logo && (
                <div className="news-article-image">
                  <a
                    href={article.link}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <img
                      src={article.logo}
                      alt={`Logo for ${article.source}`}
                    />
                  </a>
                </div>
              )}
              <a
                className="news-article-link"
                href={article.link}
                rel="noopener noreferrer"
                target="_blank"
              >
                {article.title}
              </a>
            </div>
          ))}
        </div>
      </section>
    );
  }
}

// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { user } from '../../config';

/**
 * Component to redirect to a view when the state is not explicit in the route.
 *
 * @param {*} props - Props for component
 * @returns {React.element} - React element
 */
export default function PreferredStateRedirect({
  view = 'timeline',
  redirect = '/onboarding/returning-user',
}) {
  // Try to get user preferred state
  let state = user.preferredStateAccessDetails();

  return state ? (
    <Navigate to={`/states/${state.id}/${view}`} replace={true} />
  ) : (
    <Navigate to={redirect} replace={true}></Navigate>
  );
}

PreferredStateRedirect.propTypes = {
  view: PropTypes.string,
  redirect: PropTypes.string,
};

/**
 * Main, persistent header
 */

// Styles
import './MainHeader.scss';

// Dependencies
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import { uploadRoles, user } from '../../config';
import FilterContext from '../../context/FilterContext';
import CoreComponent from '../../extensions/CoreComponent';
import AuthorizedAuthenticated from '../../providers/auth/AuthorizedAuthenticated';
import AuthorizedAuthenticatedAny from '../../providers/auth/AuthorizedAuthenticatedAny';
import AuthorizedAuthenticatedWithRole from '../../providers/auth/AuthorizedAuthenticatedWithRole';
import AuthorizedUnauthenticated from '../../providers/auth/AuthorizedUnauthenticated';

// Header component
export default class MainHeader extends CoreComponent {
  static propTypes = {};
  static defaultProps = {};
  static contextType = FilterContext;

  render() {
    const { hasStates, statesLabel, statesDetails } = this.context || {};

    // Don't show links to states the user does not have access to
    const primaryStateDetails =
      statesDetails?.[0] && user.hasStateAccess(statesDetails?.[0].id)
        ? statesDetails?.[0]
        : user.preferredStateAccessDetails();

    return (
      <div className="main-header">
        <div className="container-lg">
          <nav>
            <h1>
              <Link to="/">
                VoteShield
                {hasStates && user && user.isAuthenticated() ? (
                  <small> + {statesLabel()}</small>
                ) : (
                  ''
                )}
              </Link>
            </h1>

            <ul>
              <AuthorizedAuthenticated>
                <li>
                  <NavLink
                    to={
                      primaryStateDetails
                        ? `/states/${primaryStateDetails.id}/timeline`
                        : '/views/timeline'
                    }
                  >
                    Voter records
                  </NavLink>
                </li>
              </AuthorizedAuthenticated>

              <AuthorizedAuthenticated>
                <>
                  {!!primaryStateDetails?.ballots?.enabled && (
                    <li>
                      <NavLink
                        to={
                          primaryStateDetails
                            ? `/states/${primaryStateDetails.id}/ballots`
                            : '/views/ballots'
                        }
                      >
                        Ballots
                      </NavLink>
                    </li>
                  )}
                </>
              </AuthorizedAuthenticated>

              <AuthorizedAuthenticatedWithRole roles={uploadRoles}>
                <li>
                  <NavLink to="/upload">Upload</NavLink>
                </li>
              </AuthorizedAuthenticatedWithRole>

              <AuthorizedAuthenticatedAny>
                <li>
                  <NavLink to="/account">Account</NavLink>
                </li>
              </AuthorizedAuthenticatedAny>

              <li>
                <NavLink to="/about">About</NavLink>
              </li>

              <AuthorizedAuthenticatedAny>
                <li>
                  <NavLink to="/logout">Logout</NavLink>
                </li>
              </AuthorizedAuthenticatedAny>

              <AuthorizedUnauthenticated>
                <li>
                  <NavLink to="/login">Login</NavLink>
                </li>
              </AuthorizedUnauthenticated>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

/**
 * Address types for ballot reviews
 */

import sortBy from 'lodash/sortBy';

import environment from '../modules/browser/environment';

const addressTypes = [
  { id: 'unknown', name: 'Unknown', sort: 'zzzzz' },
  { id: 'apartment', name: 'Apartment building' },
  { id: 'jail', name: 'Jail' },
  { id: 'multi-family', name: 'Multi-family' },
  { id: 'retirement', name: 'Retirement community' },
  { id: 'healthcare-facility', name: 'Healthcare facility' },
  { id: 'po-box', name: 'P.O. Box' },
  { id: 'religious', name: 'Religious community' },
  { id: 'college-university', name: 'College or University' },
];

if (environment() !== 'production') {
  addressTypes.push({ id: 'test', name: 'TEST REVIEW', sort: 'zzzzz' });
}

export default sortBy(
  addressTypes,
  (a) => (a.sort || a.name)?.toLowerCase() || 'zzzzz',
);

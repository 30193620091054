/**
 * Normalizations types
 *
 * Note that "id" should be what is used in the API
 */

const normalizations = [
  {
    id: 'per_diem',
    name: 'Day',
    description: 'Daily average voters affected',
    descriptive: 'Daily average voters [[changed]]',
    unit: 'Daily average voters affected',
  },
  {
    id: 'per_kilocapita',
    name: 'Capita',
    description: 'Voters affected per 1,000 voters',
    descriptive: 'Voters [[changed]] per 1,000 voters registered',
    unit: 'Voters affected per 1k voters',
  },
  {
    id: 'per_diem_per_kilocapita',
    name: 'Capita + Day',
    description: 'Daily average voters affected per 1,000 voters',
    descriptive: 'Daily average voters [[changed]] per 1,000 voters registered',
    unit: 'Daily avg. voters affected per 1k voters',
  },
  {
    id: 'voters_affected',
    name: 'Raw',
    description: 'Total voters affected',
    descriptive: 'Total voters [[changed]]',
    unit: 'Voters affected',
  },
];

export default normalizations;

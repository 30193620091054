/**
 * Metrics that are available in elections for ballots.
 */

import orderBy from 'lodash/orderBy';

const metrics = [
  {
    id: 'ballot-records',
    apiId: 'total_ballot_records',
    name: 'Total ballot records',
    description: 'all ballot records in file',
    units: 'records',
    sort: 'aaaa',
  },
  {
    id: 'app-sent',
    apiId: 'applications_sent',
    name: 'Applications sent',
    description: 'absentee ballot applications sent out',
    units: 'applications',
    sort: 'apps-01',
  },
  {
    id: 'app-returned',
    apiId: 'applications_returned',
    name: 'Applications returned',
    description: 'absentee ballot applications returned',
    units: 'applications',
    sort: 'apps-02',
  },
  {
    id: 'app-sent-to-return',
    apiId: 'avg_days_app_sent_to_app_return',
    name: 'Average time for applications returned',
    description:
      'average time between when an application was sent out and when it was returned',
    units: 'days',
    sort: 'apps-03',
  },
  {
    id: 'unprocessed-app',
    apiId: 'unprocessed_applications',
    name: 'Unprocessed applications',
    description: 'applications that have not been processed',
    units: 'applications',
    sort: 'apps-04',
  },
  {
    id: 'ballot-sent',
    apiId: 'ballots_sent',
    name: 'Ballots sent',
    description: 'absentee ballots sent out',
    units: 'ballots',
    unit: 'ballot sent',
    sort: 'ballots-01',
  },
  {
    id: 'app-return-to-ballot-sent',
    apiId: 'avg_days_app_return_to_ballot_sent',
    name: 'Average time for ballots sent',
    description:
      'average time between when an application was returned and when ballot was sent out',
    units: 'days',
    sort: 'ballots-01a',
  },
  {
    id: 'ballot-returned',
    apiId: 'ballots_returned',
    name: 'Ballots returned',
    description: 'absentee ballots returned after being filled out',
    units: 'ballots',
    sort: 'ballots-02',
  },
  {
    id: 'ballot-returned-per',
    apiId: 'percent_ballots_returned',
    name: 'Ballots returned (percent)',
    description: 'percent of ballots sent out that have been returned',
    units: 'percent of ballots sent',
    sort: 'ballots-02',
  },
  {
    id: 'ballot-sent-to-ballot-return',
    apiId: 'avg_days_ballot_sent_to_ballot_return',
    name: 'Average time for ballots returned',
    description:
      'average time between when a ballot was sent out and when it was returned',
    units: 'days',
    sort: 'ballots-03',
  },
  {
    id: 'ballot-rejected',
    apiId: 'ballots_rejected',
    name: 'Ballots rejected',
    description: 'absentee ballots rejected after being returned',
    units: 'ballots',
    sort: 'ballots-04',
    stateOverrides: {
      pa: {
        name: 'Ballots cancelled',
        description: 'absentee ballots cancelled after being returned',
      },
    },
  },
  {
    id: 'ballot-rejected-per',
    apiId: 'percent_ballots_rejected',
    name: 'Ballots rejected (percent)',
    description: 'percent of ballots returned that have been rejected',
    units: 'percent of ballots returned',
    sort: 'ballots-04',
    stateOverrides: {
      pa: {
        name: 'Ballots cancelled (percent)',
        description: 'percent of ballots returned that have been cancelled',
      },
    },
  },
];

const orderedMetrics = orderBy(metrics, ['sort', 'nam'], ['asc', 'asc']);

export default orderedMetrics;

/**
 * Consistent page feeback form link
 */

// Dependencies
// Styles
import './FeedbackPageLink.scss';

import React from 'react';

//import PropTypes from 'prop-types';
//import classNames from 'classnames';
import CoreComponent from '../../extensions/CoreComponent';
import FeedbackFormLink from './FeedbackFormLink';

// Components
export default class FeedbackPageLink extends CoreComponent {
  // Prop types
  static propTypes = {};

  // Prop defaults
  static defaultProps = {};

  // Render
  render() {
    const { children } = this.props;

    return (
      <div className="feedback-page-link">
        <FeedbackFormLink variant="button" className={''}>
          <span className="inner-button-text">
            {children ? children : 'Feedback'}
          </span>
        </FeedbackFormLink>
      </div>
    );
  }
}

/**
 * Main login page.  This is what users get redirected to if they
 * are not signed in.
 */

// Dependencies
import React from 'react';
import { Link } from 'react-router-dom';

import { accountEmail } from '../../../config';
import CoreComponent from '../../../extensions/CoreComponent';
import PageTitle from '../../document/PageTitle';
import EmailLink from '../../links/EmailLink';
import LoginForm from '../../login/LoginForm';

// Login page component
export default class LoginPage extends CoreComponent {
  static propTypes = {};
  static defaultProps = {};

  // Get redirect
  getRedirect({ location }) {
    let redirect = location && location.state ? location.state.from : null;
    let redirectPath = redirect && redirect.pathname ? redirect.pathname : null;
    redirect = redirectPath && redirectPath.match(/logout/i) ? null : redirect;
    return redirect || '/views/timeline';
  }

  // Main render
  render() {
    // See if we have a redirect path
    let redirect = this.getRedirect(this.props || {});

    return (
      <div className="container-lg pt-2 pt-md-4 mb-2">
        <PageTitle title="Login" />

        <div className="row">
          <div className="col-0 col-md-4"></div>

          <div className="col-12 col-md-4 mb-2 pr-md-3">
            <LoginForm redirect={redirect} />

            <p>
              If you forgot it, <Link to="/forgot">reset your password</Link>.
            </p>

            <p>
              If you do not have an account, reach out to us to{' '}
              <EmailLink
                email={accountEmail}
                subject="Account request for VoteShield"
                body="I would like to request an account for the VoteShield platform.  (Please provide your organization and email address)"
              >
                request an account
              </EmailLink>
              .
            </p>
          </div>
        </div>
      </div>
    );
  }
}

// Dependencies
import isArray from 'lodash/isArray';
import _map from 'lodash/map';

/**
 * Convert an array of values to Map where the key is the value, and the value is true/boolean.
 *
 * @param {Array} arrayCollection Array to convert
 * @returns {Map} Map of values
 */
export default function booleanArrayToMap(arrayCollection) {
  return isArray(arrayCollection)
    ? new Map(_map(arrayCollection, (i) => [i, true]))
    : new Map();
}

/**
 * Election types
 */

const electionTypes = [
  {
    id: 'general',
    name: 'General',
  },
  {
    id: 'special',
    name: 'Special',
  },
  {
    id: 'primary',
    name: 'Primary',
  },
  {
    id: 'municipal',
    name: 'Municipal',
  },
];

export default electionTypes;

/**
 * Page footer content
 */

// Dependencies
import React from 'react';
import { Link, NavLink } from 'react-router-dom';

import CoreComponent from '../../extensions/CoreComponent';
import AuthorizedAuthenticatedAny from '../../providers/auth/AuthorizedAuthenticatedAny';
import AuthorizedUnauthenticated from '../../providers/auth/AuthorizedUnauthenticated';
import FeedbackPageLink from '../links/FeedbackPageLink';

// Styles
//import './PageFooter.scss';

// Components
export default class PageFooter extends CoreComponent {
  // Prop types
  static propTypes = {};

  // Prop defaults
  static defaultProps = {};

  // Default state
  state = {};

  // Render
  render() {
    // Shortcuts

    return (
      <>
        <FeedbackPageLink />
        <div className="page-footer">
          <div className="page-footer-contents">
            <div className="container-lg">
              <div className="footer-menu">
                <nav>
                  <ul>
                    <li>
                      <NavLink to="/about">About</NavLink>
                    </li>
                    <li>
                      <Link
                        to={{
                          pathname: '/',
                          hash: '#media',
                        }}
                      >
                        In the Media
                      </Link>
                    </li>

                    <AuthorizedAuthenticatedAny>
                      <li>
                        <NavLink to="/logout">Logout</NavLink>
                      </li>
                    </AuthorizedAuthenticatedAny>

                    <AuthorizedUnauthenticated>
                      <li>
                        <NavLink to="/login">Login</NavLink>
                      </li>
                    </AuthorizedUnauthenticated>

                    <li>
                      <NavLink to="/legal/terms-of-use">Terms of Use</NavLink>
                    </li>

                    <li>
                      <NavLink to="/legal/privacy-policy">
                        Privacy Policy
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

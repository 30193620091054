/**
 * Anomaly indexes
 */

const anomalyIndexes = [
  {
    id: 'maxIndex',
    apiId: 'anomaly_metric_maximum',
    name: 'Maximum',
    help: 'The highest of the 4 anomaly detection method scores for each change group.',
  },
  {
    id: 'temporalIndex',
    apiId: 'temporal_anomaly_metric_percentile',
    name: 'Temporal',
    help: 'Measures the irregularity of a change group compared to other moments in time for a single location.',
    icon: 'access_time',
  },
  {
    id: 'geographicIndex',
    apiId: 'cross_locale_anomaly_metric_percentile',
    name: 'Geographic',
    help: 'Measures the irregularity of a change group compared to other locations in the state at the same point in time.',
    icon: 'room',
  },
  {
    id: 'demographicIndex',
    apiId: 'demographic_anomaly_metric_percentile',
    name: 'Demographic',
    help: 'Measures the irregularity of the demographics of a change group compared to the demographics of other change groups that share the same locale and change type (i.e. Removal) expressed as a z-score value.',
    icon: 'local_offer',
  },
];

export default anomalyIndexes;

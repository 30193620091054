import styles from './styles';
const synchrony = {
  // Minimum and maximum score that we display in hover tooltip
  // (These values signify the most useful range for identifying notability)
  min: 0,
  max: 15,

  // Threshold where score becomes likely notable
  warningThreshold: 4,
  warningColor: styles.color.orangeYellow,

  // Threshold where score becomes highly notable
  errorThreshold: 7,
  errorColor: styles.color.red,

  // Change types with no synchrony score data associated
  changeTypesUnavailable: [
    'total_registered_voters',
    'reregistration',
    'birthday',
    'early_voter',
  ],
};

export default synchrony;

// Dependencies

/**
 * Determine the environment that we are in.  We use window.location by
 * default and aim to use a hostname as opposed to process.env.NODE_ENV,
 * as the latter is more aimed at how to build the project, then on how
 * the application should change based on the environment.
 *
 * @param {string} hostname Will use window.location if not provided
 * @returns {string} A string describing the environment.  Will
 *   default to 'production' if cannot determine otherwise.
 */
export default function environment(hostname) {
  hostname =
    hostname !== undefined
      ? hostname
      : window && window.location && window.location.hostname
        ? window.location.hostname
        : '';

  if (!hostname || hostname.match(/^(www\.voteshield\.us|voteshield\.us)$/i)) {
    return 'production';
  }
  else if (hostname.match(/localhost|127\.0\.0\.1/i)) {
    return 'local';
  }
  else if (hostname.match(/beta\./i)) {
    return 'beta';
  }
  else if (hostname.match(/(dev|development)\./i)) {
    return 'development';
  }
  else {
    return 'staging';
  }
}

/**
 * Given a formatted response, check if there is an error
 * and throw a helpful error
 *
 * @param {object} response Formatted response object
 * @param {string} apiEndpoint Name of API endpoint to help with error messages and logging.
 * @param {string} dataProperty Name of data property to get from the reponse.
 * @returns {object} The same formatted response object unless
 *   and error has been thrown
 */
export default function handleFormattedResponseErrors(
  response,
  apiEndpoint = '',
  dataProperty = 'json',
) {
  // Check for response
  if (!response) {
    let e = new Error(`No response from API ${apiEndpoint}`);
    e.debug = e.debug || {};
    e.debug.response = response;
    e.debug.apiEndpoint = apiEndpoint;
    throw e;
  }
  // If 401 or 403, the User object handles that so just pass through
  else if (response.status === 401 || response.status === 403) {
    return response;
  }
  // Check for status
  else if (!response.ok || response.status >= 300) {
    let errorClass = response?.json?.error_class;
    let responseMessage = response?.json?.msg;
    let message = `API Error: ${errorClass ? '[' + errorClass + ']' : ''} ${
      responseMessage ? responseMessage : 'Unknown error to API'
    } (${apiEndpoint}) (${response.status})`;
    let e = new Error(message);
    e.debug = e.debug || {};
    e.debug.response = response;
    e.debug.apiEndpoint = apiEndpoint;
    throw e;
  }
  // Check for data
  else if (dataProperty && !response[dataProperty]) {
    let errorClass = response?.json?.error_class;
    let responseMessage = response?.json?.msg;
    let message = `API NoData: ${errorClass ? '[' + errorClass + ']' : ''} ${
      responseMessage ? responseMessage : 'Unknown error to API'
    } (${apiEndpoint}) (${response.status})`;
    let e = new Error(message);
    e.debug = e.debug || {};
    e.debug.response = response;
    e.debug.apiEndpoint = apiEndpoint;
    e.debug.dataProperty = dataProperty;
    throw e;
  }

  return response;
}

/**
 * A layout component for form items.
 */

// Dependencies
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';

// Components
export default class FormItemLayout extends CoreComponent {
  // Prop types
  static propTypes = {
    variant: PropTypes.oneOf(['', 'split', 'split-small']),
    itemClass: PropTypes.string,
    top: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    beforeInput: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    input: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    afterInput: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
    bottom: PropTypes.oneOfType([PropTypes.func, PropTypes.element]),
  };

  // Prop defaults
  static defaultProps = {};

  // Render
  render() {
    // Shortcuts
    const { variant, itemClass, top, beforeInput, input, afterInput, bottom } =
      this.props;

    // Variants
    const isSplit = variant === 'split';
    const isSplitSmall = variant === 'split-small';
    const isSplitAny = isSplit || isSplitSmall;

    return (
      <div
        className={classNames('form-item', itemClass, {
          'mb-half': isSplitAny,
        })}
      >
        {!!top && (
          <div className={classNames({ row: isSplitAny })}>
            <div
              className={classNames({
                'order-md-last': isSplitAny,
                'col-md-5': isSplit,
                'col-md-4': isSplitSmall,
              })}
            >
              {this.renderProp(top)}
            </div>
          </div>
        )}

        <div className={classNames({ row: isSplitAny })}>
          <div
            className={classNames({
              'order-md-last': isSplitAny,
              'col-md-5': isSplit,
              'col-md-4': isSplitSmall,
            })}
          >
            {this.renderProp(beforeInput)}
          </div>

          <div
            className={classNames({
              'col-md-5': isSplit,
              'col-md-8': isSplitSmall,
            })}
          >
            {this.renderProp(input)}
            {this.renderProp(afterInput)}
          </div>

          <div className={classNames({ 'col-md-2': isSplit })} />
        </div>

        {this.renderProp(bottom)}
      </div>
    );
  }
}

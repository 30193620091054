/**
 * Landing - Intro section
 */

// Styles
import './IntroSection.scss';

// Dependencies
import React from 'react';

import imageLogoWordmark from '../../assets/logo/logo-wordmark.svg';
import { demoEmail } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';
import AuthorizedAuthenticatedAny from '../../providers/auth/AuthorizedAuthenticatedAny';
import AuthorizedUnauthenticated from '../../providers/auth/AuthorizedUnauthenticated';
import EmailLink from '../links/EmailLink';
import LoginForm from '../login/LoginForm';
import BalanceText from '../text/BalanceText';

// Components
export default class IntroSection extends CoreComponent {
  // Get redirect
  getRedirect({ location }) {
    let redirect = location && location.state ? location.state.from : null;
    let redirectPath = redirect && redirect.pathname ? redirect.pathname : null;
    redirect = redirectPath && redirectPath.match(/logout/i) ? null : redirect;
    return redirect || '/views/timeline';
  }

  // Render
  render() {
    // See if we have a redirect path
    let redirect = this.getRedirect(this.props || {});

    // Content
    return (
      <div className="row landing-intro">
        <div className="col-12 col-md-6 minh-50vh minh-md-75vh">
          <div className="center-absolute title-wrapper">
            <div className="text-center">
              <h1 className="page-title">
                <span className="sr-only">VoteShield</span>
                <img
                  alt="VoteShield logo and wordmark"
                  src={imageLogoWordmark}
                ></img>
              </h1>

              <p className="heading-copy">
                Safeguarding voter registration data.
              </p>

              <p className="heading-sub-copy">
                <BalanceText>
                  For more information, demo requests, or media requests, please
                  reach out to our team.
                </BalanceText>
              </p>

              <EmailLink
                variant="button"
                className="outline"
                email={demoEmail}
                subject="VoteShield Inquiry"
                body="I am an election administrator at [ELECTION_OFFICE] and would like to request a demo of VoteShield."
                gaEvent={{
                  category: 'email link',
                  action: 'contact',
                  label: 'landing - banner',
                }}
              >
                Contact Us
              </EmailLink>
            </div>
          </div>
        </div>

        <div className="display-none display-md-block col-0 col-md-6 minh-75vh color-inverse">
          <div className="center-absolute-md login-form-wrapper">
            <AuthorizedUnauthenticated>
              <LoginForm redirect={redirect} buttonClassName="outline" />
            </AuthorizedUnauthenticated>

            <AuthorizedAuthenticatedAny>
              <p className="intro-logged-in">
                <span className="intro-logged-in-callout">
                  <a className="button outline" href="/views/timeline">
                    Go to Timeline
                  </a>
                  <br />
                  Explore how the voter file has changed over time
                </span>

                <span className="intro-logged-in-callout">
                  <a className="button outline" href="/views/anomalies">
                    Go to Anomalies
                  </a>
                  <br />
                  Explore anomalies in the voter file
                </span>
              </p>
            </AuthorizedAuthenticatedAny>
          </div>
        </div>
      </div>
    );
  }
}

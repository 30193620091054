/**
 * Demographic/features variables, mostly used in Analysis view
 */

// Common to year conversion
const convertToYears = (x, options) =>
  options && options.fromApiId && options.fromApiId.match(/month/)
    ? x / 12
    : options && options.fromApiId && options.fromApiId.match(/year/)
      ? x
      : x / 365;

// Configuration
const demographics = [
  {
    // Disable demographic.  This is useful for specific state overrides with
    // stateOverrides property
    // disabled: true,
    //
    // Arbitrary id
    id: 'race',
    // ID used by the API
    apiId: 'cat_race',
    // continuous or categorical
    type: 'categorical',
    // Title
    name: 'Race or Ethnicity',
    // Description
    description:
      'The race or ethnicity of each voter given the categories offered by the voter file.',
    // For categorical variables, use these to create a nicely
    // formatted name for each value
    categories: [
      { id: 'black', name: 'Black' },
      { id: 'white', name: 'White' },
      { id: 'hispanic', name: 'Hispanic' },
      {
        id: 'asian',
        apiId: ['asian_or_pacific_islander'],
        name: 'Asian or Pacific Islander',
      },
      {
        id: 'native',
        apiId: ['american_indian_or_alaskan_native'],
        name: 'American Indian or Alaskan Native',
      },
      {
        id: 'multi',
        apiId: ['multi_racial'],
        name: 'Multi Racial',
      },
      { id: 'other', apiId: ['other'], name: 'Other' },
      { id: 'unknown', apiId: ['unknown', 'undefined'], name: 'Unknown' },
    ],
    // Used to override any category designation by states.  It overrides
    // on the basis of the `id`
    // categoryOverridesByState: {
    //   mi: [
    //     { id: 'active', apiId: ['v'], name: 'Active' },
    //   ]
    // },
    //
    // For other overrides, use the stateOverrides property
    // statesOverrides: {
    //   mi: {
    //     name: 'Michigan Race',
    //   },
    // },
    //
    // Used to create a descriptive sentence describing a set in this demographic
    descriptive: '[[population]] who identify as [[value]]',
    // Used to mark that a demographic does have a reference for comparison
    // (mostly used in the negative)
    hasReference: true,
    // For continuous variables, notes that the values are discrete
    // instead of ranges (histogram vs bar graph)
    // isDiscrete: false,
    // Lower number will show/sorted first
    weight: 10,
  },
  {
    id: 'gender',
    apiId: 'cat_gender',
    type: 'categorical',
    name: 'Gender',
    description:
      'The gender of each voter given the categories offered by the voter file.',
    categories: [
      { id: 'male', apiId: ['male'], name: 'Male' },
      { id: 'female', apiId: ['female'], name: 'Female' },
      { id: 'other', apiId: ['other'], name: 'Other' },
      { id: 'unknown', apiId: ['', 'unknown', 'null', 'nan'], name: 'Unknown' },
    ],
    descriptive: '[[population]] who identify as [[value]]',
    hasReference: true,
    weight: 20,
  },
  {
    id: 'voter-status',
    apiId: 'cat_voter-status',
    type: 'categorical',
    name: 'Voter Status',
    description: 'The status of each voter designated by the voter file.',
    categories: [
      { id: 'active', apiId: ['a', 'v'], name: 'Active' },
      { id: 'inactive', apiId: ['i', 's'], name: 'Inactive' },
      { id: 'pending', name: 'Pending' },
      { id: 'prereg', apiId: ['p-17'], name: 'Preregistered' },
      { id: 'federal_election_only', name: 'Federal election only' },
      { id: 'unknown', apiId: ['', 'other', 'unknown'], name: 'Unknown' },
    ],
    categoryOverridesByState: {
      mi: [
        { id: 'active', apiId: ['a'], name: 'Active' },
        { id: 'inactive', apiId: ['v'], name: 'Inactive' },
      ],
      tx: [{ id: 'active', apiId: ['v'], name: 'Active' }],
    },
    descriptive: '[[population]] who are designated as [[value]]',
    hasReference: true,
    weight: 30,
  },
  {
    id: 'party',
    apiId: 'cat_party-identifier',
    type: 'categorical',
    name: 'Party',
    description:
      'The party of each voter given the categories offered by the voter file.',
    categories: [
      {
        id: 'dem',
        apiId: ['d', 'dem', 'democrat', 'democratic'],
        name: 'Democrat',
        color: 'blue',
      },
      {
        id: 'rep',
        apiId: ['r', 'rep', 'republican'],
        name: 'Republican',
        color: 'red',
      },
      // This might not be standard across states
      {
        id: 'lib',
        apiId: ['lib', 'libertarian', 'lbt', 'lbr', 'lpf', 'libertarian_party'],
        name: 'Libertarian',
      },
      {
        id: 'grn',
        apiId: ['grn', 'gre', 'gr', 'green', 'green_party'],
        name: 'Green',
      },
      { id: 'ind', apiId: ['ind', 'independent'], name: 'Independent' },
      { id: 'other', apiId: ['oth', 'other'], name: 'Other' },
      {
        id: 'np',
        apiId: [
          'una',
          'pnd',
          'uaf',
          'npa',
          'unaffiliated',
          'und',
          'blk',
          'nf',
          'party_not_designated',
          'npp',
        ],
        name: 'No party affiliation',
        color: 'teal',
      },
    ],
    descriptive: '[[population]] who are registered as [[value]]',
    hasReference: true,
    weight: 50,
  },
  {
    id: 'party-change-direction',
    apiId: 'cat_party-change-direction',
    type: 'categorical',
    name: 'Party Change Direction',
    description: 'The parties that each voter switched from and to.',
    categories: [
      {
        id: 'dem_to_rep',
        apiId: [
          'dem_to_rep',
          'd_to_r',
          'democratic_to_republican',
          'democrat_to_republican',
        ],
        name: 'Democrat to Republican',
      },
      {
        id: 'dem_to_np',
        apiId: [
          'dem_to_np',
          'dem_to_pnd',
          'd_to_nf',
          'dem_to_uaf',
          'dem_to_npa',
          'dem_to_und',
          'dem_to_blk',
          'dem_to_una',
          'democratic_to_unaffiliated',
          'democrat_to_non_partisan',
          'democratic_to_party_not_designated',
        ],
        name: 'Democrat to No party affliation',
      },
      {
        id: 'dem_to_oth',
        apiId: ['dem_to_oth', 'd_to_oth', 'democratic_to_other'],
        name: 'Democrat to Other',
      },
      {
        id: 'dem_to_ind',
        apiId: ['dem_to_ind', 'democratic_to_independent'],
        name: 'Democrat to Independent',
      },
      {
        id: 'dem_to_lbt',
        apiId: [
          'dem_to_lbt',
          'dem_to_lbr',
          'dem_to_lpf',
          'dem_to_lib',
          'democratic_to_libertarian',
          'democrat_to_libertarian_party',
        ],
        name: 'Democrat to Libertarian',
      },
      {
        id: 'dem_to_grn',
        apiId: [
          'dem_to_grn',
          'dem_to_gre',
          'd_to_g',
          'd_to_gr',
          'democrat_to_green_party',
          'democratic_to_green',
        ],
        name: 'Democrat to Green',
      },

      {
        id: 'rep_to_dem',
        apiId: [
          'rep_to_dem',
          'r_to_d',
          'republican_to_democratic',
          'republican_to_democrat',
        ],
        name: 'Republican to Democrat',
      },
      {
        id: 'rep_to_np',
        apiId: [
          'rep_to_np',
          'rep_to_pnd',
          'r_to_nf',
          'rep_to_uaf',
          'rep_to_npa',
          'rep_to_und',
          'rep_to_blk',
          'rep_to_una',
          'republican_to_unaffiliated',
          'republican_to_non_partisan',
          'republican_to_party_not_designated',
        ],
        name: 'Republican to No party affliation',
      },
      {
        id: 'rep_to_oth',
        apiId: ['rep_to_oth', 'r_to_oth', 'republican_to_other'],
        name: 'Republican to Other',
      },
      {
        id: 'rep_to_ind',
        apiId: ['rep_to_ind', 'republican_to_independent'],
        name: 'Republican to Independent',
      },
      {
        id: 'rep_to_lbt',
        apiId: [
          'rep_to_lbt',
          'rep_to_lbr',
          'rep_to_lpf',
          'rep_to_lib',
          'republican_to_libertarian',
          'republican_to_libertarian_party',
        ],
        name: 'Republican to Libertarian',
      },
      {
        id: 'rep_to_grn',
        apiId: [
          'rep_to_grn',
          'rep_to_gre',
          'r_to_g',
          'r_to_gr',
          'republican_to_green_party',
          'republican_to_green',
        ],
        name: 'Republican to Green',
      },

      {
        id: 'np_to_dem',
        apiId: [
          'np_to_dem',
          'pnd_to_dem',
          'uaf_to_dem',
          'npa_to_dem',
          'und_to_dem',
          'blk_to_dem',
          'una_to_dem',
          'nf_to_d',
          'unaffiliated_to_democratic',
          'non_partisan_to_democrat',
          'party_not_designated_to_democratic',
        ],
        name: 'No party affliation to Democrat',
      },
      {
        id: 'np_to_rep',
        apiId: [
          'np_to_rep',
          'pnd_to_rep',
          'uaf_to_rep',
          'npa_to_rep',
          'und_to_rep',
          'blk_to_rep',
          'una_to_rep',
          'nf_to_r',
          'unaffiliated_to_republican',
          'non_partisan_to_republican',
          'party_not_designated_to_republican',
        ],
        name: 'No party affliation to Republican',
      },
      {
        id: 'np_to_oth',
        apiId: [
          'np_to_oth',
          'pnd_to_oth',
          'uaf_to_oth',
          'blk_to_oth',
          'nf_to_oth',
          'party_not_designated_to_other',
        ],
        name: 'No party affliation to Other',
      },
      {
        id: 'np_to_ind',
        apiId: [
          'np_to_ind',
          'pnd_to_ind',
          'npa_to_ind',
          'blk_to_ind',
          'party_not_designated_to_independent',
        ],
        name: 'No party affliation to Independent',
      },
      {
        id: 'np_to_lbt',
        apiId: [
          'np_to_lbt',
          'pnd_to_lbt',
          'uaf_to_lbr',
          'npa_to_lpf',
          'una_to_lib',
          'unaffiliated_to_libertarian',
          'non_partisan_to_libertarian_party',
          'party_not_designated_to_libertarian',
        ],
        name: 'No party affliation to Libertarian',
      },
      {
        id: 'np_to_grn',
        apiId: [
          'np_to_grn',
          'pnd_to_grn',
          'uaf_to_grn',
          'npa_to_gre',
          'blk_to_gre',
          'una_to_gre',
          'nf_to_gr',
          'party_not_designated_to_green',
          'non_partisan_to_green_party',
        ],
        name: 'No party affliation to Green',
      },

      {
        id: 'oth_to_dem',
        apiId: ['oth_to_dem', 'oth_to_d', 'other_to_democratic'],
        name: 'Other to Democrat',
      },
      {
        id: 'oth_to_rep',
        apiId: ['oth_to_rep', 'oth_to_r', 'other_to_republican'],
        name: 'Other to Republican',
      },
      {
        id: 'oth_to_np',
        apiId: [
          'oth_to_np',
          'oth_to_pnd',
          'oth_to_blk',
          'oth_to_nf',
          'other_to_party_not_designated',
        ],
        name: 'Other to No party affliation',
      },
      {
        id: 'oth_to_ind',
        apiId: ['oth_to_ind', 'other_to_independent'],
        name: 'Other to Independent',
      },
      {
        id: 'oth_to_lbt',
        apiId: ['oth_to_lbt', 'other_to_libertarian'],
        name: 'Other to Libertarian',
      },
      {
        id: 'oth_to_grn',
        apiId: ['oth_to_grn', 'oth_to_gr', 'other_to_green'],
        name: 'Other to Green',
      },

      {
        id: 'ind_to_dem',
        apiId: ['ind_to_dem', 'independent_to_democratic'],
        name: 'Independent to Democrat',
      },
      {
        id: 'ind_to_rep',
        apiId: ['ind_to_rep', 'independent_to_republican'],
        name: 'Independent to Republican',
      },
      {
        id: 'ind_to_np',
        apiId: [
          'ind_to_np',
          'ind_to_pnd',
          'ind_to_npa',
          'ind_to_blk',
          'independent_to_party_not_designated',
        ],
        name: 'Independent to No party affliation',
      },
      {
        id: 'ind_to_oth',
        apiId: ['ind_to_oth', 'independent_to_other'],
        name: 'Independent to Other',
      },
      {
        id: 'ind_to_lbt',
        apiId: ['ind_to_lbt', 'ind_to_lpf', 'independent_to_libertarian'],
        name: 'Independent to Libertarian',
      },
      {
        id: 'ind_to_grn',
        apiId: ['ind_to_grn', 'ind_to_gre', 'independent_to_green'],
        name: 'Independent to Green',
      },

      {
        id: 'lbt_to_dem',
        apiId: [
          'lbt_to_dem',
          'lbr_to_dem',
          'lpf_to_dem',
          'lib_to_dem',
          'libertarian_to_democratic',
          'libertarian_party_to_democrat',
        ],
        name: 'Libertarian to Democrat',
      },
      {
        id: 'lbt_to_rep',
        apiId: [
          'lbt_to_rep',
          'lbr_to_rep',
          'lpf_to_rep',
          'lib_to_rep',
          'libertarian_to_republican',
          'libertarian_party_to_republican',
        ],
        name: 'Libertarian to Republican',
      },
      {
        id: 'lbt_to_np',
        apiId: [
          'lbt_to_np',
          'lbt_to_pnd',
          'lbr_to_uaf',
          'lpf_to_npa',
          'lib_to_una',
          'libertarian_to_unaffiliated',
          'libertarian_to_party_not_designated',
          'libertarian_party_to_non_partisan',
        ],
        name: 'Libertarian to No party affliation',
      },
      {
        id: 'lbt_to_oth',
        apiId: ['lbt_to_oth', 'libertarian_to_other'],
        name: 'Libertarian to Other',
      },
      {
        id: 'lbt_to_ind',
        apiId: ['lbt_to_ind', 'lpf_to_ind', 'libertarian_to_independent'],
        name: 'Libertarian to Independent',
      },
      {
        id: 'lbt_to_grn',
        apiId: [
          'lbt_to_grn',
          'lbr_to_grn',
          'lpf_to_gre',
          'libertarian_to_green',
          'libertarian_party_to_green_party',
        ],
        name: 'Libertarian to Green',
      },

      {
        id: 'grn_to_dem',
        apiId: [
          'grn_to_dem',
          'gre_to_dem',
          'g_to_d',
          'gr_to_d',
          'green_party_to_democrat',
          'green_to_democratic',
        ],
        name: 'Green to Democrat',
      },
      {
        id: 'grn_to_rep',
        apiId: [
          'grn_to_rep',
          'gre_to_rep',
          'g_to_r',
          'gr_to_r',
          'green_party_to_republican',
          'green_to_republican',
        ],
        name: 'Green to Republican',
      },
      {
        id: 'grn_to_np',
        apiId: [
          'grn_to_np',
          'grn_to_pnd',
          'grn_to_uaf',
          'gre_to_npa',
          'gre_to_blk',
          'gre_to_una',
          'gr_to_nf',
          'green_party_to_non_partisan',
          'green_to_party_not_designated',
        ],
        name: 'Green to No party affliation',
      },
      {
        id: 'grn_to_oth',
        apiId: ['grn_to_oth', 'gr_to_oth', 'green_to_other'],
        name: 'Green to Other',
      },
      {
        id: 'grn_to_ind',
        apiId: ['grn_to_ind', 'gre_to_ind', 'green_to_independent'],
        name: 'Green to Independent',
      },
      {
        id: 'grn_to_lbt',
        apiId: [
          'grn_to_lbt',
          'grn_to_lbr',
          'gre_to_lpf',
          'green_to_libertarian',
        ],
        name: 'Green to Libertarian',
      },
    ],
    descriptive: '[[population]] who switched registration from [[value]]',
    hasReference: false,
    weight: 55,
  },
  {
    id: 'status-reason',
    apiId: [
      'cat_statusreason', // arizona2
      'cat_status-reason', // colorado, georgia
      'cat_reasoncode', // new york
      'cat_voter-status-reason-desc', // north carolina
      'cat_voter-status-reason', // wisconsin
      'cat_voterstatusreasoncodedesc', //california
      'cat_cancellation-reason', // michigan
    ],
    type: 'categorical',
    name: 'Status Reason',
    description:
      'The "status reason" assigned to each voter describing why they were designated with their current status.',
    categories: [
      { id: '20_day_period', apiId: ['20_day_period'], name: '20 Day Period' },
      {
        id: '4-year_maintenance',
        apiId: ['4-year_maintenance'],
        name: '4-Year Maintenance',
      },
      { id: 'active', apiId: ['active'], name: 'Active' },
      {
        id: 'active_registrant',
        apiId: ['active_registrant'],
        name: 'Active Registrant',
      },
      {
        id: 'added_to_system_in_error',
        apiId: ['added_to_system_in_error'],
        name: 'Added To System In Error',
      },
      {
        id: 'administrative',
        apiId: ['administrative'],
        name: 'Administrative',
      },
      {
        id: 'administrative_action',
        apiId: ['administrative_action'],
        name: 'Administrative Action',
      },
      { id: 'age', apiId: ['age'], name: 'Age' },
      { id: 'armed_forces', apiId: ['armed_forces'], name: 'Armed Forces' },
      {
        id: 'business_address',
        apiId: ['business_address'],
        name: 'Business Address',
      },
      { id: 'canceled', apiId: ['canceled'], name: 'Canceled' },
      { id: 'challenged', apiId: ['challenged'], name: 'Challenged' },
      { id: 'citizenship', apiId: ['citizenship'], name: 'Citizenship' },
      {
        id: 'confirmation_not_returned',
        apiId: ['confirmation_not_returned'],
        name: 'Confirmation Not Returned',
      },
      {
        id: 'confirmation_pending',
        apiId: ['confirmation_pending'],
        name: 'Confirmation Pending',
      },
      {
        id: 'confirmation_returned_undeliverable',
        apiId: ['confirmation_returned_undeliverable'],
        name: 'Confirmation Returned Undeliverable',
      },
      { id: 'conversion', apiId: ['conversion'], name: 'Conversion' },
      { id: 'court', apiId: ['court'], name: 'Court' },
      { id: 'death', apiId: ['death'], name: 'Death' },
      { id: 'deceased', apiId: ['deceased'], name: 'Deceased' },
      {
        id: 'deceased_-_automatic_resolution',
        apiId: ['deceased_-_automatic_resolution'],
        name: 'Deceased - Automatic Resolution',
      },
      {
        id: 'declared_incompetent',
        apiId: ['declared_incompetent'],
        name: 'Declared Incompetent',
      },
      {
        id: 'declared_incompetent_-_automatic_resolution',
        apiId: ['declared_incompetent_-_automatic_resolution'],
        name: 'Declared Incompetent - Automatic Resolution',
      },
      { id: 'duplicate', apiId: ['duplicate'], name: 'Duplicate' },
      {
        id: 'duplicate_registration',
        apiId: ['duplicate_registration'],
        name: 'Duplicate Registration',
      },
      {
        id: 'duplicate_same_jurisdiction',
        apiId: ['duplicate_same_jurisdiction'],
        name: 'Duplicate Same Jurisdiction',
      },
      {
        id: 'eric_-_cross_state_mover_(soft)',
        apiId: ['eric_-_cross_state_mover_(soft)'],
        name: 'Eric - Cross State Mover (Soft)',
      },
      {
        id: 'eric_-_deceased_(hard)',
        apiId: ['eric_-_deceased_(hard)'],
        name: 'Eric - Deceased (Hard)',
      },
      {
        id: 'eric_-_in_state_duplicate_(hard)',
        apiId: ['eric_-_in_state_duplicate_(hard)'],
        name: 'Eric - In State Duplicate (Hard)',
      },
      {
        id: 'eric_-_in_state_mover_(hard)',
        apiId: ['eric_-_in_state_mover_(hard)'],
        name: 'Eric - In State Mover (Hard)',
      },
      {
        id: 'failed_to_vote',
        apiId: ['failed_to_vote'],
        name: 'Failed To Vote',
      },
      {
        id: 'federal_-_id_only',
        apiId: ['federal_-_id_only'],
        name: 'Federal - Id Only',
      },
      {
        id: 'federal_-_no_id_of_citizenship',
        apiId: ['federal_-_no_id_of_citizenship'],
        name: 'Federal - No Id Of Citizenship',
      },
      {
        id: 'federal_-_no_id_or_citizenship',
        apiId: ['federal_-_no_id_or_citizenship'],
        name: 'Federal - No Id Or Citizenship',
      },
      {
        id: 'federal_–_id_only',
        apiId: ['federal_–_id_only'],
        name: 'Federal – Id Only',
      },
      {
        id: 'federal_–_no_id_of_citizenship',
        apiId: ['federal_–_no_id_of_citizenship'],
        name: 'Federal – No Id Of Citizenship',
      },
      {
        id: 'federal_–_no_id_or_citizenship',
        apiId: ['federal_–_no_id_or_citizenship'],
        name: 'Federal – No Id Or Citizenship',
      },
      { id: 'felon', apiId: ['felon'], name: 'Felon' },
      {
        id: 'felon_-_automatic_resolution',
        apiId: ['felon_-_automatic_resolution'],
        name: 'Felon - Automatic Resolution',
      },
      {
        id: 'felony_conviction',
        apiId: ['felony_conviction'],
        name: 'Felony Conviction',
      },
      {
        id: 'felony_sentence_completed',
        apiId: ['felony_sentence_completed'],
        name: 'Felony Sentence Completed',
      },
      {
        id: 'id_verification',
        apiId: ['id_verification'],
        name: 'Id Verification',
      },
      { id: 'inactive', apiId: ['inactive'], name: 'Inactive' },
      { id: 'incompetent', apiId: ['incompetent'], name: 'Incompetent' },
      {
        id: 'incomplete_edr',
        apiId: ['incomplete_edr'],
        name: 'Incomplete Edr',
      },
      {
        id: 'invalid_citizenship_proof',
        apiId: ['invalid_citizenship_proof'],
        name: 'Invalid Citizenship Proof',
      },
      {
        id: 'invalid_residence_address',
        apiId: ['invalid_residence_address'],
        name: 'Invalid Residence Address',
      },
      {
        id: 'involuntary_cancellation',
        apiId: ['involuntary_cancellation'],
        name: 'Involuntary Cancellation',
      },
      {
        id: 'juror_questionnaire_-_citizenship',
        apiId: ['juror_questionnaire_-_citizenship'],
        name: 'Juror Questionnaire - Citizenship',
      },
      {
        id: 'juror_questionnaire_–_felony',
        apiId: ['juror_questionnaire_–_felony'],
        name: 'Juror Questionnaire – Felony',
      },
      {
        id: 'juror_questionnaire_–_non-resident',
        apiId: ['juror_questionnaire_–_non-resident'],
        name: 'Juror Questionnaire – Non-Resident',
      },
      {
        id: 'legacy_-_conversion',
        apiId: ['legacy_-_conversion'],
        name: 'Legacy - Conversion',
      },
      { id: 'legacy_data', apiId: ['legacy_data'], name: 'Legacy Data' },
      { id: 'mailcheck', apiId: ['mailcheck'], name: 'Mailcheck' },
      {
        id: 'manual_duplicate_resolution',
        apiId: ['manual_duplicate_resolution'],
        name: 'Manual Duplicate Resolution',
      },
      { id: 'merged', apiId: ['merged'], name: 'Merged' },
      { id: 'military', apiId: ['military'], name: 'Military' },
      { id: 'moved', apiId: ['moved'], name: 'Moved' },
      {
        id: 'moved_from_county',
        apiId: ['moved_from_county'],
        name: 'Moved From County',
      },
      {
        id: 'moved_from_state',
        apiId: ['moved_from_state'],
        name: 'Moved From State',
      },
      {
        id: 'moved_out_of_jurisdiction',
        apiId: ['moved_out_of_jurisdiction'],
        name: 'Moved Out Of Jurisdiction',
      },
      {
        id: 'moved_out_of_jurisdiction_-_automatic_resolution',
        apiId: ['moved_out_of_jurisdiction_-_automatic_resolution'],
        name: 'Moved Out Of Jurisdiction - Automatic Resolution',
      },
      {
        id: 'moved_out_of_state',
        apiId: ['moved_out_of_state'],
        name: 'Moved Out Of State',
      },
      {
        id: 'moved_within_state',
        apiId: ['moved_within_state'],
        name: 'Moved Within State',
      },
      { id: 'movers', apiId: ['movers'], name: 'Movers' },
      { id: 'movers_list', apiId: ['movers_list'], name: 'Movers List' },
      { id: 'ncoa', apiId: ['ncoa'], name: 'NCOA' },
      { id: 'none', apiId: ['none'], name: 'None' },
      { id: 'no_contact', apiId: ['no_contact'], name: 'No Contact' },
      {
        id: 'no_response_to_verification_notice',
        apiId: ['no_response_to_verification_notice'],
        name: 'No Response To Verification Notice',
      },
      {
        id: 'non-surviving_record',
        apiId: ['non-surviving_record'],
        name: 'Non-Surviving Record',
      },
      { id: 'nvra', apiId: ['nvra'], name: 'NVRA' },
      {
        id: 'nvra_-_eric_cross_state_mover',
        apiId: ['nvra_-_eric_cross_state_mover'],
        name: 'NVRA - Eric Cross State Mover',
      },
      {
        id: 'nvra_-_eric_cross_state_mover_(soft)',
        apiId: ['nvra_-_eric_cross_state_mover_(soft)'],
        name: 'NVRA - Eric Cross State Mover (Soft)',
      },
      {
        id: 'nvra_-_eric_deceased',
        apiId: ['nvra_-_eric_deceased'],
        name: 'NVRA - Eric Deceased',
      },
      {
        id: 'nvra_-_eric_deceased_(soft)',
        apiId: ['nvra_-_eric_deceased_(soft)'],
        name: 'NVRA - Eric Deceased (Soft)',
      },
      {
        id: 'nvra_-_eric_in_state_duplicate',
        apiId: ['nvra_-_eric_in_state_duplicate'],
        name: 'NVRA - Eric In State Duplicate',
      },
      {
        id: 'nvra_-_eric_in_state_duplicate_(soft)',
        apiId: ['nvra_-_eric_in_state_duplicate_(soft)'],
        name: 'NVRA - Eric In State Duplicate (Soft)',
      },
      {
        id: 'nvra_-_eric_in_state_mover',
        apiId: ['nvra_-_eric_in_state_mover'],
        name: 'NVRA - Eric In State Mover',
      },
      {
        id: 'nvra_-_eric_in_state_mover_(soft)',
        apiId: ['nvra_-_eric_in_state_mover_(soft)'],
        name: 'NVRA - Eric In State Mover (Soft)',
      },
      {
        id: 'nvra_-_eric_ncoa_report',
        apiId: ['nvra_-_eric_ncoa_report'],
        name: 'NVRA - Eric Ncoa Report',
      },
      {
        id: 'nvra_confirmation_response_needed',
        apiId: ['nvra_confirmation_response_needed'],
        name: 'NVRA Confirmation Response Needed',
      },
      {
        id: 'nvra_inactive_address',
        apiId: ['nvra_inactive_address'],
        name: 'NVRA Inactive Address',
      },
      {
        id: 'nvra_-_inactive_address',
        apiId: ['nvra_-_inactive_address'],
        name: 'NVRA Inactive Address (2)',
      },
      { id: 'other', apiId: ['other'], name: 'Other' },
      {
        id: 'overseas_citizen',
        apiId: ['overseas_citizen'],
        name: 'Overseas Citizen',
      },
      {
        id: 'pending_expired',
        apiId: ['pending_expired'],
        name: 'Pending Expired',
      },
      {
        id: 'possible_duplicate',
        apiId: ['possible_duplicate'],
        name: 'Possible Duplicate',
      },
      {
        id: 'presidential_only',
        apiId: ['presidential_only'],
        name: 'Presidential Only',
      },
      {
        id: 'presidential_only_-_expired',
        apiId: ['presidential_only_-_expired'],
        name: 'Presidential Only - Expired',
      },
      {
        id: 'previously_registered',
        apiId: ['previously_registered'],
        name: 'Previously Registered',
      },
      {
        id: 'provisional_not_counted',
        apiId: ['provisional_not_counted'],
        name: 'Provisional Not Counted',
      },
      { id: 'purged', apiId: ['purged'], name: 'Purged' },
      { id: 'registered', apiId: ['registered'], name: 'Registered' },
      {
        id: 'registered_out_of_state',
        apiId: ['registered_out_of_state'],
        name: 'Registered Out Of State',
      },
      {
        id: 'registrant_-_waiting_verification',
        apiId: ['registrant_-_waiting_verification'],
        name: 'Registrant - Waiting Verification',
      },
      {
        id: 'registrant_too_young',
        apiId: ['registrant_too_young'],
        name: 'Registrant Too Young',
      },
      {
        id: 'rejected_expired',
        apiId: ['rejected_expired'],
        name: 'Rejected Expired',
      },
      {
        id: 'removed_after_2_fed_general_elections_in_inactive_status',
        apiId: ['removed_after_2_fed_general_elections_in_inactive_status'],
        name: 'Removed After 2 Fed General Elections In Inactive Status',
      },
      {
        id: 'removed_due_to_sustained_challenge',
        apiId: ['removed_due_to_sustained_challenge'],
        name: 'Removed Due To Sustained Challenge',
      },
      {
        id: 'removed_from_file',
        apiId: ['removed_from_file'],
        name: 'Removed From File (No Reason Code)',
      },
      {
        id: 'removed_under_old_purge_law',
        apiId: ['removed_under_old_purge_law'],
        name: 'Removed Under Old Purge Law',
      },
      {
        id: 'request_from_voter',
        apiId: ['request_from_voter', 'voter_requested'],
        name: 'Request From Voter',
      },
      { id: 'residency', apiId: ['residency'], name: 'Residency' },
      { id: 'returned_mail', apiId: ['returned_mail'], name: 'Returned Mail' },
      { id: 'suspended', apiId: ['suspended'], name: 'Suspended' },
      {
        id: 'temporary_registrant',
        apiId: ['temporary_registrant'],
        name: 'Temporary Registrant',
      },
      {
        id: 'undeliverable_ballot',
        apiId: ['undeliverable_ballot'],
        name: 'Undeliverable Ballot',
      },
      {
        id: 'undeliverable_mailing',
        apiId: ['undeliverable_mailing'],
        name: 'Undeliverable Mailing',
      },
      { id: 'unknown', apiId: ['unknown', 'none'], name: 'Unknown' },
      { id: 'unverified', apiId: ['unverified'], name: 'Unverified' },
      {
        id: 'unverified_new',
        apiId: ['unverified_new'],
        name: 'Unverified New',
      },
      {
        id: 'updated_registration',
        apiId: ['updated_registration'],
        name: 'Updated Registration',
      },
      {
        id: 'new_registration',
        apiId: ['new_registration'],
        name: 'New Registration',
      },
      { id: 'usps_notice', apiId: ['usps_notice'], name: 'USPS Notice' },
      {
        id: 'valid_registration',
        apiId: ['valid_registration'],
        name: 'Valid Registration',
      },
      {
        id: 'verification_pending',
        apiId: ['verification_pending'],
        name: 'Verification Pending',
      },
      { id: 'verified', apiId: ['verified'], name: 'Verified' },
      {
        id: 'voluntary_cancellation',
        apiId: ['voluntary_cancellation'],
        name: 'Voluntary Cancellation',
      },
      {
        id: 'voter_registered_in_error',
        apiId: ['voter_registered_in_error'],
        name: 'Voter Registered In Error',
      },
      {
        id: 'voter_request',
        apiId: ['voter_request', 'voter_requested'],
        name: 'Voter Request',
      },
      {
        id: 'voter_request_-_voluntary',
        apiId: ['voter_request_-_voluntary'],
        name: 'Voter Request - Voluntary',
      },
      {
        id: 'vraz_-_waiting_verification',
        apiId: ['vraz_-_waiting_verification'],
        name: 'VRAZ - Waiting Verification',
      },
    ],
    descriptive:
      '[[population]] who have a current status because of [[value]]',
    hasReference: true,
    weight: 6,
  },
  {
    id: 'admin-user',
    apiId: ['cat_status-user-code'],
    type: 'categorical',
    name: 'Administrative User',
    description:
      'The user code of the election administrator who is responsible for the record change.',
    categories: [
      {
        id: 'other',
        apiId: ['other'],
        name: 'Other',
      },
      {
        id: 'none',
        apiId: ['none'],
        name: 'None',
      },
      {
        id: 'unknown',
        apiId: ['unknown'],
        name: 'Unknown',
      },
    ],
    descriptive: '[[population]] who were updated by [[value]]',
    hasReference: true,
    weight: 70,
  },
  {
    id: 'age',
    apiId: ['age_years'],
    type: 'continuous',
    name: 'Age',
    units: 'years',
    description: 'The age of each voter based on their birth date or year.',
    descriptive: '[[population]] who are [[value]] [[units]] of age',
    hasReference: true,
    weight: 80,
    stateOverrides: { wi: { disabled: true } },
  },
  {
    id: 'language',
    apiId: 'cat_language',
    type: 'categorical',
    name: 'Language',
    description:
      'The language of each voter given the categories offered by the voter file.',
    categories: [
      { id: 'english', apiId: ['english'], name: 'English' },
      { id: 'spanish', apiId: ['spanish'], name: 'Spanish' },
      { id: 'chinese', apiId: ['chinese'], name: 'Chinese' },
      { id: 'vietnamese', apiId: ['vietnamese'], name: 'Vietnamese' },
      { id: 'korean', apiId: ['korean'], name: 'Korean' },
      { id: 'tagalog', apiId: ['tagalog'], name: 'Tagalog' },
      { id: 'japanese', apiId: ['japanese'], name: 'Japanese' },
      { id: 'thai', apiId: ['thai'], name: 'Thai' },
      { id: 'hindi', apiId: ['hindi'], name: 'Hindi' },
      { id: 'khmer', apiId: ['khmer'], name: 'Khmer' },
      { id: 'other', apiId: ['other'], name: 'Other' },
    ],
    descriptive: '[[population]] whose language is [[value]]',
    hasReference: true,
    weight: 85,
  },
  {
    id: 'last-updated',
    apiId: [
      'months_since_last_updated', // iowa
      'months_since_changed', // georgia
      'months_since_last_changed', // pennsylvania
    ],
    type: 'continuous',
    name: 'Last Updated',
    units: 'years',
    descriptive: '[[population]] who were updated [[value]] [[units]] ago',
    conversion: convertToYears,
    description:
      'The length of time since the record of each voter was changed.',
    hasReference: true,
    weight: 90,
  },
  // Decided was not useful.
  // {
  //   id: 'participation',
  //   apiId: 'participation',
  //   type: 'continuous',
  //   name: 'Participation',
  //   units: 'percent of all elections',
  //   descriptive: '[[population]] who participated in [[value]] [[units]]',
  //   conversion: x => x * 100,
  //   description:
  //     'The percentage of elections each voter has participated in since they registered out of all elections in the state regardless of eligibility.',
  //   notes: 'Should be displayed as a percentage',
  //   hasReference: true,
  //   weight: 100
  // },
  {
    id: 'recent-participation',
    apiId: [
      'days_since_last_voted',
      'months_since_last_voted',
      'months_since_last_vote',
      'months_since_lastvoteddate',
    ],
    type: 'continuous',
    name: 'Last Electoral Participation',
    units: 'years ago',
    descriptive:
      '[[population]] who last particpated in an election [[value]] [[units]]',
    conversion: convertToYears,
    outputFormat: { round: 1 },
    description:
      'The length of time since a voter\'s most recent participation in an election.',
    notes: 'Each voter is only counted and categorized once.',
    hasReference: true,
    weight: 110,
  },
  {
    id: 'vote-history',
    apiId: ['cat_has-vote-history'],
    type: 'categorical',
    name: 'Vote History',
    descriptive: '[[population]] with any vote history: [[value]]',
    description: 'Whether each voter has any previous vote history.',
    categories: [
      { id: 'yes', apiId: ['yes'], name: 'Yes' },
      { id: 'no', apiId: ['no'], name: 'No' },
    ],
    hasReference: true,
    weight: 115,
  },
  {
    id: 'last-registered',
    apiId: [
      'months_since_eff_regn',
      'months_since_registration',
      'months_since_regdate',
      'months_since_of_registration',
      'months_since_registrationdate',
      'months_since_registr_dt',
      'months_since_effective_of_registration',
      'months_since_originalregistration',
    ],
    type: 'continuous',
    name: 'Last Registered',
    units: 'years',
    descriptive: '[[population]] who last registered [[value]] [[units]] ago',
    conversion: convertToYears,
    description: 'The length of time since each voter registered.',
    hasReference: true,
    weight: 120,
  },
  {
    id: 'last-contact',
    apiId: ['months_since_last_contact'],
    type: 'continuous',
    name: 'Last Contact',
    units: 'years',
    descriptive: '[[population]] who last had contact [[value]] [[units]] ago',
    conversion: convertToYears,
    description:
      'The length of time since each voter had some form of contact with the election office.',
    hasReference: true,
    weight: 130,
  },
  {
    id: 'first-added',
    apiId: ['months_since_added'],
    type: 'continuous',
    name: 'First Added',
    units: 'years',
    descriptive:
      '[[population]] who were added to the voter role [[value]] [[units]] ago',
    conversion: convertToYears,
    description:
      'The length of time since each voter was added to the voter role.',
    hasReference: true,
    weight: 130,
  },
  {
    id: 'last-inactivity',
    apiId: ['months_since_inact'],
    type: 'continuous',
    name: 'Last Inactive',
    units: 'years',
    descriptive:
      '[[population]] who were last inactive [[value]] [[units]] ago',
    conversion: convertToYears,
    description: 'The length of time since each voter was changed to inactive.',
    hasReference: true,
    weight: 140,
  },
  {
    id: 'last-status-change',
    apiId: [
      //'months_since_status',          // michigan - We are no longer receiving this data
      'months_since_status_change', // pennsylvania
    ],
    type: 'continuous',
    name: 'Last Status Change',
    units: 'years',
    descriptive:
      '[[population]] who had a status change [[value]] [[units]] ago',
    conversion: convertToYears,
    description:
      'The length of time since the status of each voter was changed between inactive and active.',
    hasReference: true,
    weight: 150,
  },
  {
    id: 'last-affiliation',
    apiId: ['months_since_of_party_affiliation'],
    type: 'continuous',
    name: 'Last Party Affilation',
    units: 'years',
    descriptive:
      '[[population]] who registered with a party [[value]] [[units]] ago',
    conversion: convertToYears,
    description: 'The length of time since each voter last set their party.',
    hasReference: true,
    weight: 160,
  },
  {
    id: 'absentee-votes',
    apiId: 'absentee_votes',
    type: 'continuous',
    name: 'Absentee Votes',
    units: 'percent of votes',
    descriptive:
      '[[population]] who voted absentee [[value]] percent of all votes cast',
    conversion: (x) => x * 100,
    formatOptions: { round: 1 },
    description:
      'The percentage of all votes that were cast absentee for each voter.',
    hasReference: true,
    weight: 170,
  },
  {
    id: 'occupation',
    apiId: 'cat_occupation',
    type: 'categorical',
    name: 'Occupation',
    descriptive: '[[population]] who identify as [[value]]',
    description:
      'The occupation of each voter given the categories offered by the voter file.',
    categories: [],
    hasReference: true,
    weight: 190,
  },
  {
    id: 'name-change',
    apiId: ['cat_string-name'],
    type: 'categorical',
    name: 'Name Changes',
    description:
      'The specific name fields that changed out of all the name field changes that occurred.',
    categories: [
      {
        id: 'first-name',
        apiId: [
          'text_name_first',
          'firstname',
          'first_name',
          'name_first',
          'nm_first',
          'first',
          'fname',
        ],
        name: 'First Name',
      },
      {
        id: 'middle-name',
        apiId: [
          'text_name_middle',
          'middlename',
          'middle_name',
          'name_middle',
          'middle_maiden_name',
          'nm_mid',
          'middle',
          'midl_name',
          'mname',
          'mid',
        ],
        name: 'Middle Name',
      },
      {
        id: 'last-name',
        apiId: [
          'text_name_last',
          'lastname',
          'name_last',
          'nm_last',
          'last_name',
          'last',
          'surname',
          'lname',
        ],
        name: 'Last Name',
      },
    ],
    hasReference: false,
    descriptive: 'The proportion of [[value]] changes',
    weight: 220,
  },
  {
    id: 'address-change',
    apiId: ['cat_string-address'],
    type: 'categorical',
    name: 'Address Changes',
    description:
      'The specific address fields that changed out of all the address field changes that occurred.',
    categories: [],
    hasReference: false,
    descriptive: 'The proportion of [[value]] changes',
    weight: 220,
  },
  {
    id: 'first-edit-distance',
    apiId: [
      'text_name_first_edit_dist',
      'firstname_edit_dist',
      'first_name_edit_dist',
      'name_first_edit_dist',
      'nm_first_edit_dist',
      'first_edit_dist',
    ],
    type: 'continuous',
    name: 'First Name',
    units: 'characters changed',
    description:
      'The number of characters that changed in each voter\'s first name.',
    descriptive:
      '[[population]] who had [[value]] characters changed in their first name',
    hasReference: false,
    isDiscrete: true,
    weight: 230,
  },
  {
    id: 'middle-edit-distance',
    apiId: [
      'text_name_middle_edit_dist',
      'middlename_edit_dist',
      'middle_name_edit_dist',
      'name_middle_edit_dist',
      'middle_maiden_name_edit_dist',
      'nm_mid_edit_dist',
      'middle_edit_dist',
      'midl_name_edit_dist',
      'mid_edit_dist',
    ],
    type: 'continuous',
    name: 'Middle Name',
    units: 'characters changed',
    description:
      'The number of characters that changed in each voter\'s middle name.',
    descriptive:
      '[[population]] who had [[value]] characters changed in their middle name',
    hasReference: false,
    isDiscrete: true,
    weight: 240,
  },
  {
    id: 'last-edit-distance',
    apiId: [
      'text_name_last_edit_dist',
      'lastname_edit_dist',
      'last_name_edit_dist',
      'name_last_edit_dist',
      'nm_last_edit_dist',
      'last_edit_dist',
      'surname_edit_dist',
    ],
    type: 'continuous',
    name: 'Last Name',
    units: 'characters changed',
    description:
      'The number of characters that changed in each voter\'s last name.',
    descriptive:
      '[[population]] who had [[value]] characters changed in their last name',
    hasReference: false,
    isDiscrete: true,
    weight: 250,
  },
  // NY
  // months_since_purge

  // CO
  // months_since_effective

  // AZ2
  // months_since_effectivedateofchange

  // Determined to be not useful
  // {
  //   id: 'election-size',
  //   apiId: ['average_election_size', 'local_engagement'],
  //   type: 'continuous',
  //   name: 'Election Size',
  //   units: 'voters in election',
  //   descriptive: '[[population]] who voted in elections with an average of [[value]] voters in them',
  //   formatOptions: { round: 0 },
  //   description:
  //     'The average election size over every election in which each voter participated in.',
  //   hasReference: true,
  //   weight: 180
  // },
  // {
  //   id: 'partisanship-dem',
  //   apiId: ['partisanship_dem'],
  //   type: 'continuous',
  //   name: 'Primary Vote: Democrat',
  //   units: 'percent of votes',
  //   conversion: x => x * 100,
  //   description:
  //     'The percentage of all votes that were cast in a democratic primary for each voter.',
  //   descriptive: '[[population]] who voted Democrat [[value]] of all their primary votes',
  //   hasReference: true,
  //   weight: 200
  // },
  // {
  //   id: 'partisanship-rep',
  //   apiId: ['partisanship_rep'],
  //   type: 'continuous',
  //   name: 'Primary Vote: Republican',
  //   units: 'percent of votes',
  //   conversion: x => x * 100,
  //   description:
  //     'The percentage of all votes that were cast in a republican primary for each voter.',
  //   descriptive: '[[population]] who voted Republican [[value]] of all their primary votes',
  //   hasReference: true,
  //   weight: 210
  // },
];

export default demographics;

// Dependencies
import isFunction from 'lodash/isFunction';
import objectHash from 'object-hash';

import { gaUaEquivalentEvent } from './google-analytics';

// Mark as fired
let gaFired = {};

/**
 * Wrapper around a function to call a Google Analytics custom event afterwards.
 *
 * @todo - Move this to the google-analytics.js module, and eventually change
 *   the calls to be aligned with gaUaEquivalentEvent.
 *
 * Examples:
 *
 * gaEventWrapper(functionToFire, { category: 'test', action: 'test' })(values, for, function);
 *
 * onChange={gaEventWrapper(
 *   e => this.handlePreferenceChange(e, profileController), {
 *     category: 'user',
 *     action: 'prefer sms mfa'
 * })}
 *
 * onChange: gaEventWrapper(setStates, s => {
 *   return {
 *     category: 'global filter',
 *     action: 'state',
 *     label: s ? booleanMapToArray(s).join(',') : 'none'
 *   };
 * })
 * @param {Function} handler - Function to call
 * @param {object|Function} gaOptions - Object of options to pass to GA, or
 *   function that receives the same arguments as the handler.
 * @param {string} gaOptions.category - Category of event, usually in format
 *   of sections with a dash, such as "ballots - election - addresses table".
 * @param {string} gaOptions.action - Action of event, something like "save"
 *   or "review" or "sort"
 * @param {string} gaOptions.label - (optional) Label for the event, such
 *   as "ascending" or "descending" or "name" or "email"
 * @param {string} gaOptions.value - (optional) If the event has a number
 *   value, such as a count or a time, it can be passed here.
 * @param {boolean} gaOnce - Boolean on whether this event should only be
 *   called once.
 * @returns {*} - What the handler returns
 */
export default function gaEventWrapper(handler, gaOptions, gaOnce = false) {
  return (...args) => {
    // Run handler first
    handler(...args);

    // Make id if need to check
    let gaFiredId;
    if (gaOnce) {
      gaFiredId = objectHash(gaOptions);
    }

    // Check if we need to fire
    if (!gaOnce || (gaOnce && gaFiredId && !gaFired[gaFiredId])) {
      let eventOptions = isFunction(gaOptions) ? gaOptions(...args) : gaOptions;

      // Translate event options
      let name = eventOptions.name || eventOptions.action;
      let options = {
        category: eventOptions.category,
        label: eventOptions.label,
        value: eventOptions.value,
        interactive: !eventOptions.non_interaction,
      };
      gaUaEquivalentEvent(name, options);

      // Mark as fired
      if (gaFiredId) {
        gaFired[gaFiredId] = true;
      }
    }
  };
}

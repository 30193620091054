/**
 * Legal agreement banner
 */

// Dependencies
import './LegalAgreementBanner.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import CoreComponent from '../../extensions/CoreComponent';

// Header component
export default class LegalAgreementBanner extends CoreComponent {
  // Props
  static propTypes = {};
  static defaultProps = {};

  render() {
    return (
      <div className="legal-agreement-banner container-lg">
        <p>
          We&apos;ve updated our{' '}
          <strong>Terms and Conditions of Use and our Privacy Policy</strong>;
          please read through in full to get full access to VoteShield.
        </p>

        <Link
          className="button compact success legal-agreement-action"
          role="button"
          to="/onboarding/returning-user/legal-only"
        >
          Legal agreements
        </Link>
      </div>
    );
  }
}

/**
 * Common statistic config
 */

// Export config
const statistics = {
  confidenceInterval: 0.95,
};

export default statistics;

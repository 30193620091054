/**
 * Component to help with balancing text.  Currently
 * focuses on removing widows.
 */

// Dependencies
import React from 'react';
import { renderToString } from 'react-dom/server';

import CoreComponent from '../../extensions/CoreComponent';

// Components
export default class BalanceText extends CoreComponent {
  static propTypes = {};
  static defaultProps = {};
  state = {};

  // Main balance handling
  balance(children) {
    try {
      let converted = renderToString(children);
      converted = converted.trim().replace(/\s+((\S|<[^>]+>)*)$/g, '&nbsp;$1');
      return converted;
    }
    catch (e) {
      console.error(e);
      return false;
    }
  }

  // Main render
  render() {
    let balanced = this.balance(this.props.children);

    return balanced ? (
      <span
        dangerouslySetInnerHTML={{ __html: this.balance(this.props.children) }}
      />
    ) : (
      <>{this.props.children}</>
    );
  }
}

/**
 * Error boundary component using Sentry
 */

// Dependencies
import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import ErrorReportButton from '../../components/links/ErrorReportButton';
import ErrorPage from '../../components/pages/error/ErrorPage';

export default class ErrorBoundary extends Component {
  static propTypes = {
    children: PropTypes.any,
  };
  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = { error: null, eventId: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    const { error } = this.state;

    if (error) {
      // Render fallback UI
      return (
        <ErrorPage>
          <p className="mb-2">
            We&apos;re sorry for the inconvenience; unfortunately an error
            occurred that we were not sure how to handle.
          </p>

          {error && error.toString && (
            <div className="mb-2">
              <code className="display-block p-1">{error.toString()}</code>
            </div>
          )}

          <div className="alert-box error">
            <p>
              The error will be logged in our system, but any more information
              you can provide will certainly be helpful.
            </p>

            <ErrorReportButton error={error || 'Unknown error'}>
              Feedback
            </ErrorReportButton>
          </div>
        </ErrorPage>
      );
    }
    else {
      // When there's not an error, render children untouched
      return this.props.children;
    }
  }
}

// Dependencies
import queryString from 'query-string';

import config from '../../config';
import { gaApiTiming, gaException } from '../ga/google-analytics';
import formatResponse from './format-response';

/**
 * Wrapper around API call to
 * https://github.com/Voteshield/Inspector
 *
 * @param {string} path API path, such as 'authenticate'
 * @param {object} options Shortcut options and fetch options
 * @param {object} options.query Object of data to pass as query string
 * @param {object} options.body Object of data to pass as body data
 * @param {string} options.method HTTP method, defaults to 'post' if
 *   a body option is provided, and 'get' otherwise
 * @param {boolean} options.bypassCache Use to add a query suffix to get
 *   around http/browser cache
 * @returns {Promise} Resolves to formatted response object with attached
 *   meta data included ok and status
 */
export default async function request(path, options = {}) {
  if (!path) {
    throw new Error('No path provided to inspector API call.');
  }

  // Start timer
  const startTimer = new Date().getTime();

  // Deconstruct options
  let { query, body, method, credentials, bypassCache, ...fetchOptions } =
    options;

  // By pass cache
  if (bypassCache) {
    query = query || {};
    query._no_cache = +new Date();
  }

  // Make endpoint
  const endpoint = `${config.apiEndpoint}${path}${
    query ? '?' + queryString.stringify(query) : ''
  }`;

  // Make options
  fetchOptions = {
    ...fetchOptions,
    method: !method && body ? 'post' : method ? method : 'get',
    credentials: credentials === undefined ? 'include' : credentials,
    body: body ? JSON.stringify(body) : undefined,
  };

  // Do fetch, if fails, note exception
  try {
    let f = await window.fetch(endpoint, fetchOptions);

    // Note time in GA
    let endTimer = new Date().getTime();
    gaApiTiming({
      name: 'load time',
      endpoint: path,
      ms: endTimer - startTimer,
    });

    return formatResponse(f);
  }
  catch (e) {
    // Note exception in GA
    if (e.name !== 'AbortError') {
      gaException(`API error for path "${path}": ${e.toString()}`);
    }

    throw e;
  }
}

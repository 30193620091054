/**
 * Material Icon shortcut
 */

// Dependencies
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';

// Components
export default class IconMaterial extends CoreComponent {
  static propTypes = {
    id: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
    className: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.string,
      PropTypes.array,
    ]),
    title: PropTypes.string,
    // Since we are most often hiding this from screen readers, alt is not usually
    // necessary.
    alt: PropTypes.string,
  };
  static defaultProps = {
    hidden: true,
  };
  state = {};

  // Render
  render() {
    // Shortcuts
    const { id, hidden, children, className, title, alt } = this.props;

    return (
      <i
        className={classNames('material-icons-outlined icon-font', className)}
        aria-hidden={hidden}
        // focusable={hidden}
        title={title}
        aria-label={alt}
        role="img"
      >
        {id ? id : children}
      </i>
    );
  }
}

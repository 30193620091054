// Dependencies
import findLastIndex from 'lodash/findLastIndex';
import sortBy from 'lodash/sortBy';

/**
 * Simple function to find closest value that is below
 * a given value/needle.
 *
 * NOTE: Float point operations in JS are silly and
 * when you do soemthing like 0.5 - 0.1 you may
 * get 0.0999999999999 which is less than 0.1.
 *
 * @param {number} needle Value to comapre against
 * @param {Array} haystack Array of elements to look through
 * @param {Function} accessor Function that defines how to get a value from
 *   an element in the haystack.
 * @returns {number} Index of element in haystack
 */
export default function findClosestFloor(
  needle,
  haystack = [],
  accessor = (x) => x,
) {
  let sorted = sortBy(haystack, accessor);

  // Easy checks outside range
  if (needle >= accessor(sorted[sorted.length - 1])) {
    return sorted.length - 1;
  }
  if (needle <= accessor(sorted[0])) {
    return 0;
  }

  return findLastIndex(haystack, (d) => {
    let value = accessor(d);
    return value <= needle;
  });
}

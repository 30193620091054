/**
 * Wrapper around abort controller for window fetch
 */

/**
 * Create a Browser AbortController if available.
 *
 * @param {boolean} throwOnUnsupported - Whether to throw if browser does not support.
 * @returns {AbortController} An abort controller
 */
export default function abortController(throwOnUnsupported = false) {
  if (window.AbortController) {
    return new window.AbortController();
  }
  else if (throwOnUnsupported) {
    throw new Error('AbortController is not available in window.');
  }
  else {
    return {
      signal: undefined,
      abort: () => {
        console.warn('AbortController is not available in window.');
      },
    };
  }
}

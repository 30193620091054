/**
 * Simple component provider to check if user is logged in
 * and has the authenticated authorization and has a specific
 * role
 */

// Dependencies
import PropTypes from 'prop-types';
import React from 'react';
import { Children } from 'react';
import { Navigate } from 'react-router-dom';

import { user } from '../../config';
import CoreComponents from '../../extensions/CoreComponent';

// Component
export default class AuthorizedAuthenticatedWithRole extends CoreComponents {
  static propTypes = {
    redirect: PropTypes.string,
    roles: PropTypes.array,
  };
  static defaultProps = {
    roles: [],
  };

  render() {
    const { roles, redirect } = this.props;

    if (
      user &&
      user.isAuthenticated() &&
      user.hasLegalAgreedTo() &&
      user.hasAnyRole(roles)
    ) {
      return Children.only(this.props.children);
    }

    return redirect ? <Navigate to={redirect} /> : '';
  }
}

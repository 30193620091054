/**
 * Email link component
 */

// Dependencies
import classNames from 'classnames';
import _each from 'lodash/each';
import _filter from 'lodash/filter';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import React from 'react';
import UAParser from 'ua-parser-js';

import { user } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';

// Only need to do this once
const ua = UAParser();

// Components
export default class FeedbackFormLink extends CoreComponent {
  // Prop types
  static propTypes = {
    variant: PropTypes.oneOf(['', 'button']),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    feedbackType: PropTypes.oneOf([
      '',
      'Comments',
      'Questions',
      'Bug Reports',
      'Feature Request',
    ]),
    feedback: PropTypes.string,
    browser: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.oneOf([
        '',
        'Google Chrome',
        'Firefox',
        'Safari',
        'Opera',
        'Unknown',
      ]),
    ]),
    device: PropTypes.string,
    url: PropTypes.string,
  };

  // Prop defaults
  static defaultProps = {};

  // Make defaults
  makeDefaults({ device, browser }) {
    if (!device) {
      let uaDevice = `${ua.device.vendor} ${ua.device.type} ${ua.device.model}`;
      let usOS = `${ua.os.name} ${ua.os.version}`;
      device = _filter([
        ua.device.vendor ? uaDevice : '',
        ua.os.name ? usOS : '',
      ]).join(' | ');
    }

    let browserOther;
    if (!browser) {
      browserOther = `${ua.browser.name} ${ua.browser.version}`;
    }

    // Email from user
    let email;
    if (user && user.isAuthenticated() && user.profile && user.profile.email) {
      email = user.profile.email;
    }

    return {
      browser,
      browserOther,
      device,
      email,
    };
  }

  // Create link
  createLink() {
    // Field mapping
    const fieldMap = {
      email: 'entry.1373762784',
      feedbackType: 'entry.1591633300',
      feedback: 'entry.326955045',
      device: 'entry.1466348317',
      browser: 'entry.1295134874',
      browserOther: 'entry.1295134874.other_option_response',
      url: 'entry.870808883',
    };

    // Compile values
    let values = {
      ...pick(this.props, [
        'feedbackType',
        'feedback',
        'device',
        'browser',
        'url',
      ]),
      ...this.makeDefaults(this.props),
    };
    // TODO: This should actually respond to URL changes
    values.url = values.url || window.location.href.toString();
    if (values.browserOther) {
      values.browser = '__other_option__';
    }

    // Make link
    let link =
      'https://docs.google.com/forms/d/e/1FAIpQLSdcnBKmTA6bpZePq-oXkAJ6CDXP6cbjFYCyYx3fmoGRTbre7Q/viewform?usp=pp_url';
    let fields = [];
    _each(fieldMap, (param, field) => {
      if (values[field]) {
        fields.push(`${param}=${encodeURIComponent(values[field])}`);
      }
    });
    link = `${link}&${fields.join('&')}`;

    return link;
  }

  // Click handler
  clickHandler = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const link = this.createLink();
    const a = document.createElement('a');
    a.href = link;
    a.target = '_blank';
    a.rel = 'noopener noreferrer';
    a.click();
  };

  // Render
  render() {
    // Shortcuts
    const { variant, className, children } = this.props;
    const link = this.createLink();

    return (
      <a
        href={link}
        role={variant === 'button' ? 'button' : undefined}
        className={classNames(className)}
        target="_blank"
        rel="noopener noreferrer"
        onClick={this.clickHandler}
      >
        {variant === 'button' && (
          <i
            className="pr-half material-icons-outlined icon-font"
            aria-hidden="true"
          >
            feedback
          </i>
        )}
        {children}
      </a>
    );
  }
}

/**
 * Locale types
 *
 * Note that "id" should be what is used in the API
 */

const localeTypes = [
  {
    id: 'county',
    name: 'County',
    namePlural: 'Counties',
    set: 'counties',
    // Counties in Virginia actually include independent cities, and so
    // the suffixes are actually in the data already.
    // See: https://www.vaco.org/county-profiles/
    outputLocale: (locale, stateId) =>
      stateId === 'va' ? locale.name : `${locale.name} County`,
    outputLocaleShort: (locale) => `${locale.name}`,
  },
  {
    id: 'jurisdiction',
    name: 'Jurisdiction',
    namePlural: 'Jurisdictions',
    set: 'jurisdictions',
    outputLocale: (locale) => `${locale.name}`,
    outputLocaleShort: (locale) =>
      `${locale.name.replace(/\([a-z0-9]*\)/i, '').trim()}`,
  },
  {
    id: 'congressional_district',
    name: 'Congressional District',
    nameShort: 'Congressional',
    namePlural: 'Congressional Districts',
    set: 'congressionalDistricts',
    outputLocale: (locale) => `Congressional District ${locale.name}`,
    outputLocaleShort: (locale) => `CD ${locale.name}`,
    outputLocaleSort: (locale) => locale.name.padStart(4, '0'),
  },
];

export default localeTypes;

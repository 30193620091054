/**
 * Email link component
 */

// Dependencies
import classNames from 'classnames';
import isArray from 'lodash/isArray';
import PropTypes from 'prop-types';
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';
import gaEventWrapper from '../../modules/ga/event-wrapper';

// Components
export default class EmailLink extends CoreComponent {
  // Prop types
  static propTypes = {
    variant: PropTypes.oneOf(['', 'button']),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    email: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    cc: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    bcc: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    gaEvent: PropTypes.object,
    addSubjectReference: PropTypes.bool,
  };

  // Prop defaults
  static defaultProps = {
    addSubjectReference: true,
  };

  // Render
  render() {
    // Shortcuts
    const {
      variant,
      className,
      children,
      email,
      subject,
      body,
      cc,
      bcc,
      gaEvent,
      addSubjectReference,
    } = this.props;

    // Add random number to subject
    let altSubject = subject;
    if (addSubjectReference && subject) {
      altSubject = `${subject} (ref. ${Math.floor(Date.now() / 1000)})`;
    }

    // Make link
    let link = `mailto:${encodeURIComponent(email)}?subject=${
      altSubject ? encodeURIComponent(altSubject) : ''
    }&body=${body ? encodeURIComponent(body) : ''}&cc=${
      cc ? encodeURIComponent(isArray(cc) ? cc.join(',') : cc) : ''
    }&bcc=${cc ? encodeURIComponent(isArray(bcc) ? bcc.join(',') : bcc) : ''}`;

    // Make click handler
    const clicker = gaEvent ? gaEventWrapper(() => true, gaEvent) : () => true;

    return (
      <a
        href={link}
        role={variant === 'button' ? 'button' : undefined}
        className={classNames(className)}
        disabled={!email}
        target="_blank"
        rel="noopener noreferrer"
        onClick={clicker}
      >
        {variant === 'button' && (
          <i
            className="pr-half material-icons-outlined icon-font"
            aria-hidden="true"
          >
            mail
          </i>
        )}
        {children}
      </a>
    );
  }
}

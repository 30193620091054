/**
 * Simple wrapper componet for progress styles
 */

// Dependencies
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';

// Components
export default class Progress extends CoreComponent {
  // Prop types
  static propTypes = {
    variant: PropTypes.oneOf(['thin']),
    // Should be 0 - 1
    progress: PropTypes.number,
    indeterminate: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    classNameBar: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  // Prop defaults
  static defaultProps = {
    progress: 0,
  };

  // Render
  render() {
    // Shortcuts
    const { progress, color, variant, indeterminate, className, classNameBar } =
      this.props;

    const innerStyle = {
      backgroundColor: color || undefined,
    };
    if (!indeterminate) {
      innerStyle.width = `${progress * 100}%`;
    }

    return (
      <div
        className={classNames(
          'progress',
          { thin: variant === 'thin', indeterminate },
          className,
        )}
      >
        <div
          className={classNames('progress-bar', classNameBar)}
          style={innerStyle}
          role={indeterminate ? '' : 'progressbar'}
          aria-valuenow={indeterminate ? '' : progress * 100}
          aria-valuemin={indeterminate ? '' : '0'}
          aria-valuemax={indeterminate ? '' : '100'}
        />
      </div>
    );
  }
}

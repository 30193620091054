/**
 * Custom population automatic creators config
 */

const customPopulationAutomaticCreators = [
  'ballotshield',
  'duplicates_auto',
  'quiet_period_auto',
];

export default customPopulationAutomaticCreators;

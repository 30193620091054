/**
 * JS Error page meant to be used in the Error Boundary
 */

// Dependencies
import PropTypes from 'prop-types';
import React from 'react';

import CoreComponent from '../../../extensions/CoreComponent';
import PageTitle from '../../document/PageTitle';

// Main component
export default class ErrorPage extends CoreComponent {
  // Prop types
  static propTypes = {
    title: PropTypes.string,
    children: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.func,
      PropTypes.node,
    ]),
  };

  // Default props
  static defaultProps = {
    title: 'We ran into a problem',
    children: (
      <p>Unfortunately an error occurred that we were not able to deal with.</p>
    ),
  };

  // Main render
  render() {
    // Shortcuts
    let { title, children } = this.props;

    return (
      <div className="container-lg mb-4">
        <PageTitle title="Error" />

        <div className="pt-2 row">
          <div className="col-1">
            <i
              className={
                'pt-1 material-icons-outlined icon-font color-error font-largest'
              }
              aria-hidden="true"
            >
              error_outline
            </i>
          </div>

          <div className="col-au5o col-md-6">
            <h1 className="page-title">{this.renderProp(title)}</h1>

            {this.renderProp(children)}
          </div>
        </div>
      </div>
    );
  }
}

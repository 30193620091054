/**
 * Checks if string (or stringable) has a capital letter.
 *
 * TODO: Expand into accented characters or unicode?
 *
 * @param {string} input String input to check
 * @param {number} length Minimum number of letters to find
 * @returns {boolean} Whether validated or not
 */
export default function hasCapitalLetters(input, length = 1) {
  if (!input || !input.toString) {
    return false;
  }

  let matches = input.toString().match(/[A-Z]/g) || [];
  return matches.length >= length;
}

/**
 * 404/not-found page
 */

// Dependencies
import React from 'react';

import CoreComponent from '../../../extensions/CoreComponent';
import PageTitle from '../../document/PageTitle';

// Login page componet
export default class NotFoundPage extends CoreComponent {
  static propTypes = {};
  static defaultProps = {};

  // Main render
  render() {
    return (
      <div className="container-lg mb-4">
        <PageTitle title="Not found" />

        <h1 className="page-title">Not found</h1>

        <p>The page you are looking for was not found in our system.</p>
      </div>
    );
  }
}

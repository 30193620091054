/**
 * A component around React Helmet to support
 * multiple titles from different components.
 *
 * React Helmet seems to work best with URL query updates
 */

// Dependencies
import _filter from 'lodash/filter';
import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

// We want to keep track of different titles
let pageTitles = {};

// Main module
export default class PageTitle extends Component {
  // Prop types
  static propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    sort: PropTypes.any,
  };

  // Prop defaults
  static defaultProps = {};

  // On unmount, remove entry
  componentWillUnmount() {
    delete pageTitles[this.props.id || 'default'];
  }

  // Compute page title
  computePageTitle({ id, title, sort }) {
    // Update global page title
    if (title) {
      pageTitles[id || 'default'] = {
        title,
        sort: id && sort ? sort : id && title ? title : 99999999,
      };
    }
    else if (id) {
      delete pageTitles[id];
    }

    // Put together
    return _filter(sortBy(pageTitles, (t) => t.sort).map((t) => t.title)).join(
      ' | ',
    );
  }

  // Render
  render() {
    const pageTitle = this.computePageTitle(this.props);

    return (
      <Helmet>
        <title>{pageTitle} | VoteShield</title>
      </Helmet>
    );
  }
}

/**
 * Mark environment
 */

// Dependencies
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';
import environment from '../../modules/browser/environment';

// Styles
//import './EnvironmentHeader.scss';

// Header component
export default class EnvironmentHeader extends CoreComponent {
  static propTypes = {};
  static defaultProps = {};

  render() {
    const env = environment();

    // Production, don't show anything
    if (env === 'production') {
      return '';
    }

    return (
      <div className="environment-header">
        <p className="container-lg color-purple pt-half pb-half mb-0 text-center">
          {env === 'local' ? (
            <span>
              You are currently viewing a <strong>local development</strong>{' '}
              version of VoteShield.
            </span>
          ) : env === 'staging' ? (
            <span>
              You are currently viewing a <strong>Staging</strong> version of
              VoteShield. This version is not meant to be shared publicly and
              could contain inaccurate data.
            </span>
          ) : env === 'development' ? (
            <span>
              You are currently viewing a <strong>Development</strong> version
              of VoteShield. This version is not meant to be shared publicly and
              could contain inaccurate data.
            </span>
          ) : env === 'beta' ? (
            <span>
              Welcome to <strong>VoteShield Beta</strong>! This site is for
              testing upcoming features. If you&apos;d like to get back to the
              original VoteShield please go to{' '}
              <a href="https://voteshield.us">voteshield.us</a>.
            </span>
          ) : (
            <span>Unknown environment</span>
          )}
        </p>
      </div>
    );
  }
}

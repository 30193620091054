/**
 * Fun facts used in long loading.
 */

const loadingFacts = [
  {
    fact: 'George Washington is the only U.S. president in history to win 100% of the Electoral College vote.',
    source:
      'Cummins, Joseph. 2015. Anything for a Vote: Dirty Tricks, Cheap Shots, and October Surprised in U.S Presidential. Philadelphia, PA: Quirk Books.',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'The Constitution does not state when Election Day should be, which meant that in the early 1800s, people could vote from April to December.',
    source:
      'Cummins, Joseph. 2015. Anything for a Vote: Dirty Tricks, Cheap Shots, and October Surprised in U.S Presidential. Philadelphia, PA: Quirk Books.',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'In 1845, Congress decided that voting day would be the first Tuesday after the first Monday in November, which was after the fall harvest and before winter conditions made travel too difficult.',
    source:
      'Cummins, Joseph. 2015. Anything for a Vote: Dirty Tricks, Cheap Shots, and October Surprised in U.S Presidential. Philadelphia, PA: Quirk Books',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'Until 1937, presidents weren’t sworn in until March 4 because it took so long to count and report ballots. In light of better technology, the 20th amendment moved inauguration day to noon on January 20th.',
    source:
      'Cummins, Joseph. 2015. Anything for a Vote: Dirty Tricks, Cheap Shots, and October Surprised in U.S Presidential. Philadelphia, PA: Quirk Books.',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'The first election to use a voting machine was in 1892. Though it was actually invented earlier, candidates initially opposed the idea because it eliminated the wheeling and dealing for votes over the phone.',
    source:
      'Goodman, Susan E. See How They Run: Campaign Dreams, Election Schemes, and the Race to the White House. New York, NY: MacMillan, 2008.',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'Before the 1804, the presidential candidate who received the second highest electoral votes became vice-president.',
    source:
      'Clift, Eleanor and Matthew Spieler. Selecting a President. New York, NY: St. Martin’s Press, 2012.',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'George Washington gave the shortest inauguration speech at 135 words. William Henry Harrison’s was the longest, at 8,445 words. He spoke for over two hours in a heavy snowstorm, which made him catch a cold and ultimately die from pneumonia one month later.',
    source:
      'Clift, Eleanor and Matthew Spieler. Selecting a President. New York, NY: St. Martin’s Press, 2012.',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'American astronauts on the ISS can vote in elections from orbit.',
    source:
      'Wall, Mike. "Extreme Voting: How Astronauts Cast Ballots from Space." Space. November 5, 2012. Accessed: September 19, 2016.',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'The United States is ranked 139th out of 172 countries in voter participation.',
    source:
      'Clift, Eleanor and Matthew Spieler. Selecting a President. New York, NY: St. Martin’s Press, 2012.',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'The word "election" is from the Latin eligere, which means, "to pick out, select" and is related to the word "lecture."',
    source:
      '"Election." Online Etymological Dictionary. Accessed: May 28, 2016.',
    link: 'https://www.factretriever.com/us-presidential-elections-facts',
  },
  {
    fact: 'The state of Texas allows people to vote on election day from space. David Wolf was the first reported astronaut to email an encrypted ballot to Earth during a local election in 1997.',
    source:
      'Koren, Marina. "The First American to Vote From Space." November 8, 2016. Accessed: August 19, 2020.',
    link: 'https://www.theatlantic.com/science/archive/2016/11/voting-from-space/506960/',
  },
];

export default loadingFacts;

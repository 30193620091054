/**
 * Context for filtering on global level.  There are many properties
 * that should remain as we navigate
 */

// Dependencies
import { createContext } from 'react';

// Main context
const FilterContext = createContext({});

export default FilterContext;

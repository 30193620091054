// Dependencies
import {
  captureException as sentryCaptureException,
  withScope,
} from '@sentry/browser';
import _each from 'lodash/each';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isPlainObject from 'lodash/isPlainObject';
import kebabCase from 'lodash/kebabCase';
import merge from 'lodash/merge';

/**
 * Wrapper around capture exception to add context.
 *
 * @param {Error} exception - Exception to send.
 * @param {object} context - Context data to add to the exception sent to Sentry.
 */
export default function captureException(exception, context = {}) {
  if (!exception) {
    return;
  }

  // Allow properties to be added to exception
  let combinedContext = merge({}, context, exception);

  withScope((scope) => {
    // Level
    if (combinedContext.level) {
      scope.setLevel(combinedContext.level);
    }

    // Tags
    if (combinedContext.tags) {
      _each(combinedContext.tags, (v, k) => {
        scope.setTag(`x-${kebabCase(k)}`, v);
      });
    }

    // For some reason, it is difficult to get the URI that the
    // user was on in the Sentry interface, so add it here.
    scope.setContext('x-uri', {
      value: window.location.href,
    });

    // Extra context (either defined as extras or debug)
    let extraContext = merge(
      {},
      combinedContext.extras || {},
      combinedContext.debug || {},
    );
    if (!isEmpty(extraContext)) {
      _each(extraContext, (v, k) => {
        // String values don't seem to show up in UI, so wrap
        v = isPlainObject(v) || isArray(v) ? v : { value: v };
        scope.setContext(`x-${kebabCase(k)}`, v);
      });
    }

    // Check if username is sent along, such as with the case of
    // logging in.  There doesn't seem to be an official way to check
    // if there was a user, so this is a bit brittle.
    if (!scope._user?.username && extraContext.username) {
      scope.setUser({
        username: extraContext.username,
      });
    }

    sentryCaptureException(exception);
  });
}

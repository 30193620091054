/**
 * Alert component
 */

// Dependencies
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';
import dispatch from '../../modules/events/dispatch';

// Components
export default class Alert extends CoreComponent {
  // Prop types
  static propTypes = {
    role: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    variant: PropTypes.oneOf(['bar', 'box', 'box-small']),
    closable: PropTypes.bool,
    onClose: PropTypes.func,
  };

  // Prop defaults
  static defaultProps = {
    role: 'alert',
    variant: 'bar',
    closable: true,
  };

  // Default state
  state = {
    open: true,
  };

  // Handle close
  handleClose = (event) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    this.setState({
      open: false,
    });

    dispatch(this.props.onClose, event);
  };

  // Render
  render() {
    // Shortcuts
    const { className, role, variant, children, closable } = this.props;
    const { open } = this.state;

    // If not open, don't show anything
    if (!open) {
      return '';
    }

    return (
      <div
        className={classNames(
          {
            'alert-bar': variant === 'bar',
            'alert-box': variant === 'box',
            'alert-box small': variant === 'box-small',
          },
          className,
        )}
        role={role}
      >
        {closable && (
          <button
            className="compact link small m-0 float-right"
            onClick={this.handleClose}
          >
            <i className="material-icons-outlined icon-font" aria-hidden="true">
              clear
            </i>
          </button>
        )}
        {children}
      </div>
    );
  }
}

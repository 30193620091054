/**
 * Demographics
 *
 * Id should be corresponding to backend API values
 */

const demographicGroups = [
  {
    id: 'party',
    name: 'Party',
    descriptive: 'split by registered party',
    options: [
      { id: 'D', name: 'Democrat', color: 'blue' },
      { id: 'R', name: 'Republican', color: 'red' },
      { id: 'N', name: 'No Party Affliation', color: 'teal' },
    ],
  },
  {
    id: 'age',
    name: 'Age',
    descriptive: 'split by age',
    options: [
      { id: '18-34', name: '18-34', color: 'pink' },
      { id: '35-49', name: '35-49', color: 'purple' },
      { id: '50-64', name: '50-64', color: 'orange' },
      { id: '65-130', name: '65+', color: 'green' },
    ],
  },
  {
    id: 'none',
    name: 'None',
    descriptive: '',
    options: [],
  },
];

export default demographicGroups;

// Dependencies
import _filter from 'lodash/filter';

import queryStringToValue from './to-value';

/**
 * Create function to convert from query-string string to array
 *
 * @param {Function} converter - Function to convert each value; defaults to queryStringToValue.
 * @returns {Function} - Function to convert.
 */
export default function toArrayOfFromQueryString(converter) {
  converter = converter || queryStringToValue;

  return (input) => {
    // Convert from query string should be silent
    try {
      return input
        ? _filter(input.split(',').map((i) => converter(i)))
        : undefined;
    }
    catch (e) {
      console.error(e);
      return undefined;
    }
  };
}

/**
 * Logout page.  Wrapper around logout component
 */

// Dependencies
import React from 'react';

import CoreComponent from '../../../extensions/CoreComponent';
import PageTitle from '../../document/PageTitle';
import Logout from '../../login/Logout';

// Logout page componet
export default class LogoutPage extends CoreComponent {
  static propTypes = {};
  static defaultProps = {};

  // Main render
  render() {
    return (
      <>
        <PageTitle title="Logout" />

        <div className="container-lg pt-2 pt-md-4 mb-2">
          <h1 className="h2">Logout</h1>

          <Logout />
        </div>
      </>
    );
  }
}

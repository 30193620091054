/**
 * Convert general value to a query-string string.
 *
 * @param {*} input - Various input to convert to string
 * @returns {string} - Converted string
 */
export default function fromValueToQueryString(input) {
  let stringified;
  try {
    stringified = typeof input === 'object' ? JSON.stringify(input) : '';
  }
  catch (e) {
    stringified = '';
  }

  return input === undefined || input === null
    ? ''
    : input === true
      ? 'true'
      : input === false
        ? 'false'
        : stringified
          ? stringified
          : input.toString
            ? input.toString()
            : '';
}

/**
 * Dispatch an event function, essentially just a wrapper around
 * calling a function.
 */

// Dependencies
import isFunction from 'lodash/isFunction';

/**
 * Dispatch an event function, essentially just a wrapper around
 * calling a function.  Mostly useful in React components
 * where a prop is passed to handle some sort of event.
 *
 * @param {Function} handler The function to call, we do not
 *   bind the handler to anything, since it is possible, probable
 *   that it wants its own context.
 * @param  {...any} args The args to pass along. A good convention
 *   is to pass the context of where this is called from
 */
export default function dispatch(handler, ...args) {
  if (isFunction(handler)) {
    handler(...args);
  }
}

/**
 * A wrapper around submit buttons and form status
 */

// Dependencies
import classNames from 'classnames';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import CoreComponent from '../../extensions/CoreComponent';
import FormItemLayout from './FormItemLayout';

// Components
export default class FormSubmit extends CoreComponent {
  // Prop types
  static propTypes = {
    variant: PropTypes.oneOf(['', 'split', 'split-small']),
    errors: PropTypes.object,
    error: PropTypes.string,
    success: PropTypes.string,
    hasValidated: PropTypes.bool,
    isSubmitting: PropTypes.bool,
    buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    submittingButtonLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    successButtonLabel: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.element,
    ]),
    afterSubmit: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    buttonClassNames: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  // Default props
  static defaultProps = {
    error: '',
  };

  // Render
  render() {
    // Short cuts
    const {
      variant,
      errors,
      error,
      success,
      //hasValidated,
      isSubmitting,
      buttonLabel,
      submittingButtonLabel,
      successButtonLabel,
      afterSubmit,
      buttonClassNames,
    } = this.props;

    return (
      <FormItemLayout
        variant={variant}
        input={
          <Fragment>
            <button
              className={classNames(buttonClassNames, {
                success: isEmpty(errors) && success,
              })}
              type="submit"
              disabled={isSubmitting}
            >
              {isSubmitting ? (
                this.renderProp(submittingButtonLabel)
              ) : success ? (
                <Fragment>
                  {this.renderProp(successButtonLabel)}{' '}
                  <i className="icon icon-success-white" aria-hidden="true" />
                </Fragment>
              ) : (
                this.renderProp(buttonLabel)
              )}
            </button>

            {this.renderProp(afterSubmit)}
          </Fragment>
        }
        beforeInput={
          <div className="form-status">
            {success && (
              <p className="success" role="alert">
                <i className="icon icon-success-green" aria-hidden="true" />{' '}
                {success}
              </p>
            )}
            {error && (
              <p className="error" role="alert">
                <i className="icon icon-cancel-red" aria-hidden="true" />{' '}
                {error}
              </p>
            )}
          </div>
        }
      />
    );
  }
}

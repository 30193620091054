/**
 * Overall, it is best to use CSS classes, but for things
 * like charts, we need a specific value available is JS
 *
 * TODO: Export out SASS variables in a way that makes sense
 */

// Dependencies
import camelCase from 'lodash/camelCase';
import _filter from 'lodash/filter';

// Styles
const styles = {};

/**
 * A hacky way to take a block of SASS variables
 * and convert to vars
 *
 * @param {string} input - String of SASS variables.
 */
function parseSassVariable(input) {
  let lines = input.trim().split('\n');
  _filter(lines).forEach((l) => {
    let parts = l.split(':');
    let name = parts[0];
    let namePrefix = camelCase(name.split('-')[0]);
    let nameSuffix = camelCase(name.split('-').slice(1));
    let value = parts[1].trim().replace(/;$/, '');

    styles[namePrefix] = styles[namePrefix] || {};
    styles[namePrefix][nameSuffix] = value;
  });
}

// Colors
parseSassVariable(`
$color-gray-lightest: #f2f2f2;
$color-gray-lighter: #e0e0e0;
$color-gray-light: #bdbdbd;
$color-gray-medium: #828282;
$color-gray-dark: #6a6a6a;
$color-gray-darker: #4f4f4f;
$color-gray-darkest: #222;
$color-white: #fff;
$color-black: #000;
$color-red: #ef4836;
$color-blue: #0073e3;
$color-teal: #178670;
$color-brown: #733f00;
$color-green: #126710;
$color-purple: #5b297e;
$color-pink: #a62f8f;
$color-orange: #aa6c1e;
$color-yellow: #f2c94c;
$color-red-yellow: #f18941;
$color-orange-yellow: #feb24c;
$color-yellow-green: #82982e;
`);

// Add method to look for a named color
styles.isNamedColor = (c) => {
  return !!styles.color[camelCase(c)];
};

// Export
export default styles;

/**
 * Constants
 */
const constants = {
  AUTHORIZATION: {
    UNAUTHENTICATED: 'unauthenticated',
    AUTHENTICATED: 'authenticated',
    REQUIRES_PASSWORD_CHANGE: 'requires-password-change',
    REQUIRES_MFA: 'requires-mfa',
  },
};

export default constants;

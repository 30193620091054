/**
 * Component to check password validation.
 */

// Dependencies
// Styles
import './PasswordValidator.scss';

import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import zxcvbn from 'zxcvbn';

import CoreComponent from '../../extensions/CoreComponent';
import validators from '../../modules/validators';
import Progress from '../progress/Progress';

// Login page componet
export default class PasswordValidator extends CoreComponent {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {};

    // Allow override
    this.overrideStateWithProp(this.state);
  }

  // Prop types
  static propTypes = {
    password: PropTypes.string,
    headingClass: PropTypes.string,
    validators: PropTypes.array,
    initialState: PropTypes.object,
    onUpdate: PropTypes.func,
    scoreTranslator: PropTypes.object,
    strengthClasses: PropTypes.object,
  };

  // Default props
  static defaultProps = {
    headingClass: 'h3',
    password: '',
    validators: [
      {
        id: 'length',
        message: 'Between 10 and 64 characters.',
        validator: (p) => p && validators.isLength(p, { min: 10, max: 64 }),
      },
      {
        id: 'number',
        message: 'At least one number.',
        validator: (p) => p && validators.hasNumbers(p),
      },
      {
        id: 'capital',
        message: 'At least one capital letter.',
        validator: (p) => p && validators.hasCapitalLetters(p),
      },
      {
        id: 'special',
        message: 'At least one special character.',
        validator: (p) => p && validators.hasSpecialCharacters(p),
      },
    ],
    // See: https://www.npmjs.com/package/zxcvbn
    scoreTranslator: {
      '-1': '',
      0: 'Very risky',
      1: 'Very guessable',
      2: 'Somewhat guessable',
      3: 'Good',
      4: 'Great',
    },
    strengthClasses: {
      '-1': '',
      0: 'color-red-bg',
      1: 'color-red-yellow-bg',
      2: 'color-yellow-bg',
      3: 'color-yellow-green-bg',
      4: 'color-green-bg',
    },
  };

  // Validate
  validate() {
    let { password, validators } = this.props;

    return validators.reduce((memo, v) => {
      return memo === false ? false : v.validator(password);
    }, true);
  }

  // Render
  render() {
    // Shortcuts
    let {
      validators,
      password,
      scoreTranslator,
      headingClass,
      strengthClasses,
    } = this.props;

    // Determine strength of password
    let strength = zxcvbn(password || '');
    let score = password && strength ? strength.score : -1;

    return (
      <aside className="password-requirements">
        <h1 className={classNames(headingClass, 'sr-only')}>
          Password strength and requirements
        </h1>

        <Progress
          variant="thin"
          classNameBar={strengthClasses[score]}
          progress={(score + 1) / 5}
        />

        <p className="mb-half font-small">
          Password strength:{' '}
          {score !== undefined && score >= 0 ? (
            <span>
              <strong>{scoreTranslator[score]}</strong> (it would take{' '}
              <strong>
                ~
                {
                  strength.crack_times_display
                    .online_no_throttling_10_per_second
                }
              </strong>{' '}
              to guess)
            </span>
          ) : (
            ''
          )}
          <br />
          Your new password must include all of the following:
        </p>

        <ul className="checklist font-small">
          {validators.map((v) => (
            <li
              className={classNames({
                done: v.validator(password),
                invalid: password && !v.validator(password),
              })}
              key={v.id}
            >
              {v.message}
            </li>
          ))}
        </ul>
      </aside>
    );
  }
}

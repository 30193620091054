// Dependencies
import isNumber from 'lodash/isNumber';

/**
 * Try to convert an input to a number (integer or float)
 *
 * @param {*} input Input to convert and format to number
 * @param {boolean} [assumeInteger=true] If not a number, parse as integer; false to
 *   parse as float
 * @returns {number} A number (integer or float) or undefined
 *   if unable to convert.  Will throw error if can't use
 *   parseFloat or parseInt or there is not toString on input.
 */
export default function toNumber(input, assumeInteger = true) {
  let converted = input;

  if (input !== 0 && !input) {
    return undefined;
  }

  if (!isNumber(input) && input.toString) {
    try {
      converted = assumeInteger
        ? parseInt(
          input
            .toString()
            .replace(/[^0-9-.]/gi, '')
            .trim(),
          10,
        )
        : parseFloat(
          input
            .toString()
            .replace(/[^0-9-.]/gi, '')
            .trim(),
        );
    }
    catch (e) {
      throw new Error(
        `Unable to convert ${input} to a ${
          assumeInteger ? 'integer' : 'float'
        } number: ${e.toString()}`,
      );
    }
  }
  else if (!isNumber(input) && !input.toString) {
    throw new Error(
      `Unable to convert type ${typeof input} to a ${
        assumeInteger ? 'integer' : 'float'
      } number as it does not have a 'toString' method`,
    );
  }

  // NaN
  if (Number.isNaN(converted)) {
    throw new Error(
      `Unable to convert ${input} to a ${
        assumeInteger ? 'integer' : 'float'
      } number`,
    );
  }

  return converted;
}

/**
 * Non-locale based grouping for ballot reports.  For top-level filtering,
 * we need to support groups of values that are specific to a state.
 */

const ballotMetricGroups = [
  {
    id: 'vote-method',
    apiId: ['vote_method'],
    name: 'Vote method',
    description: 'The method used to cast a ballot.',
    values: [
      {
        id: 'mailing',
        apiId: ['mailing', 'mailed', 'mail'],
        name: 'Mailing',
        filterable: true,
      },
      {
        id: 'in-person',
        apiId: ['in-person', 'in person'],
        name: 'In person',
        filterable: true,
      },
    ],
    stateOverrides: {
      pa: {
        // Pennsylvania has a number of codes that need to be grouped together for more
        // meaningful metrics.
        // See: https://drive.google.com/file/d/1b8BQ3cObFGWKye9tQEsr_n3PZi0jO4sF/view
        values: [
          {
            id: 'mail-in',
            apiIdGroup: ['olmailv', 'olmailnv', 'mailin', 'pmi'],
            name: 'Mail-in',
            filterable: true,
          },
          {
            id: 'absentee',
            apiIdGroup: [
              'cvo',
              'olregv',
              'olregnv',
              'reg',
              'f',
              'm',
              'c',
              'cri',
              'per',
              'civ',
              'mri',
              'v',
              'bv',
            ],
            name: 'Absentee',
            filterable: true,
          },
          {
            id: 'counter',
            apiIdGroup: ['reg', 'c', 'civ', 'v', 'alt'],
            name: 'At counter',
            filterable: true,
          },
          {
            id: 'not-counter',
            apiIdGroup: [
              'olmailv',
              'olmailnv',
              'cvo',
              'mailin',
              'olregv',
              'olregnv',
              'f',
              'm',
              'cri',
              'per',
              'pmi',
              'mri',
              'bv',
            ],
            name: 'Not at counter',
            filterable: true,
          },
          {
            id: 'pa-olmailv',
            apiId: 'olmailv',
            name: 'Online Mail-in App Verified',
          },
          {
            id: 'pa-olmailnv',
            apiId: 'olmailnv',
            name: 'Online Mail-in App Not Verified',
          },
          { id: 'pa-cvo', apiId: 'cvo', name: 'Civilian Overseas' },
          { id: 'pa-mailin', apiId: 'mailin', name: 'Mail-in Paper' },
          {
            id: 'pa-olregv',
            apiId: 'olregv',
            name: 'Online Absentee App Verified',
          },
          {
            id: 'pa-olregnv',
            apiId: 'olregnv',
            name: 'Online Absentee App Not Verified',
          },
          { id: 'pa-reg', apiId: 'reg', name: 'Regular Absentee Paper' },
          { id: 'pa-f', apiId: 'f', name: 'Federal Absentee (Unregistered)' },
          { id: 'pa-m', apiId: 'm', name: 'Military Absentee' },
          { id: 'pa-c', apiId: 'c', name: 'Emergency Absentee' },
          {
            id: 'pa-cri',
            apiId: 'cri',
            name: 'Civilian Remote/Isolated (UOCAVA)',
          },
          { id: 'pa-per', apiId: 'per', name: 'Permanent Absentee' },
          { id: 'pa-pmi', apiId: 'pmi', name: 'Permanent Mail-in' },
          { id: 'pa-alt', apiId: 'alt', name: 'Alternative' },
          { id: 'pa-civ', apiId: 'civ', name: 'Civilian Absentee' },
          {
            id: 'pa-mri',
            apiId: 'mri',
            name: 'Military Remote/Isolated (UOCAVA)',
          },
          { id: 'pa-v', apiId: 'v', name: 'Veteran Absentee' },
          { id: 'pa-bv', apiId: 'bv', name: 'Bedridden Veteran Absentee' },
        ],
      },
      // We need to group some in-person values together, so need to make
      // exception for Colorado.
      co: {
        values: [
          {
            id: 'in-person',
            apiIdGroup: ['in person bmd', 'in person paper', 'in person dre'],
            name: 'In person',
            filterable: true,
          },
          {
            id: 'mail-in',
            apiId: 'mail',
            name: 'Mail-in',
            filterable: true,
          },
          {
            id: 'co-in-person-paper',
            apiId: 'in person paper',
            name: 'In person paper',
          },
          {
            id: 'co-in-person-bmd',
            apiId: 'in person bmd',
            name: 'In person BMD',
          },
          {
            id: 'co-in-person-dre',
            apiId: 'in person dre',
            name: 'In person DRE',
          },
        ],
      },
      // Georgia changed its vote method codes as of the 2024-03-12_primary,
      // so we need to group old and new codes
      ga: {
        values: [
          {
            id: 'mailing',
            apiIdGroup: ['mailed', 'absentee by mail'],
            name: 'Mailing',
            filterable: true,
          },
          {
            id: 'in-person',
            apiIdGroup: ['in person', 'early in-person'],
            name: 'In person',
            filterable: true,
          },
        ],
      },
      va: {
        values: [
          {
            id: 'military',
            apiIdGroup: ['military'],
            name: 'Military',
            filterable: true,
          },
          {
            id: 'overseas',
            apiIdGroup: ['overseas'],
            name: 'Overseas',
            filterable: true,
          },
          {
            id: 'temporary',
            apiIdGroup: ['temporary(federal-only ballot)'],
            name: 'Temporary (Federal Only)',
            filterable: true,
          },
        ],
      },
      wi: {
        values: [
          {
            id: 'in-person',
            apiIdGroup: ['voted in person'],
            name: 'In Person',
            filterable: true,
          },
        ],
      },
    },
  },
  {
    id: 'party',
    apiId: ['party'],
    name: 'Party',
    description: 'The registered party of the voter.',
    values: [
      {
        id: 'dem',
        apiId: ['democrat', 'dem'],
        name: 'Democrat',
        filterable: true,
      },
      {
        id: 'rep',
        apiId: ['republican', 'rep'],
        name: 'Republican',
        filterable: true,
      },
    ],
  },
  {
    id: 'uocava',
    apiId: ['uocava'],
    name: 'UOCAVA',
    description:
      'Groups of voters as defined by the Uniformed And Overseas Citizens Absentee Voting Act (UOCAVA).',
    values: [
      {
        id: 'overseas-civilian',
        apiId: ['overseas civilian'],
        name: 'Overseas civilian',
        filterable: true,
      },
      {
        id: 'military',
        apiId: ['military'],
        name: 'Military members',
        filterable: true,
      },
      {
        id: 'uocava',
        apiId: ['y'],
        name: 'UOCAVA',
        filterable: true,
      },
      {
        id: 'non-uocava',
        apiId: ['non-uocava', 'n'],
        name: 'Non-UOCAVA',
        filterable: true,
      },
    ],
  },
  {
    id: 'permanent-absentee',
    apiId: ['permanent_absentee'],
    name: 'Permanent absentee',
    description: 'Voters who are designated as permanently absentee.',
    values: [
      {
        id: 'yes',
        apiId: ['y'],
        name: 'Yes',
        filterable: true,
      },
      {
        id: 'no',
        apiId: ['n'],
        name: 'No',
        filterable: true,
      },
    ],
  },
  {
    id: 'permanent-accessible',
    apiId: ['permanent_accessible'],
    name: 'Permanent accessible',
    description: 'Voters who are designated as "permanent accessible".',
    values: [
      {
        id: 'yes',
        apiId: ['y'],
        name: 'Yes',
        filterable: true,
      },
      {
        id: 'no',
        apiId: ['n'],
        name: 'No',
        filterable: true,
      },
    ],
  },
  {
    id: 'race',
    apiId: ['race'],
    name: 'Race',
    description: 'The race of the voter.',
    values: [
      {
        id: 'white',
        apiId: ['white'],
        name: 'White',
        filterable: true,
      },
      {
        id: 'black',
        apiId: ['black or african american'],
        name: 'Black or African American',
        filterable: true,
      },
      {
        id: 'undesignated',
        apiId: ['undesignated'],
        name: 'Undesignated',
        filterable: true,
      },
      {
        id: 'other',
        apiId: ['other'],
        name: 'Other',
        filterable: true,
      },
      {
        id: 'asian',
        apiId: ['asian'],
        name: 'Asian',
        filterable: true,
      },
      {
        id: 'two_races',
        apiId: ['two or more races'],
        name: 'Two or more races',
        filterable: true,
      },
      {
        id: 'indian_american',
        apiId: ['indian american or alaska native'],
        name: 'Indian American or Alaska Native',
        filterable: true,
      },
      {
        id: 'hawaiian',
        apiId: ['native hawaiian or pacific islander'],
        name: 'Native Hawaiian or Pacific Islander',
        filterable: true,
      },
    ],
  },
];

export default ballotMetricGroups;

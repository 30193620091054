/**
 * Alert component
 */

// Dependencies
import classNames from 'classnames';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import PropTypes from 'prop-types';
import React from 'react';

import CoreComponent from '../../extensions/CoreComponent';
import Progress from '../progress/Progress';
import Alert from './Alert';

// Components
export default class AlertBox extends CoreComponent {
  // Prop types
  static propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    description: PropTypes.any,
    actionsDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    actions: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    variant: PropTypes.oneOf(['', 'error', 'success', 'loading']),
    size: PropTypes.oneOf(['', 'small']),
    closable: PropTypes.bool,
    progress: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.bool,
      PropTypes.string,
    ]),
  };

  // Prop defaults
  static defaultProps = {
    variant: '',
    closable: true,
    progress: false,
  };

  // Render
  render() {
    // Shortcuts
    let {
      className,
      title,
      description,
      actionsDescription,
      actions,
      variant,
      size,
      closable,
      progress,
    } = this.props;

    return (
      <Alert
        variant="box"
        className={classNames(variant, className, { small: size })}
        closable={closable}
      >
        <section className="row">
          <div className="col-auto">
            {variant !== 'loading' && (
              <i
                className={classNames(
                  'pt-half material-icons-outlined icon-font',
                  {
                    [`color-${variant}`]: variant,
                  },
                )}
                aria-hidden="true"
              >
                error_outline
              </i>
            )}
          </div>

          <div className="col-auto col-md-6">
            {isString(title) ? (
              <h1 className="h2 m-0 p-0">{title}</h1>
            ) : (
              this.renderProp(title)
            )}

            {(progress || progress === 0 || progress === 'indeterminate') && (
              <Progress
                className="mt-half"
                variant="thin"
                progress={isNumber(progress) ? progress : 0}
                indeterminate={progress === 'indeterminate'}
              ></Progress>
            )}

            <div style={{ minHeight: '7em' }}>
              {isString(description) ? (
                <p>{description}</p>
              ) : (
                this.renderProp(description)
              )}
            </div>

            {isString(actionsDescription) ? (
              <p className="mb-0">
                <strong>{actionsDescription}</strong>
              </p>
            ) : (
              this.renderProp(actionsDescription)
            )}

            {this.renderProp(actions)}
          </div>

          <div className="display-none display-md-block col-md-4 text-left">
            <i
              className={`icon-image ${
                variant === 'loading'
                  ? 'icon-loading-logo-md'
                  : 'icon-voteshield'
              } icon-huge`}
              aria-hidden="true"
            />
          </div>
        </section>
      </Alert>
    );
  }
}

/**
 * Date config
 */

const dates = {
  formatApi: 'YYYY-MM-DD',
  formatFullShort: 'YYYY-MM-DD',
  formatFullMedium: 'MMM D, YYYY',
  formatFullLong: 'MMMM DD, YYYY',
  formatMonthMedium: 'MMM `YY',
  formatDay: 'MMM D',
};

export default dates;

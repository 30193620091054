/**
 * Landing content
 */

// Styles
import './LandingContent.scss';

// Dependencies
import React from 'react';

import { demoEmail } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';
import EmailLink from '../links/EmailLink';
import BalanceText from '../text/BalanceText';
// Sections
import AboutSections from './AboutSections';
import IntroSection from './IntroSection';
import MediaSection from './MediaSection';
import PublicationSection from './PublicationSection';
import TestimonialSection from './TestimonialSection';

// Components
export default class LandingContent extends CoreComponent {
  // Render
  render() {
    // Content
    return (
      <div className="landing-content">
        <div className="overflow-hidden">
          <IntroSection />
        </div>

        <AboutSections />

        <div className="container-lg">
          <TestimonialSection />
        </div>

        <div className="callout-bg">
          <div className="container-lg">
            <div className="container-copy-large center-horizontal text-center">
              <h1>
                <BalanceText>Contact us for more information</BalanceText>
              </h1>

              <p className="heading-copy">
                <BalanceText>
                  For demo requests, media requests, or just to learn more,
                  please don’t hesitate to reach out to our team. Please note
                  that VoteShield platform access is only available to state and
                  local election officials.
                </BalanceText>
              </p>

              <EmailLink
                variant="button"
                className="outline"
                email={demoEmail}
                subject="VoteShield Inquiry"
                gaEvent={{
                  category: 'email link',
                  action: 'contact',
                  label: 'landing - callout',
                }}
              >
                Contact Us
              </EmailLink>
            </div>
          </div>
        </div>

        <div className="container-lg">
          <MediaSection />

          <PublicationSection />
        </div>
      </div>
    );
  }
}

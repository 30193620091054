/**
 * Configuration per state.
 *
 * Note that there are occassions when states data change so
 * drastically that we have to create a new configuration
 * for them.  In general, this means that we just need
 * to update their 'apiId', but we may still want to display
 * the old data in a separate configuration.
 *
 * For instance, AZ and NJ both have had that happen,
 * so, we have new apiId's for them, but want to
 * be able to see the old data, specifically on the dev
 * environment.  This also means we may need to support
 * different counties.
 * TODO: At one point we should remove the support for
 * old state configurations
 */

// Dependencies and data
import iaCongressionalDistricts from '../data/congressional-districts/ia';
import njCongressionalDistricts from '../data/congressional-districts/nj';
import nvCongressionalDistricts from '../data/congressional-districts/nv';
import ohCongressionalDistricts from '../data/congressional-districts/oh';
import vaCongressionalDistricts from '../data/congressional-districts/va';
import azCounties from '../data/counties/az';
import azCountiesPre2020 from '../data/counties/az-pre-2020';
import caCounties from '../data/counties/ca';
import coCounties from '../data/counties/co';
import flCounties from '../data/counties/fl';
import gaCounties from '../data/counties/ga';
import iaCounties from '../data/counties/ia';
import ksCounties from '../data/counties/ks';
import miCounties from '../data/counties/mi';
import mnCounties from '../data/counties/mn';
import moCounties from '../data/counties/mo';
import ncCounties from '../data/counties/nc';
import nhCounties from '../data/counties/nh';
import njCounties from '../data/counties/nj';
import njCountiesPre2020 from '../data/counties/nj-pre-2020';
import nvCounties from '../data/counties/nv';
import nyCounties from '../data/counties/ny';
import ohCounties from '../data/counties/oh';
import okCounties from '../data/counties/ok';
import paCounties from '../data/counties/pa';
import testCountyNames from '../data/counties/testNames';
import txCounties from '../data/counties/tx';
import vaCounties from '../data/counties/va';
import vtCounties from '../data/counties/vt';
import waCounties from '../data/counties/wa';
import wvCounties from '../data/counties/wv';
import miJurisdictions from '../data/jurisdictions/mi';
import wiJurisdictions from '../data/jurisdictions/wi';
import environment from '../modules/browser/environment';

// Config
const stateConfiguration = {
  az: {
    // This gets used in the frontend to identify the state
    id: 'az',
    // This is needed in titles or similar copy
    shortName: 'AZ',
    // The full name used in copy
    name: 'Arizona',
    // The id that the backend/Inspector users
    apiId: 'arizona2',
    // Whether this state can have voter file uploads in the interface.
    uploadable: true,
    // The primary locale type associated with the state.  This should be
    // a locale type as defined in src/config/locale-types.js
    primaryLocaleType: 'county',
    // What locale types are available.  These should be
    // locale types as defined in src/config/locale-types.js
    localeTypesAvailable: ['county'],
    // Actual counties
    counties: azCounties,
    // What demographic groups are available.  This is used in
    // the Timeline and Locales.
    demographicGroupsAvailable: ['age', 'party'],
    // List of change types that are not available in this state.
    // See src/config/change-types.js
    changeTypesUnavailable: [],
    // What main views are available for this state.
    // NOTE: This made sense when states had different analysis
    // available, but now this is basically only used to show
    // or hide analysis; therefore make sure analysis is here.
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],

    // Use this to put the state into maintenance mode and display
    // a specific message.
    // maintenance: 'STATE Ballot|Voter data is going through some maintenance.  Expect data issues and possible inaccuracies.  Work should be complete by MMM Dth, YYYY.'
    // maintenance: 'STATE Ballot|Voter data is going through significant maintenance.  Data will be offline and work should be complete by MMM Dth, YYYY.

    // Depending on the scope of the message, use this to
    // show or hide the message on specific pages.
    // maintenanceScope: 'all' | 'voters' | 'ballots'

    // If no data should be displayed during maintenance, use this.
    // maintenanceNoDisplay: true
  },
  ca: {
    id: 'ca',
    shortName: 'CA',
    name: 'California',
    apiId: 'california',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: caCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter', 'activated', 'deactivated'],
  },
  co: {
    id: 'co',
    shortName: 'CO',
    name: 'Colorado',
    apiId: 'colorado',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: coCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter'],
    ballots: {
      enabled: true,
      isVoteByMail: true,
      metricsUnavailable: [
        'app-return-to-ballot-sent',
        'unprocessed-app',
        'app-sent-to-return',
        'app-sent',
        'app-returned',
      ],
      localeTypeSupported: 'county',
      metricGroupFilter: 'vote-method',
      metricGroupFilterDefault: 'mailing',
    },
  },
  fl: {
    id: 'fl',
    shortName: 'FL',
    name: 'Florida',
    apiId: 'florida',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: flCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter'],
  },
  ga: {
    id: 'ga',
    shortName: 'GA',
    name: 'Georgia',
    apiId: 'georgia',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: gaCounties,
    demographicGroupsAvailable: ['age'],
    viewsAvailable: ['timeline', 'analysis', 'anomalies'],
    changeTypesUnavailable: ['party', 'early_voter'],
    ballots: {
      enabled: true,
      metricsUnavailable: ['app-sent', 'app-sent-to-return'],
      metricGroupFilter: 'vote-method',
      metricGroupFilterDefault: 'mailing',
      localeTypeSupported: 'county',
    },
  },
  ia: {
    id: 'ia',
    shortName: 'IA',
    name: 'Iowa',
    apiId: 'iowa',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county', 'congressional_district'],
    counties: iaCounties,
    congressionalDistricts: iaCongressionalDistricts,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter'],
    ballots: {
      enabled: true,
      metricsUnavailable: ['app-sent', 'app-sent-to-return'],
      metricGroupFilter: 'vote-method',
      metricGroupFilterDefault: 'mailing',
      localeTypeSupported: 'county',
    },
  },
  ks: {
    id: 'ks',
    shortName: 'KS',
    name: 'Kansas',
    apiId: 'kansas',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: ksCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter'],
  },
  mi: {
    id: 'mi',
    shortName: 'MI',
    name: 'Michigan',
    apiId: 'michigan',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county', 'jurisdiction'],
    counties: miCounties,
    jurisdictions: miJurisdictions,
    demographicGroupsAvailable: ['age'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['party'],
    ballots: {
      enabled: true,
      localeTypeSupported: 'jurisdiction',
      localeTotalHigh: true,
      metricGroupFilter: 'uocava',
      metricGroupFilterDefault: 'non-uocava',
    },
  },
  mn: {
    id: 'mn',
    shortName: 'MN',
    name: 'Minnesota',
    apiId: 'minnesota',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: mnCounties,
    demographicGroupsAvailable: ['age'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: [
      'party',
      'activated',
      'deactivated',
      'early_voter',
    ],
  },
  mo: {
    id: 'mo',
    shortName: 'MO',
    name: 'Missouri',
    apiId: 'missouri',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: moCounties,
    demographicGroupsAvailable: ['age'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['party', 'early_voter'],
  },
  nv: {
    id: 'nv',
    shortName: 'NV',
    name: 'Nevada',
    apiId: 'nevada',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county', 'congressional_district'],
    congressionalDistricts: nvCongressionalDistricts,
    counties: nvCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter'],
  },
  nh: {
    id: 'nh',
    shortName: 'NH',
    name: 'New Hampshire',
    apiId: 'new_hampshire',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: nhCounties,
    demographicGroupsAvailable: ['party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: [
      'activated',
      'deactivated',
      'birthday',
      'early_voter',
      'reregistration',
    ],
  },
  nj: {
    id: 'nj',
    shortName: 'NJ',
    name: 'New Jersey',
    apiId: 'new_jersey2',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county', 'congressional_district'],
    counties: njCounties,
    congressionalDistricts: njCongressionalDistricts,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter'],
  },
  ny: {
    id: 'ny',
    shortName: 'NY',
    name: 'New York',
    apiId: 'new_york',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: nyCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'analysis', 'anomalies'],
    changeTypesUnavailable: ['early_voter'],
  },
  nc: {
    id: 'nc',
    shortName: 'NC',
    name: 'North Carolina',
    apiId: 'north_carolina',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: ncCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter'],
    ballots: {
      enabled: true,
      metricsUnavailable: ['app-sent', 'app-sent-to-return'],
      metricGroupFilter: 'vote-method',
      metricGroupFilterDefault: 'mail',
      localeTypeSupported: 'county',
    },
  },
  oh: {
    id: 'oh',
    shortName: 'OH',
    name: 'Ohio',
    apiId: 'ohio',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county', 'congressional_district'],
    congressionalDistricts: ohCongressionalDistricts,
    counties: ohCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'analysis', 'anomalies'],
    changeTypesUnavailable: ['early_voter'],
  },
  ok: {
    id: 'ok',
    shortName: 'OK',
    name: 'Oklahoma',
    apiId: 'oklahoma',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: okCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'analysis', 'anomalies'],
    changeTypesUnavailable: ['early_voter'],
  },
  pa: {
    id: 'pa',
    shortName: 'PA',
    name: 'Pennsylvania',
    apiId: 'pennsylvania',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: paCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'analysis', 'anomalies'],
    changeTypesUnavailable: ['early_voter'],
    ballots: {
      enabled: true,
      metricGroupFilter: 'vote-method',
      metricGroupFilterDefault: 'mail-in',
      localeTypeSupported: 'county',
    },
  },
  tx: {
    id: 'tx',
    shortName: 'TX',
    name: 'Texas',
    apiId: 'texas',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: txCounties,
    demographicGroupsAvailable: ['age'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['party', 'early_voter'],
  },
  va: {
    id: 'va',
    shortName: 'VA',
    name: 'Virginia',
    apiId: 'virginia',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county', 'congressional_district'],
    congressionalDistricts: vaCongressionalDistricts,
    counties: vaCounties,
    demographicGroupsAvailable: ['age'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['party', 'early_voter'],
    ballots: {
      enabled: true,
      metricsUnavailable: [
        'app-return-to-ballot-sent',
        'app-sent-to-return',
        'app-sent',
      ],
      metricGroupFilter: 'vote-method',
      metricGroupFilterDefault: 'mailing',
      localeTypeSupported: 'county',
    },
  },
  vt: {
    id: 'vt',
    shortName: 'VT',
    name: 'Vermont',
    apiId: 'vermont',
    viewable: true,
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: vtCounties,
    demographicGroupsAvailable: ['age'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['party', 'early_voter', 'reregistration'],
  },
  wa: {
    id: 'wa',
    shortName: 'WA',
    name: 'Washington',
    apiId: 'washington',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: waCounties,
    demographicGroupsAvailable: ['age'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter', 'party'],
  },
  wv: {
    id: 'wv',
    shortName: 'WV',
    name: 'West Virginia',
    apiId: 'west_virginia',
    viewable: true,
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: wvCounties,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter'],
  },
  wi: {
    id: 'wi',
    shortName: 'WI',
    name: 'Wisconsin',
    apiId: 'wisconsin',
    uploadable: true,
    primaryLocaleType: 'jurisdiction',
    localeTypesAvailable: ['jurisdiction'],
    //counties: wiCounties,
    // Note: These should be called municipalities
    jurisdictions: wiJurisdictions,
    demographicGroupsAvailable: null,
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: [
      'party',
      'birthday',
      'early_voter',
      'activated',
      'deactivated',
      'reregistration',
    ],
    ballots: {
      enabled: true,
      localeTotalHigh: true,
      metricsUnavailable: ['app-sent-to-return'],
      metricGroupFilter: 'vote-method',
      metricGroupFilterDefault: 'mailing',
      localeTypeSupported: 'jurisdiction',
    },
  },
};

// Add test state
// This must come after other states - state access search will only find first instance of matching apiId
// This prevents test state from showing up for any user without access to all states
stateConfiguration.ts = {
  // Based on Arizona
  ...stateConfiguration.az,

  // Custom naming
  id: 'ts',
  shortName: 'TS',
  name: 'Test State',

  // Apply custom county names
  counties: stateConfiguration.az.counties.map((c, index) => ({
    ...c,
    name: testCountyNames[index],
  })),
};

// Add old data for specific environments
if (environment().match(/(local|development)/)) {
  stateConfiguration['az-pre-2020'] = {
    id: 'az-pre-2020',
    shortName: 'AZ (prev)',
    name: 'Arizona (pre 2020)',
    apiId: 'arizona',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: azCountiesPre2020,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: [],
  };

  stateConfiguration['nj-pre-2020'] = {
    id: 'nj-pre-2020',
    shortName: 'NJ (prev)',
    name: 'New Jersey (pre 2020)',
    apiId: 'new_jersey',
    uploadable: true,
    primaryLocaleType: 'county',
    localeTypesAvailable: ['county'],
    counties: njCountiesPre2020,
    demographicGroupsAvailable: ['age', 'party'],
    viewsAvailable: ['timeline', 'anomalies', 'analysis'],
    changeTypesUnavailable: ['early_voter'],
  };
}

// Export
export default stateConfiguration;

// Dependencies
import React from 'react';

import FilterContextDefaults from '../../context/FilterContextDefaults';
import CoreComponent from '../../extensions/CoreComponent';
import AuthorizedAuthenticatedAny from '../../providers/auth/AuthorizedAuthenticatedAny';
import AuthorizedAuthenticatedNeedsLegalAgreement from '../../providers/auth/AuthorizedAuthenticatedNeedsLegalAgreement';
import LegalAgreementBanner from '../banners/LegalAgreementBanner';
import NewFeaturesBanner from '../banners/NewFeaturesBanner';
import PageFooter from '../footer/PageFooter';
import EnvironmentHeader from '../navigation/EnvironmentHeader';
import Header from '../navigation/MainHeader';

// Components
export default class DefaultPageLayout extends CoreComponent {
  render() {
    return (
      <FilterContextDefaults>
        <div>
          <header>
            <EnvironmentHeader />

            <AuthorizedAuthenticatedNeedsLegalAgreement>
              <LegalAgreementBanner />
            </AuthorizedAuthenticatedNeedsLegalAgreement>

            <AuthorizedAuthenticatedAny>
              <NewFeaturesBanner />
            </AuthorizedAuthenticatedAny>

            <Header />
          </header>

          <main className="minh-85vh">{this.props.children}</main>

          <footer>
            <PageFooter />
          </footer>
        </div>
      </FilterContextDefaults>
    );
  }
}

/**
 * Hopefully React Router will get this into v6 soon, but for now,
 * it is here.
 *
 * See: https://github.com/remix-run/react-router/pull/7586
 */

import PropTypes from 'prop-types';
import React from 'react';
import { Router } from 'react-router-dom';

/**
 * Custom HistoryRouter component.
 *
 * @param {object} props - Props for component
 * @param {*} props.children - Children to render
 * @param {*} props.history - History object
 * @param {*} props.basename - Basename for router
 * @returns {React.element} - React element
 */
export default function HistoryRouter({ children, history, basename }) {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location,
  });

  // This is not how the PR has it, but this what worked for this
  // code base.
  React.useLayoutEffect(
    () =>
      history.listen(({ location, action }) => {
        setState({ location, action });
      }),
    [history],
  );

  return (
    <Router
      children={children} // eslint-disable-line
      basename={basename}
      navigator={history}
      navigationType={state.action}
      location={state.location}
    />
  );
}

HistoryRouter.propTypes = {
  children: PropTypes.node.isRequired,
  history: PropTypes.object.isRequired,
  basename: PropTypes.string,
};

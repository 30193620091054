/**
 * Wrapper around FilterContext.Provider to provider
 * set functions
 */

// Dependencies
import clone from 'lodash/clone';
import cloneDeep from 'lodash/cloneDeep';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import isBoolean from 'lodash/isBoolean';
import isEqual from 'lodash/isEqual';
import _map from 'lodash/map';
import _max from 'lodash/max';
import _min from 'lodash/min';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import uniq from 'lodash/uniq';
import memoize from 'memoizee';
import moment from 'moment';
import React from 'react';

// TODO: Split config up
import config, { idSeparator } from '../config';
import CoreComponent from '../extensions/CoreComponent';
import abortController from '../modules/api/abort-controller';
import dataTransformValidPostDate from '../modules/api/data-transform-post-date';
import handleFormattedResponseErrors from '../modules/api/handle-formatted-response-errors';
import findClosestFloor from '../modules/arrays/find-closest-floor';
import booleanMapToArray from '../modules/convert/boolean-map-to-array';
import toNumber from '../modules/convert/to-number';
import outputDate from '../modules/output/date';
import fromArrayToQueryString from '../modules/query-string/from-array';
import fromBooleanMapToQueryString from '../modules/query-string/from-boolean-map';
import QueryStringManager from '../modules/query-string/QueryStringManager';
import toArrayOfFromQueryString from '../modules/query-string/to-array-of';
import toBooleanMapFromQueryString from '../modules/query-string/to-boolean-map';
import captureException from '../modules/sentry/capture-exception';
import withGlobalRouteMatch from '../providers/routes/with-global-route-match';
import FilterContext from './FilterContext';

// Header component
class FilterContextDefaults extends CoreComponent {
  constructor(props) {
    super(props);

    // Make memoized functions for performance
    // Note: Needed for some state validation
    this.memoizedComputedStatesValues = memoize(this.computedStatesValues);
    this.memoizedComputedLocalesValues = memoize(this.computedLocalesValues);
    this.memoizedComputedChangesValues = memoize(this.computedChangesValues);
    this.memoizedComputedDemographicsValues = memoize(
      this.computedDemographicsValues,
    );
    this.memoizedComputedValidDatesValues = memoize(
      this.computedValidDatesValues,
    );

    // Check user profile for default values
    let userPreferredState;
    let userpreferredStateAccessDetails =
      config.user.preferredStateAccessDetails() || undefined;
    let userPreferredLocales;
    if (userpreferredStateAccessDetails) {
      userPreferredState = userpreferredStateAccessDetails.id;

      // Check if there is a locale preference.  Currently only supports
      // the first one.
      if (config.user.profile.locales && config.user.profile.locales.length) {
        let locale = config.user.profile.locales[0];
        let localeId = `${locale.locale_type}${config.idSeparator}${locale.locale}`;
        if (
          localeId &&
          _find(userpreferredStateAccessDetails.allLocales, {
            apiId: locale.locale,
            localeTypeId: locale.locale_type,
          })
        ) {
          userPreferredLocales = localeId;
        }
      }
    }

    // Default state
    const defaultState = {
      stateOptions: config.user.stateAccessOptions(),
      states: new Map(
        userPreferredState ? [[userPreferredState, true]] : undefined,
      ),
      statesEnabled: config.user.stateAccessOptions()?.length > 1,
      locales: userPreferredLocales ? [userPreferredLocales] : [],
      localesEnabled: true,
      localeTypes: [],
      localeTypeOptions: [],
      localeTypesEnabled: true,
      localeTypesMultiple: true,
      changesEnabled: true,
      changes: new Map([['removal', true]]),
      changesMultiple: false,
      changesEmptyLabel: 'Choose change type',
      demographicsEnabled: true,
      demographicGroup: undefined,
      demographicFilters: new Map(),
      validDates: [],
      datesEnabled: true,
      dates: [],
      setStates: this.setStates,
      setStateOptions: this.setStateOptions,
      setLocalesEnabled: this.setLocalesEnabled,
      setLocales: this.setLocales,
      setLocaleTypes: this.setLocaleTypes,
      setLocaleTypesEnabled: this.setLocaleTypesEnabled,
      setLocaleTypesMultiplicity: this.setLocaleTypesMultiplicity,
      setLocaleTypeOnly: this.setLocaleTypeOnly,
      setChanges: this.setChanges,
      setChangesEnabled: this.setChangesEnabled,
      setChangesMultiplicity: this.setChangesMultiplicity,
      setChangesEmptyLabel: this.setChangesEmptyLabel,
      setEnabledChangeOptions: this.setEnabledChangeOptions,
      setDisabledChangeOptions: this.setDisabledChangeOptions,
      setDemographics: this.setDemographics,
      setDemographicsEnabled: this.setDemographicsEnabled,
      setDemographicGroup: this.setDemographicGroup,
      setDemographicFilters: this.setDemographicFilters,
      setDatesEnabled: this.setDatesEnabled,
      setValidDates: this.setValidDates,
      setDates: this.setDates,
      setPrimaryLocale: this.setPrimaryLocale,
    };

    // Get any state values from the route (i.e. state)
    let stateFromRoute = this.getStateFromRoute(this.props) || {};

    // Make query string handler
    // Note: The GlobalFilter handles setting the PageTitle
    this.qs = new QueryStringManager({
      id: 'gf',
      include: [
        'locales',
        'localeTypes',
        'changes',
        'demographicGroup',
        'dates',
        'changesMultiple',
      ],
      canWrite: /anomalies|timeline|locales|ballots/i,
      //defaults: defaultState,
      serialization: {
        locales: [fromArrayToQueryString(true), toArrayOfFromQueryString()],
        localeTypes: [fromArrayToQueryString(true), toArrayOfFromQueryString()],
        changes: [fromBooleanMapToQueryString, toBooleanMapFromQueryString],
        dates: [fromArrayToQueryString(), toArrayOfFromQueryString(toNumber)],
      },
    });

    // Read query values
    let qsValues = this.qs.read() || {};

    // Old URLs had "locales" but just in the direct apiId format,
    // and not with the combined locale type.  Let's try to match it up
    // if that is the case.
    //
    // Examples:
    //   - localhost:3000/states/ia/timeline?gf-locales=Allamakee%2CBoone&gf-changes=removal&gf-demographicGroup=none&gf-dates=1624838400000%2C1633305600000&tip-analysisOpen=false&ti-normalization=voters_affected
    //   - localhost:3000/states/ks/timeline?gf-locales=null&gf-changes=removal&gf-demographicGroup=none&gf-dates=1620172800000%2C1630540800000&tip-analysisOpen=false&ti-normalization=voters_affected
    if (!qsValues.localeType && qsValues.locales && qsValues.locales.length) {
      let states = { ...defaultState, ...stateFromRoute }?.states;
      let statesArray = booleanMapToArray(states);
      let stateDetails = config.states[statesArray?.[0]];

      if (stateDetails) {
        qsValues.locales = _filter(
          _map(qsValues.locales, (l) => {
            let deprecatedLocale = !~l.indexOf(config.idSeparator);
            if (!deprecatedLocale) {
              return l;
            }

            // Try primary locale type first, then try just the raw locale id
            let foundLocale = _find(stateDetails.allLocales, {
              id: `${stateDetails.primaryLocaleType}${idSeparator}${l}`,
            });
            foundLocale = !foundLocale
              ? _find(stateDetails.allLocales, { apiId: l })
              : foundLocale;
            return foundLocale?.id;
          }),
        );

        qsValues.locales =
          qsValues.locales.length === 0 ? undefined : qsValues.locales;
      }
    }

    // Setting the default locale is a pain.  We need to know if
    // the value has been set from the URL initially.
    this._localesTrackingInitialQueryString = !!qsValues.locales;

    // Create initial state
    let state = {
      ...defaultState,
      ...stateFromRoute,
      ...qsValues,
    };
    this.state = this._validateState(state, state);

    // Track path name
    this._pathname = window.location.pathname;
  }

  // Props
  static propTypes = {};
  static defaultProps = {};

  // On mount
  componentDidMount(...args) {
    this.componentDidChange(true, ...args);
  }

  // Unmount
  componentWillUnmount() {
    this.abortFetchValidDates(false);
    this.qs.unlisten();
  }

  // Did update
  componentDidUpdate(...args) {
    this.componentDidChange(false, ...args);
  }

  // Handle state change
  componentDidChange(didMount, ...args) {
    const pathNameChange = window.location.pathname !== this._pathname;
    this._pathname = window.location.pathname;

    // Check path against state
    let stateFromRoute = this.getStateFromRoute(this.props) || {};
    if (
      stateFromRoute?.states?.size > 0 &&
      !isEqual(this.state.states, stateFromRoute.states)
    ) {
      this.setStates(stateFromRoute.states);
      return;
    }

    // Since this context is global, it is built
    // around things like logging in where the profile gets set.
    // This means that when someone logs in, they will get profile
    // values, but we have already instantiated our state, and
    // then something like the Timeline loads which uses the context
    // and should have those defaults set.
    //
    // Update preferred state
    if (!this.state.states || !this.state.states.size) {
      if (config.user.preferredStateAccessDetails()) {
        this.setStates(
          new Map([[config.user.preferredStateAccessDetails().id, true]]),
        );
      }
    }
    // The same thing with locales, except that an empty locales
    // is valid, so we only do this once.
    if (
      !this._localesTrackingInitialQueryString &&
      !this._localesTrackingDefaulted &&
      !this._localesTrackingSetOnce &&
      (!this.state.locales || !this.state.locales.length)
    ) {
      if (
        config.user &&
        config.user.profile &&
        config.user.profile.locales &&
        config.user.profile.locales.length
      ) {
        let locale = config.user.profile.locales[0];
        this.setLocales([
          `${locale.localeTypeId}${config.idSeparator}${locale.apiId}`,
        ]);
        this._localesTrackingDefaulted = true;
      }
    }

    // Check states enabled
    if (
      !this.state.statesEnabled &&
      config.user.stateAccessOptions()?.length > 1
    ) {
      this.setStatesEnabled(true);
    }

    // Check states options
    if (
      !this.state.stateOptions?.length &&
      config.user.stateAccessOptions()?.length > 1
    ) {
      this.setStateOptions(config.user.stateAccessOptions());
    }

    // Update valid dates
    if (
      config.user &&
      config.user.isAuthenticated() &&
      this.state.states &&
      this.state.states.size &&
      (!this.state.validDates || !this.state.validDates.length) &&
      !this.state.loadingValidDates &&
      !this.state.error
    ) {
      this.fetchValidDates();
    }

    // Update query URL only on certain pages
    if (didMount) {
      this.qs.listen(this.setStateIfDifferent.bind(this));
    }
    this.qs.write(
      this.state,
      !didMount && !pathNameChange ? args[1] : undefined,
      didMount,
    );

    // Update path URL
    this.updateRoute();
  }

  // Validate state.  Will get called with any setState
  _validateState(
    newState = {},
    combinedStateToCompare = {},
    stagingState = {},
  ) {
    const { statesDetails } = this.memoizedComputedStatesValues({
      states: combinedStateToCompare.states,
    });

    // We can't compare states since it use a map.
    const statesChanged = newState.states;

    // combinedStateToCompare is the newState and the stagingState
    // with preference to the newState, but newState may get updated along
    // the way.
    const v = (prop) => {
      return newState[prop] || stagingState[prop];
    };

    // No state set
    if (!statesDetails || !statesDetails.length) {
      newState.locales = [];
      newState.localeOptions = [];
      newState.localeTypes = [];
      newState.localeTypeOptions = [];
      newState.changes = new Map([['removal', true]]);
      newState.changeOptions = [];
      newState.demographicOptions = [];
      newState.demographicGroup = null;
      newState.demographicFilters = new Map();
      newState.validDates = [];
      newState.dates = [];
      return newState;
    }

    // Make locales options on state change or if not available
    if (
      statesDetails &&
      (statesChanged ||
        !v('localeOptions') ||
        !v('localeOptions').length ||
        !v('localeTypeOptions') ||
        !v('localeTypeOptions').length)
    ) {
      // Locale options change based on state
      newState.localeOptions = statesDetails[0].allLocales;
      newState.localeTypeOptions = _map(
        statesDetails[0].localeTypesAvailable,
        (t) => _find(config.localeTypes, { id: t }),
      );
    }

    // Default locales types if needed
    if (statesDetails && (!v('localeTypes') || !v('localeTypes').length)) {
      newState.localeTypes =
        v('localeTypesMultiple') === false
          ? [statesDetails[0].primaryLocaleType]
          : statesDetails[0].localeTypesAvailable;
    }

    // Make sure locale types are valid
    if (statesDetails && v('localeTypes') && v('localeTypes').length) {
      let stateDetails = statesDetails?.[0];
      let localeTypes = v('localeTypes');
      let filteredLocaleTypes = _filter(
        clone(localeTypes),
        (t) => !!~stateDetails.localeTypesAvailable.indexOf(t),
      );
      filteredLocaleTypes =
        filteredLocaleTypes.length > 0
          ? filteredLocaleTypes
          : v('localeTypesMultiple') === false
            ? [stateDetails.primaryLocaleType]
            : stateDetails.localeTypesAvailable;

      if (!isEqual(localeTypes, filteredLocaleTypes)) {
        newState.localeTypes = filteredLocaleTypes;
      }
    }

    // Make sure any locales are not selected if disabled,
    // and anything not available is not selected
    if (newState.locales || newState.localeOptions) {
      newState.locales = v('locales') || [];
      let localeOptions = v('localeOptions');

      if (localeOptions && localeOptions.length) {
        let allIds = _map(localeOptions, 'id');
        let disabledIds = _map(
          _filter(localeOptions, { disabled: true }),
          'id',
        );
        newState.locales = _filter(
          newState.locales,
          (l) => !!~allIds.indexOf(l),
        );
        newState.locales = _filter(
          newState.locales,
          (l) => !~disabledIds.indexOf(l),
        );
      }
      else {
        newState.locales = [];
      }
    }

    // If not multiple locales types, then make sure only locales that are of that type
    if (statesDetails && !v('localeTypesMultiple')) {
      let stateDetails = statesDetails?.[0];
      let localeType = v('localeTypes')?.[0];
      let locales = v('locales');
      if (stateDetails && localeType && locales && locales.length) {
        let localesDetails = _map(locales, (l) =>
          _find(stateDetails.allLocales, { id: l }),
        );
        let filteredLocalesDetails = _filter(
          localesDetails,
          (l) => l.localeTypeId === localeType,
        );
        let filteredLocales = _map(filteredLocalesDetails, 'id');
        if (!isEqual(locales, filteredLocales)) {
          newState.locales = filteredLocales;
        }
      }
    }

    // Change type options, update or make if states changed or if change options
    // provided or if no change options
    if (
      statesDetails &&
      (statesChanged ||
        newState.changeOptions ||
        !v('changeOptions') ||
        !v('changeOptions').length)
    ) {
      // Default change options
      newState.changeOptions =
        v('changeOptions') && v('changeOptions').length
          ? v('changeOptions')
          : cloneDeep(config.changeTypes);

      // Mark as disabled if needed
      if (statesDetails[0] && statesDetails[0].changeTypesUnavailable) {
        newState.changeOptions = _map(newState.changeOptions, (c) => {
          c.disabled =
            statesDetails[0].changeTypesUnavailable.indexOf(c.id) !== -1
              ? true
              : statesChanged
                ? false
                : c.disabled;
          return c;
        });
      }
    }

    // Make sure any changes are not selected if disabled,
    // and anything not available is not selected
    if (newState.changes || newState.changeOptions) {
      newState.changes = v('changes');
      let changeOptions = v('changeOptions');
      // Set disabled from change options
      changeOptions.forEach((o) => {
        if (o.disabled) {
          newState.changes.set(o.id, false);
        }
      });

      // Remove if not found in options
      newState.changes.forEach((v, k) => {
        if (!_find(changeOptions, { id: k })) {
          newState.changes.delete(k);
        }
      });
    }

    // Cleanup changes map
    if (newState.changes) {
      newState.changes.forEach((v, k) => {
        if (!v) {
          newState.changes.delete(k);
        }
      });
    }

    // Check change types from multiple to 1, then need to select only
    // one.
    let changesMultiple = combinedStateToCompare.changesMultiple;
    if (newState.changes && newState.changes.size) {
      if (newState.changes.size > 1 && changesMultiple === false) {
        newState.changes = new Map([
          [booleanMapToArray(newState.changes)[0], true],
        ]);
      }
    }

    // Make sure at least one change type is set
    if (newState.changes && changesMultiple === false) {
      if (!booleanMapToArray(newState.changes).length) {
        newState.changes = new Map([['removal', true]]);
      }
    }

    // Demographic options set on state change
    if (
      statesDetails &&
      (statesChanged ||
        !v('demographicOptions') ||
        !v('demographicOptions').length ||
        !v('demographicOptions')[0])
    ) {
      newState.demographicOptions = cloneDeep(config.demographicGroups);
      newState.demographicOptions = newState.demographicOptions.filter((g) => {
        // Filter out ones that are not allow in states, and keep
        // none.
        return statesDetails[0].demographicGroupsAvailable &&
          statesDetails[0].demographicGroupsAvailable.indexOf(g.id) !== -1
          ? true
          : g.id === 'none'
            ? true
            : false;
      });
    }

    // Ensure the demographic group is valid, disabled if needed to
    if (newState.demographicOptions && !newState.demographicOptions.length) {
      newState.demographicsEnabled = false;
    }
    else if (
      (newState.demographicOptions && newState.demographicOptions.length) ||
      !v('demographicGroup')
    ) {
      newState.demographicsEnabled = true;
      newState.demographicGroup = v('demographicGroup') || 'none';
      let demographicOptions = v('demographicOptions');
      newState.demographicGroup = _find(demographicOptions, {
        id: newState.demographicGroup,
      })
        ? newState.demographicGroup
        : 'none';
    }

    // Ensure that group filters are valid
    if (
      newState.demographicFilters ||
      newState.demographicOptions ||
      newState.demographicGroup
    ) {
      newState.demographicFilters = v('demographicFilters');
      let demographicOptions = v('demographicOptions');
      let demographicGroup = v('demographicGroup');
      let demographicGroupDetails = _find(demographicOptions, {
        id: demographicGroup,
      });

      if (demographicGroupDetails && demographicGroupDetails) {
        demographicGroupDetails.options.forEach((o) => {
          if (o.disabled) {
            newState.demographicFilters.set(o.id, false);
          }
        });
        newState.demographicFilters.forEach((v, k) => {
          newState.demographicFilters.set(
            k,
            _find(demographicGroupDetails.options, { id: k }) ? v : false,
          );
        });
      }
    }

    // Default demographic filters to all on
    if (
      statesChanged ||
      newState.demographicOptions ||
      newState.demographicGroup ||
      !v('demographicFilters')
    ) {
      newState.demographicFilters = v('demographicFilters') || new Map();
      let demographicOptions = v('demographicOptions');
      let demographicGroup = v('demographicGroup');
      let demographicGroupDetails = _find(demographicOptions, {
        id: demographicGroup,
      });
      let filtersArray = booleanMapToArray(newState.demographicFilters);

      if ((!filtersArray || !filtersArray.length) && demographicGroupDetails) {
        newState.demographicFilters = new Map(
          demographicGroupDetails.options.map((o) => [o.id, true]),
        );
      }
    }

    // Validate dates
    if (newState.dates) {
      let validDates = v('validDates');

      // If we don't have valid dates, it could be
      // that we just haven't loaded them yet
      if (!validDates || !validDates.length) {
        // Do nothing
      }
      else if (!newState.dates.length === 2) {
        newState.dates = this.getDefaultDates(validDates);
      }
      else if (!this.areValidDates(newState.dates, validDates)) {
        newState.dates = this.getDefaultDates(validDates);
      }
    }

    return newState;
  }

  // Update URL path for state change
  updateRoute(replace = false, primaryState) {
    let location = omit(config.history.location, ['key']);
    let toUpdate = clone(location);

    // State
    primaryState = primaryState || booleanMapToArray(this.state.states)[0];
    if (primaryState) {
      toUpdate.pathname = toUpdate.pathname.replace(
        /states\/([a-z0-9]+)\//,
        `states/${primaryState}/`,
      );
    }

    if (!isEqual(location, toUpdate)) {
      config.history[replace ? 'replace' : 'push'](toUpdate);
    }
  }

  // Get state from URL/router
  getStateFromRoute(props = {}) {
    let state = {};
    let { globalMatch } = props;
    const validStates = _map(config.states, 'id');

    if (~validStates.indexOf(globalMatch?.params?.state)) {
      state.states = new Map([[globalMatch.params.state, true]]);
    }

    return state;
  }

  // States helper
  setStates = (states) => {
    // Do a hard navigation to get a reset
    this.updateRoute(false, booleanMapToArray(states)[0]);
    window.location.reload();
  };
  setStatesEnabled = (enabled) => {
    this.setState(({ statesEnabled }) => {
      let e = isBoolean(enabled) ? enabled : !statesEnabled;
      return {
        statesEnabled: e,
      };
    });
  };
  setStateOptions = (stateOptions) => {
    this.setState({
      stateOptions: stateOptions
        ? stateOptions
        : config.user.stateAccessOptions(),
    });
  };
  computedStatesValues({ states }) {
    let statesArray = booleanMapToArray(states);
    let statesDetails = statesArray.map((s) => config.states[s]);
    let hasStates = statesArray.length >= 1;

    return {
      statesArray,
      hasStates,
      statesDetails,
      statesLabel: (property = 'name') => {
        if (statesArray.length === 1) {
          return config.states[statesArray[0]][property];
        }
        else if (statesArray.length > 1) {
          return `${statesArray.length} States`;
        }
      },
    };
  }

  // Locales values
  setLocalesEnabled = (enabled) => {
    this.setState(({ localesEnabled }) => {
      let e = isBoolean(enabled) ? enabled : !localesEnabled;
      return {
        localesEnabled: e,
      };
    });
  };
  setLocaleTypesEnabled = (enabled) => {
    this.setState(({ localeTypesEnabled }) => {
      let e = isBoolean(enabled) ? enabled : !localeTypesEnabled;
      return {
        localeTypesEnabled: e,
      };
    });
  };
  setLocales = (locales) => {
    this.setState({ locales });
    this._localesTrackingSetOnce = true;
  };
  setLocaleTypes = (localeTypes) => {
    this.setState({ localeTypes });
    this._localeTypesTrackingSetOnce = true;
  };
  setLocaleTypeOnly = (localeType) => {
    this.setState(({ states, locales, localeOptions }) => {
      let statesArray = booleanMapToArray(states);
      let stateDetails = _find(config.states, { id: statesArray?.[0] });
      let localesDetails = locales?.map((l) => {
        return localeOptions?.find((o) => o.id === l);
      });
      // Make sure locale type exists or default to primary locale type.
      localeType =
        stateDetails?.localeTypesAvailable?.indexOf(localeType) !== -1
          ? localeType
          : stateDetails?.primaryLocaleType;
      let localeTypes = uniq(localesDetails?.map((l) => l?.localeTypeId));
      let hasOtherLocaleType =
        localeTypes.length > 1 || localeTypes[0] !== localeType;

      return {
        locales: hasOtherLocaleType ? [] : locales?.length ? [locales[0]] : [],
        localeTypesMultiple: false,
        localeTypesEnabled: false,
        localeTypes: localeType ? [localeType] : [],
      };
    });
  };
  computedLocalesValues({
    localeTypeOptions,
    localeTypes,
    localeOptions,
    locales,
    localesEnabled,
    localeTypesEnabled,
    localeTypesMultiple,
  }) {
    // Used to be a Map, so to support all way, copy to array
    let localesArray = locales;
    let localesDetails = localesArray.map((l) => {
      return localeOptions.find((o) => o.id === l);
    });
    let localeTypesDetails = localeTypes.map((l) => {
      return localeTypeOptions.find((o) => o.id === l);
    });

    return {
      localeTypesDetails,
      localesArray,
      localesDetails,
      hasLocales: localesArray.length >= 1,
      localeOptions,
      localesLabel: () => {
        let localeLabel;

        // Locales enabled
        if (localesEnabled) {
          if (localesArray.length === 1) {
            localeLabel = localesDetails[0].name || '-';
          }
          else if (localesArray.length > 1) {
            localeLabel = `${localesArray.length} Locales`;
          }
          else if (!localeTypesMultiple) {
            localeLabel = localeTypesDetails?.[0]?.namePlural;
          }
        }
        else if (localeTypesEnabled) {
          localeLabel = localeTypesDetails?.[0]?.namePlural;
        }

        return localeLabel;
      },
      hasLocaleOptions: localeOptions && localeOptions.length > 1,
      hasLocaleTypeOptions: localeTypeOptions && localeTypeOptions.length > 1,
    };
  }
  setLocaleTypesMultiplicity = (multiplicity) => {
    this.setState(({ localeTypesMultiple, localeTypes, states }) => {
      let statesArray = booleanMapToArray(states);
      let stateDetails = _find(config.states, { id: statesArray?.[0] });

      // Allow for toggle
      let m = isBoolean(multiplicity) ? multiplicity : !localeTypesMultiple;

      // No change
      if (m === localeTypesMultiple) {
        return {};
      }

      // If going to single, make sure there is an option
      if (m === false && !localeTypes.length) {
        return {
          localeTypesMultiple: m,
          localeTypes: stateDetails ? [stateDetails.primaryLocaleType] : [],
        };
      }
      // If going to single, choose first one
      else if (m === false && localeTypes.length > 1) {
        return {
          localeTypesMultiple: m,
          // TODO: This should probably look at the locales that are selected
          // and choose a locale type based on that.
          localeTypes: [localeTypes[0]],
        };
      }

      return { localeTypesMultiple: m };
    });
  };

  // Primary locale
  setPrimaryLocale = (localeId) => {
    this.setState({ primaryLocale: localeId });
  };

  // Change types helpers
  setChangesEnabled = (enabled) => {
    this.setState(({ changesEnabled }) => {
      let e = isBoolean(enabled) ? enabled : !changesEnabled;
      return {
        changesEnabled: e,
      };
    });
  };
  setChanges = (changes) => {
    this.setState({ changes });
  };
  setChangesEmptyLabel = (changesEmptyLabel) => {
    this.setState({ changesEmptyLabel });
  };
  setEnabledChangeOptions = (enabled = 'all') => {
    // TODO: Should this disable options that
    // aren't defined in 'enabled'
    this.setState(({ changeOptions }) => {
      return {
        changeOptions: _map(changeOptions, (o) => {
          o.disabled =
            enabled === 'all'
              ? false
              : ~enabled.indexOf(o.id)
                ? false
                : o.disabled;
          return o;
        }),
      };
    });
  };
  setDisabledChangeOptions = (disabled = 'all') => {
    // TODO: Should this enable options that
    // aren't defined in 'disabled'
    this.setState(({ changeOptions }) => {
      return {
        changeOptions: _map(changeOptions, (o) => {
          o.disabled =
            disabled === 'all'
              ? true
              : ~disabled.indexOf(o.id)
                ? true
                : o.disabled;
          return o;
        }),
      };
    });
  };
  computedChangesValues({ changes, changesEmptyLabel, changeOptions }) {
    let changesArray = booleanMapToArray(changes);
    let changesDetails = changesArray.map((c) => {
      return changeOptions.find((o) => o.id === c);
    });

    return {
      hasChanges: changesArray.length > 0,
      changesArray,
      changesDetails,
      changesLabel: () => {
        let primaryChange = changeOptions.find((l) => l.id === changesArray[0]);
        return changesArray.length > 1
          ? `${changesArray.length} types`
          : primaryChange
            ? primaryChange.name
            : changesEmptyLabel;
      },
    };
  }
  setChangesMultiplicity = (multiplicity) => {
    this.setState(({ changesMultiple, changes }) => {
      const changesArray = booleanMapToArray(changes);

      // Allow for toggle
      let m = isBoolean(multiplicity) ? multiplicity : !changesMultiple;

      // No change
      if (m === changesMultiple) {
        return {};
      }

      // If going to single, make sure there is an option
      if (m === false && !changesArray.length) {
        return {
          changesMultiple: m,
          changes: new Map([['removal', true]]),
        };
      }
      // If going to single, choose first one
      else if (m === false && changesArray.length > 1) {
        return {
          changesMultiple: m,
          changes: new Map([[changesArray[0], true]]),
        };
      }

      return { changesMultiple: m };
    });
  };

  // Demographics enabled
  setDemographicsEnabled = (enabled) => {
    this.setState(({ demographicsEnabled }) => {
      let e = isBoolean(enabled) ? enabled : !demographicsEnabled;
      return {
        demographicsEnabled: e,
      };
    });
  };
  setDemographics = (group, filters) => {
    this.setState((state) => {
      // Check to see if the demographic group changed
      if (state.demographicGroup !== group) {
        return {
          demographicGroup: group,
          demographicFilters: filters,
        };
      }
      else {
        return {
          demographicFilters: filters,
        };
      }
    });
  };
  setDemographicGroup = (demographicGroup) => {
    this.setState({
      demographicGroup,
    });
  };
  setDemographicFilters = (demographicFilters) => {
    this.setState({
      demographicFilters,
    });
  };
  computedDemographicsValues({
    demographicOptions,
    demographicGroup,
    demographicFilters,
  }) {
    let hasOptions = demographicOptions && demographicOptions.length;
    let demographicGroupDetails = hasOptions
      ? demographicOptions.find((l) => l.id === demographicGroup)
      : [];
    let demographicFiltersArray = hasOptions
      ? booleanMapToArray(demographicFilters)
      : [];
    let demographicFiltersDetails =
      hasOptions && demographicGroupDetails
        ? demographicFiltersArray.map((d) => {
          return demographicGroupDetails.options.find((o) => o.id === d);
        })
        : [];

    return {
      hasDemographics:
        demographicGroupDetails && demographicGroupDetails.id !== 'none',
      demographicGroupDetails,
      demographicFiltersArray,
      demographicFiltersDetails,
      demographicsLabel: () => {
        let demographics = demographicOptions.find(
          (l) => l.id === demographicGroup,
        );
        return demographics && demographics.id !== 'none'
          ? `By ${demographics.name}`
          : 'Demographics';
      },
    };
  }

  // Dates helpers
  setDatesEnabled = (enabled) => {
    this.setState(({ datesEnabled }) => {
      let e = isBoolean(enabled) ? enabled : !datesEnabled;
      return {
        datesEnabled: e,
      };
    });
  };
  setValidDates = (validDates) => {
    this.setState({ validDates });
  };
  setDates = (dates) => {
    this.setState({ dates });
  };

  abortFetchValidDates(setState = true) {
    if (this._validDatesAbortController) {
      this._validDatesAbortController.abort();
    }

    if (setState) {
      this.setState({
        validDates: null,
        loadingValidDates: false,
      });
    }
  }
  async fetchValidDates() {
    this.abortFetchValidDates(false);
    await this.setStateTick();

    // Check user
    if (!config.user || !config.user.isAuthenticated()) {
      return;
    }

    // Unsure how to do this?
    // this.setState(async ({ states }) => {
    //   Set state in here
    // }

    // This is not the right way
    await this.setStateTick();
    let states = this.state.states;

    // Make sure we have states
    if (!states || !states.size) {
      // TODO: What to do here
      return this.setState({ loadingValidDates: false });
    }

    // Mark as loading
    this.setState({
      loadingValidDates: true,
      validDates: [],
    });

    let details;
    try {
      let { statesDetails, hasStates } = this.memoizedComputedStatesValues({
        states,
      });
      details = statesDetails;

      if (!hasStates) {
        return this.setState({ loadingValidDates: false });
      }

      // API call
      this._validDatesAbortController = abortController();
      let response = await config.user.api('valid/post_date', {
        signal: this._validDatesAbortController.signal,
        query: {
          state: statesDetails[0].apiId,
        },
      });
      handleFormattedResponseErrors(response);

      // Format
      // TODO: This is to handle the old style of timestamps
      let transformed = dataTransformValidPostDate(response.json);
      let validDates = _map(transformed, (d) => d.post_date);
      return this.setState(({ dates }) => {
        return {
          loadingValidDates: false,
          validDates,
          dates: this.areValidDates(dates, validDates)
            ? dates
            : this.getDefaultDates(validDates),
        };
      });
    }
    catch (e) {
      // Ignore abort error
      if (e.name === 'AbortError') {
        return this.setState({
          loadingValidDates: false,
          error: null,
        });
      }

      console.error(e);
      captureException(e, {
        tags: { feature: 'global filter - valid post dates' },
      });
      return this.setState({
        loadingValidDates: false,
        error: `Error fetching dates for ${details[0].name}: "${e.toString()}"`,
      });
    }
  }

  getDefaultDates(validDates) {
    let maxDate = validDates[validDates.length - 1];
    // Default to quarter
    let minEstimateDate = moment.utc(maxDate).subtract(3, 'month').valueOf();
    let minIndex = findClosestFloor(minEstimateDate, validDates);

    // Make sure min is at least two points away from max
    minIndex = Math.max(0, Math.min(minIndex, validDates.length - 3));

    return [validDates[minIndex], maxDate];
  }

  areValidDates(dates, validDates) {
    return ~validDates.indexOf(dates[0]) && ~validDates.indexOf(dates[1]);
  }

  computedValidDatesValues({ validDates }) {
    let min = _min(validDates);
    let max = _max(validDates);

    return {
      minValidDate: min,
      maxValidDate: max,
    };
  }

  // Complete label
  computedLabel({
    statesLabel,
    localesLabel,
    changesLabel,
    demographicsLabel,
    dates,
  }) {
    const joinFilter = (parts, join = ' - ') => {
      return _filter(parts).join(join);
    };
    const run = (label) => {
      return label ? label() : null;
    };

    return {
      label: (join) =>
        joinFilter([
          run(statesLabel),
          run(localesLabel),
          run(changesLabel),
          run(demographicsLabel),
          dates && dates.length
            ? `${outputDate(dates[0])} to ${outputDate(dates[1])}`
            : null,
        ]),
    };
  }

  // Main render
  render() {
    // Computed properies
    let computed = {
      ...this.memoizedComputedStatesValues(pick(this.state, ['states'])),
      ...this.memoizedComputedLocalesValues(
        pick(this.state, [
          'localeTypeOptions',
          'localeTypes',
          'locales',
          'localeOptions',
          'localesEnabled',
          'localeTypesEnabled',
          'localeTypesMultiple',
        ]),
      ),
      ...this.memoizedComputedChangesValues(
        pick(this.state, ['changeOptions', 'changes', 'changesEmptyLabel']),
      ),
      ...this.memoizedComputedDemographicsValues(
        pick(this.state, [
          'demographicOptions',
          'demographicGroup',
          'demographicFilters',
        ]),
      ),
      ...this.memoizedComputedValidDatesValues(
        pick(this.state, ['validDates']),
      ),
    };

    // Full label
    computed = {
      ...computed,
      ...this.computedLabel({ ...computed, ...this.state }),
    };

    return (
      <FilterContext.Provider value={{ ...this.state, ...computed }}>
        {this.props.children}
      </FilterContext.Provider>
    );
  }
}

// We want to get router info
export default withGlobalRouteMatch(FilterContextDefaults);

/**
 * Load page
 */

// Dependencies
import React from 'react';

// Assets
import loader from '../../../assets/images/loader-logo-200.gif';
import CoreComponent from '../../../extensions/CoreComponent';

// Main component
export default class LoadingPage extends CoreComponent {
  static propTypes = {};
  static defaultProps = {};

  componentDidMount() {
    // Specifically do not update the page title
    //document.title = this.props.title || 'Error | VoteShield';
  }

  // Main render
  render() {
    return (
      <div className="container-lg mb-4 pt-2">
        <img
          className="display-block center-horizontal"
          alt="Loading animated gif"
          src={loader}
        />

        <h1 className="page-title text-center">Loading...</h1>

        {this.props.children}
      </div>
    );
  }
}

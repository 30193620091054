// Dependencies
import _each from 'lodash/each';
import isArray from 'lodash/isArray';
import isMap from 'lodash/isMap';
import isObject from 'lodash/isObject';

/**
 * Convert a map of booleans to an array of keys.
 * Map([['a', true], ['b', false]]) => ['a']
 *
 * @param {Map|Array|object} mapCollection Map or other collection to convert.
 * @returns {Array} Converted array
 */
export default function booleanMapToArray(mapCollection) {
  if (isMap(mapCollection)) {
    return Array.from(mapCollection.entries())
      .filter((e) => {
        return !!e[1];
      })
      .map((e) => e[0]);
  }

  // Some sort of collection
  if (isArray(mapCollection) || isObject(mapCollection)) {
    let keys = [];
    _each(mapCollection, (v, k) => !!v && keys.push(k));
    return keys;
  }

  return [];
}

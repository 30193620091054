/**
 * Component to check if user has access to the state that it being requested.
 */

// Dependencies
import _map from 'lodash/map';
import PropTypes from 'prop-types';
import React from 'react';
import { useParams } from 'react-router-dom';

import AccessDeniedPage from '../../components/pages/errors/AccessDeniedPage';
import NotFoundPage from '../../components/pages/errors/NotFoundPage';
import { states, user } from '../../config';
import AuthorizedAuthenticated from './AuthorizedAuthenticated';

// State helpers
const validStates = _map(states, 'id');
const isValidState = (s) => !!~validStates.indexOf(s);

/**
 * Component to check if user has access to the state that it being requested.
 *
 * @param {*} props Props for component
 * @returns {React.element} - React element
 */
export default function AuthorizedState({ children }) {
  let params = useParams();

  if (!isValidState(params.state)) {
    return <NotFoundPage />;
  }

  if (!user.hasStateAccess(params.state)) {
    return <AccessDeniedPage message="You do not have access to this state." />;
  }

  return (
    <AuthorizedAuthenticated redirect="/login">
      {children}
    </AuthorizedAuthenticated>
  );
}

AuthorizedState.propTypes = {
  children: PropTypes.node,
};

/**
 * Landing - Testimonial section
 */

// Styles
import './TestimonialSection.scss';

// Dependencies
import React from 'react';

import imageBrater from '../../assets/images/jonathan-brater.jpg';
import imagePate from '../../assets/images/paul-pate.jpg';
import CoreComponent from '../../extensions/CoreComponent';
import BalanceText from '../text/BalanceText';

// Components
export default class TestimonialSection extends CoreComponent {
  // Render
  render() {
    // Content
    return (
      <section className="testimonials">
        <div className="container-copy-large center-horizontal text-center">
          <h1>Collaborative Partnerships</h1>
          <p className="heading-copy">
            <BalanceText>
              How election professionals at the county and state level are using
              VoteShield.
            </BalanceText>
          </p>
        </div>

        <div className="row testimonial">
          <div className="col-md-1"></div>
          <div className="col-md-7">
            <div className="display-block display-sm-flex">
              <div>
                <div className="logo-decoration">
                  <div className="t1-lhalf">
                    <div className="t1-r2">
                      <div
                        className="logo-content"
                        style={{
                          backgroundImage: `url('${imagePate}')`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <blockquote>
                  <BalanceText>
                    &ldquo;VoteShield is an important component to help us
                    ensure the integrity of Iowa&rsquo;s voter data and
                    elections. The system is available for all 99 county
                    auditors to use. Auditors know their counties best, so if an
                    anomaly is detected, they are able to investigate it
                    themselves.&rdquo;
                  </BalanceText>

                  <footer>
                    <cite>
                      <BalanceText>
                        Paul Pate, Iowa Secretary of State
                      </BalanceText>
                    </cite>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>

        <div className="row testimonial">
          <div className="col-md-4"></div>
          <div className="col-md-7">
            <div className="display-block display-sm-flex">
              <div>
                <div className="logo-decoration">
                  <div className="t1-lhalf">
                    <div className="t1-r2">
                      <div
                        className="logo-content"
                        style={{
                          backgroundImage: `url('${imageBrater}')`,
                        }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <blockquote>
                  <BalanceText>
                    &ldquo;VoteShield offers an unparalleled external analysis
                    of changes to voter rolls, which ensures that large scale
                    voter registration irregularities are not occurring, and if
                    they were, that we would detect them.&rdquo;
                  </BalanceText>

                  <footer>
                    <cite>
                      <BalanceText>
                        Jonathan Brater, Director of the Michigan Bureau of
                        Elections
                      </BalanceText>
                    </cite>
                  </footer>
                </blockquote>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

/**
 * User login (password) form.
 */

// Dependencies
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import { constants, supportEmail, user } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';
import dispatch from '../../modules/events/dispatch';
import gaEventWrapper from '../../modules/ga/event-wrapper';
import captureException from '../../modules/sentry/capture-exception';
import validators from '../../modules/validators';
import FormItem from '../forms/FormItem';
import EmailLink from '../links/EmailLink';

// Login page componet
export default class PasswordForm extends CoreComponent {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      // We use this to set the submit button
      // disabled by default
      hasValidated: false,
    };

    // Allow override
    this.overrideStateWithProp(this.state);
  }

  // Prop types
  static propTypes = {
    onLoginSuccess: PropTypes.func,
    onLoginPasswordChange: PropTypes.func,
    onLoginError: PropTypes.func,
    onRequiresMfa: PropTypes.func,
    initialState: PropTypes.object,
    buttonClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    redirect: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  };

  // Default props
  static defaultProps = {};

  // Unmount
  componentWillUnmount() {
    this._unmounted = true;
  }

  // Validation
  validate() {
    return (values) => {
      this.setState({ hasValidated: true });
      let errors = {};

      if (!values.username) {
        errors.username = 'Username is required.';
      }
      else if (!validators.isEmail(values.username)) {
        errors.username = 'Username should be a valid email address.';
      }

      if (!values.password) {
        errors.password = 'Password is required.';
      }

      return errors;
    };
  }

  // Login
  login = async (values, actions) => {
    // Login
    try {
      await user.login(values.username, values.password, {
        redirect: this.props.redirect,
      });

      // TODO: The user has events such as "login" that may
      // be used to redirect when logging in, but if that
      // is the case, we don't want to do anything below here.
    }
    catch (e) {
      console.error(e);
      captureException(e, { tags: { feature: 'user action - login' } });

      // Handle error
      actions.setErrors({ form: e.toString() });
      actions.setSubmitting(false);
      dispatch(this.props.onLoginError, e, this);
      return;
    }

    // Make sure we are not unmounted
    if (this._unmounted) {
      return;
    }

    // Check if authentication
    if (user.authorization === constants.AUTHORIZATION.REQUIRES_MFA) {
      actions.resetForm();
      actions.setStatus({
        success:
          'Username and password verification, but your account requires a verification code from your phone or an app.',
      });

      actions.setSubmitting(false);
      dispatch(this.props.onRequiresMfa, this);
    }
    else if (user.authorization === constants.AUTHORIZATION.AUTHENTICATED) {
      actions.resetForm();
      actions.setStatus({ success: 'Logged in successfully.' });

      actions.setSubmitting(false);
      dispatch(this.props.onLoginSuccess, this);
    }
    else if (
      user.authorization === constants.AUTHORIZATION.REQUIRES_PASSWORD_CHANGE
    ) {
      actions.resetForm();
      actions.setStatus({
        success: 'Logged in, but you need to reset your password.',
      });

      actions.setSubmitting(false);
      dispatch(this.props.onLoginPasswordChange, this);
    }
    else {
      actions.setErrors({
        form: 'Something unknown happened with logging in.',
      });

      actions.setSubmitting(false);
      dispatch(this.props.onLoginError, undefined, this);
    }
  };

  // Main render
  render() {
    return (
      <Formik
        initialValues={{ username: '', password: '' }}
        validateOnBlur
        validateOnChange
        validate={this.validate()}
        onSubmit={gaEventWrapper(this.login, {
          category: 'user',
          action: 'login',
        })}
      >
        {({
          values,
          errors,
          status,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <FormItem
              id="username"
              inputType="text"
              label="Username"
              help="Your username is most likely your email address."
              helpHide={true}
              error={errors.username}
              value={values.username}
              touched={touched.username}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                required: true,
                autoComplete: 'username',
              }}
            />

            <FormItem
              id="password"
              inputType="password"
              label="Password"
              help="Enter your corresponding password."
              helpHide={true}
              error={errors.password}
              value={values.password}
              touched={touched.password}
              onChange={handleChange}
              onBlur={handleBlur}
              inputProps={{
                required: true,
                autoComplete: 'password',
              }}
            />

            {errors.form && (
              <small className="error">
                {errors.form} If you have forgotten it, you can{' '}
                <Link to="/forgot">reset your password</Link>. If you require
                more help, please{' '}
                <EmailLink
                  email={supportEmail}
                  subject="Help with logging in"
                  body={`I received the following error trying to login: ${errors.form}\n`}
                >
                  contact support
                </EmailLink>
                .
              </small>
            )}

            {status && status.success && <small>{status.success}</small>}

            <button
              type="submit"
              className={this.props.buttonClassName}
              disabled={
                !this.state.hasValidated || !isEmpty(errors) || isSubmitting
              }
            >
              {isSubmitting ? 'Submitting...' : 'Login'}
            </button>
          </form>
        )}
      </Formik>
    );
  }
}

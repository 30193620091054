/**
 * Presets for Ballots Locales Scatter
 */

const ballotScatterPresets = [
  {
    id: 'unprocessed-app-by-time-sent',
    name: 'Unprocessed applications by Time to send',
    help: '',
    x: 'app-return-to-ballot-sent',
    y: 'unprocessed-app',
  },
  {
    id: 'time-returned-by-percent-returned',
    name: 'Time to return by Percent returned',
    help: '',
    x: 'ballot-sent-to-ballot-return',
    y: 'ballot-returned-per',
  },
  {
    id: 'ballot-returned-by-ballot-rejected',
    name: 'Ballots returned by Ballots rejected',
    help: '',
    x: 'ballot-returned',
    y: 'ballot-rejected',
  },
  {
    id: 'ballot-sent-by-ballot-returned',
    name: 'Ballots sent by Ballots returned',
    help: '',
    x: 'ballot-sent',
    y: 'ballot-returned',
  },
];

export default ballotScatterPresets;

/**
 * Main entry point for JS where we include and attach the main
 * React app component.
 */

// Polyfills
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
// This also helps with Cypress API mocking
import 'whatwg-fetch';

// Dependencies
import React from 'react';
import { createRoot } from 'react-dom/client';

import App from './App';

// TODO: Do something with service worker
// import registerServiceWorker from './registerServiceWorker';
// registerServiceWorker();

// Render app
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App />);

// For dev, enable hot module swapping
if (module.hot) {
  module.hot.accept();
}

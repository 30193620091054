// Dependencies
import sortBy from 'lodash/sortBy';

import toMoment from '../convert/to-moment';

/**
 * Transform data from the valid/post_date API.
 *
 * TODO: This is mostly a bandaid to deal with the change from
 * timestamp to ISO dates.
 *
 * @param {Array} data Original data from the API
 * @returns {Array} Transformed data
 */
export default function dataTransformValidPostDate(data) {
  let transformed = data.map((d) => {
    d.timestamp = d.post_date;
    d.moment = toMoment(d.post_date);
    d.post_date = +d.moment;

    return d;
  });

  // Sort
  sortBy(transformed, (d) => d.post_date);

  return transformed;
}

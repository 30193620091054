/**
 * Generic event emitter class.  Maybe use an existing library?
 *
 * Inspired: https://gist.github.com/mudge/5830382#gistcomment-2623252
 *
 * @class
 */
export default class EventEmitter {
  /**
   * Add event listener.
   *
   * @param {string} event Name of event
   * @param {Function} listener Function to call on event
   * @returns {Function} Function to remove the listener
   */
  on(event, listener) {
    this._events = this._events || {};

    if (typeof this._events[event] !== 'object') {
      this._events[event] = [];
    }
    this._events[event].push(listener);
    return () => this.removeListener(event, listener);
  }

  /**
   * Alias to 'on'
   *
   * @param  {...any} args See 'on' method.
   * @returns {Function} Function to remove the listener
   */
  addListener(...args) {
    return this.on(...args);
  }

  /**
   * Remove event listener.
   *
   * @param {string} event Name of event
   * @param {Function} listener Existing listener function to remove
   */
  off(event, listener) {
    this._events = this._events || {};

    if (typeof this._events[event] === 'object') {
      const idx = this._events[event].indexOf(listener);
      if (idx > -1) {
        this._events[event].splice(idx, 1);
      }
    }
  }

  /**
   * Alias to 'off' method.
   *
   * @param  {...any} args See 'off' method
   * @returns {undefined}
   */
  removeListener(...args) {
    return this.off(...args);
  }

  /**
   * Remove all event listeners of a type.
   *
   * @param {string} event Name of event
   */
  offOfType(event) {
    this._events = this._events || {};

    if (typeof this._events[event] === 'object') {
      this._events[event] = [];
    }
  }

  /**
   * Alias to 'offOfType' method.
   *
   * @param  {...any} args See 'offOfType' method
   * @returns {undefined}
   */
  removeOfType(...args) {
    return this.offOfType(...args);
  }

  /**
   * Remove all events.
   */
  offAll() {
    this._events = {};
  }

  /**
   * Alias to 'offAll' method.
   *
   * @param  {...any} args See 'offAll' method
   * @returns {undefined}
   */
  removeAll(...args) {
    return this.offAll(...args);
  }

  /**
   * Emit an event and call its listeners.
   *
   * @param {string} event Name of event
   * @param  {...any} args Args to pass to event listeners
   */
  emit(event, ...args) {
    this._events = this._events || {};

    if (typeof this._events[event] === 'object') {
      this._events[event].forEach((listener) => listener.apply(this, args));
    }
  }

  /**
   * Alias to 'emit' method.
   *
   * @param  {...any} args See 'emit' method
   * @returns {undefined}
   */
  trigger(...args) {
    return this.emit(...args);
  }

  /**
   * Like on but will only fire once
   *
   * @param {string} event Name of event
   * @param {Function} listener Function to call on event
   */
  once(event, listener) {
    this._events = this._events || {};

    const remove = this.on(event, (...args) => {
      remove();
      listener.apply(this, args);
    });
  }
}

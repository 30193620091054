/**
 * Placeholder for User class
 */

// Dependencies
//import moment from 'moment';
import bind from 'lodash/bind';
import defaults from 'lodash/defaults';
import _each from 'lodash/each';
import extend from 'lodash/extend';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import isBoolean from 'lodash/isBoolean';
import isObject from 'lodash/isObject';
import isPlainObject from 'lodash/isPlainObject';
import isString from 'lodash/isString';
import kebabCase from 'lodash/kebabCase';
import _map from 'lodash/map';
import pick from 'lodash/pick';
import snakeCase from 'lodash/snakeCase';
import sortBy from 'lodash/sortBy';

import {
  allStateAccessRoles,
  cookies,
  states as statesConfig,
} from '../../config';
import constants from '../../config/constants';
import captureException from '../../modules/sentry/capture-exception';
import handleFormattedResponseErrors from '../api/handle-formatted-response-errors';
import apiRequest from '../api/request';
import EventEmitter from '../events/EventEmitter';

/**
 * User class.  Manages user and logging in and out.  Utilizes
 * events so that routing and interface can hook into actions
 * like login, logout, requires-password-change
 */
export default class User extends EventEmitter {
  constructor(options = {}) {
    super(options);

    // Attach options
    this.options = options;

    // Properties
    this.cacheKey = 'voteshield-user';
    this.cachedProperties = [
      'id',
      'username',
      'profile',
      // The type of access that the user has and not any specific access tokens
      // or similar.  Used to create an assumption for actions, but access is always
      // checked on the server side.
      'authorization',
      'roles',
      'stateAccess',
    ];
    this.stateAccessRoleMatch = /^AccessState(.*)All$/;
    this.stateAccessIdentifierTranslator = (accessState) =>
      snakeCase(accessState).replace(/_([0-9])/m, '$1');
    this.logoutErrorTypeMatch = /(token|jwt|session)/i;

    // Profile properties which we will rename and put
    // into .profile object
    // Format: api name: user object name
    this.profileProperties = {
      email: 'email',
      email_verified: 'emailVerified',
      family_name: 'last',
      given_name: 'first',
      phone_number: 'phone',
      phone_number_verified: 'phoneVerified',
      'custom:organization': 'organization',
      'custom:default_state': 'state',
      'custom:default_locale_type': 'defaultLocaleType',
      'custom:default_locales': 'locales',
      'custom:notify_snapshot': 'notificationsNewData',
      'custom:tos_agreed': 'agreedTos',
      mfa_preference: 'mfa',
      mfa_available: 'mfaEnabled',
    };

    // Translate profile values before sending
    // to the API.  Basically everything needs to be a string.
    this.transformProfileDataToSend = options.transformProfileDataToSend
      ? options.transformProfileDataToSend
      : (data) => {
        // Notify snapshot needs to be True/False as string
        const booleanToStrings = [
          'custom:notify_snapshot',
          'custom:tos_agreed',
        ];
        booleanToStrings.forEach((p) => {
          if (isBoolean(data[p])) {
            data[p] = data[p] ? 'True' : 'False';
          }
        });
        // Locales is a string of an array
        if (!isString(data['custom:default_locales'])) {
          try {
            data['custom:default_locales'] = JSON.stringify(
              data['custom:default_locales'],
            );
          }
          catch (e) {
            data['custom:default_locales'] = '';
          }
        }

        return data;
      };

    // Transform profile data received by API
    this.transformProfileDataReceived = options.transformProfileDataReceived
      ? options.transformProfileDataReceived
      : (data) => {
        // Read booleans
        let parseBooleans = [
          'notificationsNewData',
          'emailVerified',
          'phoneVerified',
          'agreedTos',
        ];
        parseBooleans.forEach((field) => {
          data[field] =
              data[field] && data[field].match(/true|yes/i)
                ? true
                : data[field] && data[field].match(/false|no/i)
                  ? false
                  : undefined;
        });

        // Read JSON
        try {
          data.locales = JSON.parse(data.locales);
        }
        catch (e) {
          data.locales = [];
        }

        return data;
      };

    // Initialize profile
    this.profile = this.profile || {};

    // Load up values from cache
    this.loadCache();

    // Attach any events passed with options
    [
      'onLogout',
      'onLogin',
      'onPasswordChange',
      'onInitializePassword',
      'onRequiresPasswordChange',
    ].forEach((o) => {
      if (options[o]) {
        this.on(kebabCase(o.replace(/^on/, '')), options[o]);
      }
    });

    // Attach api
    this.attachApi();
  }

  /**
   * Parse profile data from API
   *
   * @param {object} rawProfileData - Raw profile data that comes back from the API
   */
  attachRawProfileData(rawProfileData) {
    this.profile = this.profile || {};

    // Check input
    if (!isPlainObject(rawProfileData)) {
      return;
    }

    // Attach ID
    this.id = rawProfileData.sub || this.id;

    // Attach username which is email
    // TODO: Should the API return a username field?
    this.username = rawProfileData.email || this.username;

    // Attach roles
    this.roles = rawProfileData['cognito:groups'] || this.roles || [];

    // Parse state specific roles
    this.stateAccess = _filter(
      _map(this.roles, (r) => {
        let m = r.match(this.stateAccessRoleMatch);
        return this.stateAccessIdentifierTranslator(m?.[1]);
      }),
    );

    // Parse raw profile data
    let transformedProfileData = {};
    _each(this.profileProperties, (v, k) => {
      if (rawProfileData && rawProfileData[k]) {
        transformedProfileData[v] = rawProfileData[k];
      }
    });
    transformedProfileData = this.transformProfileDataReceived(
      transformedProfileData,
    );

    // Attach to profile
    this.profile = extend(this.profile, transformedProfileData);
  }

  /**
   * Clear user data such as profile info
   */
  clearUserData() {
    // Clear properties in object
    ['username', 'authorization', 'profile', 'roles'].forEach((p) => {
      delete this[p];
    });

    // Clear session cookies.
    // NOTE: These are http only so they can only be removed from the server side.
    let cookieOptions = {
      path: '/',
      httpOnly: true,
      domain: window.location.hostname,
    };
    cookies.remove('session', cookieOptions);
    cookies.remove('connect.sid', cookieOptions);
  }

  /**
   * Logout, which basically emits logout events
   *
   * @returns {boolean} True if logout successful.
   * @fires User#logout
   */
  async logout() {
    // Ensure we don't log out multiple times.
    if (this.isLoggingOut) {
      return false;
    }
    this.isLoggingOut = true;

    try {
      let response = await this.api('auth/logout', { method: 'post' });
      if (!response || !response.status || response.status !== 200) {
        throw new Error('Unable to log user out.');
      }
    }
    catch (e) {
      console.error(e);
      captureException(e, { tags: { feature: 'user action - logout' } });

      this.isLoggingOut = false;
      return false;
    }

    let username = this.username;
    this.clearUserData();
    this.clearCache();
    this.isLoggingOut = false;

    /**
     * Logout event.
     *
     * @event User#logout
     * @type {object}
     * @property {object} params Sent to event
     * @property {User} params.user User object
     * @property {string} params.username Username that was used
     */
    this.emit('logout', {
      user: this,
      username,
    });

    return true;
  }

  /**
   * Some actions may automatically log someone out, but how we
   * log them out is more of a UI decision, so we send an event.
   *
   * @fires User#logout-initiated
   */
  initiateLogout() {
    /**
     * Logout initiated event.
     *
     * @event User#logout-initiated
     * @type {object}
     * @property {object} params Sent to event
     * @property {User} params.user User object
     */
    this.emit('logout-initiated', {
      user: this,
    });
  }

  /**
   * Login.  Make API call to login.  Note that password
   * is never attached to the object itself.
   *
   * @async
   * @param {string} username Username
   * @param {string} password Password
   * @param {any} eventOptions Any options to send to the login event, or use
   *   false to not fire the login event.
   * @fires User#login
   * @fires User#requires-password-change
   * @returns {number} What happened, see AUTHENTICATION_LEVELS in
   *   constants
   */
  async login(username, password, eventOptions = {}) {
    // Check values
    if (!password || !isString(password)) {
      throw new Error('Password is required to login');
    }
    if (!username || !isString(username)) {
      throw new Error('Username is required to login');
    }

    // Make username lowercase.  Cognito's usernames are case sensitive, which
    // makes sense, but we are using email addresses as usernames, and emails
    // are not case sensitive, we get around this by making sure all usernames
    // are lowercase in the system, and have the interface lowercase it.
    // Reference:
    // https://github.com/Voteshield/frontend/issues/259
    username = username.toLowerCase();

    // Make request
    let response;
    try {
      response = await apiRequest('authenticate', {
        body: { username, password },
      });
    }
    catch (e) {
      // More friendly for user
      throw new Error(`There was an error logging in: ${e.toString()}`);
    }

    // Check status code (403 means require password change)
    if (response.status === 403) {
      this.authorization = constants.AUTHORIZATION.REQUIRES_PASSWORD_CHANGE;
      this.username = username;

      // Fire off requires password change event
      this.requiresPasswordChange({
        user: this,
        username,
        response,
        options: eventOptions,
      });
      return this.authorization;
    }
    // Successful login
    else if (response.status === 200 && response.ok) {
      // Check json
      if (!response.json) {
        throw new Error(
          'There was an error logging in: no data returned from server.',
        );
      }

      // Check for any state access
      if (!this.checkRawProfileDataForAnyStateAccess(response.json)) {
        let e = new Error(
          'Your user account does not have access to any state data; this is an error on our part.',
        );
        e.debug = e.debug || {};
        e.debug.username = username;
        throw e;
      }

      // Attach to user and cache
      this.authorization = constants.AUTHORIZATION.AUTHENTICATED;
      this.attachRawProfileData(response.json);
      this.updateCache();

      if (eventOptions !== false) {
        /**
         * Login event.
         *
         * @event User#login
         * @type {object}
         * @property {object} params Values passed along
         * @property {User} params.user User object
         * @property {string} params.username Username that was used
         * @property {object} params.response Response from API
         */
        this.emit('login', {
          user: this,
          username,
          response,
          options: eventOptions,
        });
      }

      return this.authorization;
    }
    // MFA
    else if (
      response.status === 401 &&
      response?.json?.error_class?.match(/mfa/i)
    ) {
      this.authorization = constants.AUTHORIZATION.REQUIRES_MFA;
      this.username = username;
      this.requiresMfaAuthorization(
        response.json.error_class.match(/sms/i) ? 'sms' : 'software',
      );
      return this.authorization;
    }
    // State access
    else if (
      response.status === 401 &&
      response?.json?.error_class?.match(/state.*assign/i)
    ) {
      let e = new Error(
        'Your user account does not have access to any state data; this is an error on our part.',
      );
      e.debug = e.debug || {};
      e.debug.response = response;
      e.debug.username = username;
      throw e;
    }
    // Specifically bad username and password
    else if (response.status === 401) {
      let e = new Error(
        `Unable to login, please make sure that your username and password are correct. ${
          response.json && response.json.msg
            ? '(' + response.json.msg + ')'
            : ''
        }`,
      );
      e.debug = e.debug || {};
      e.debug.response = response;
      e.debug.username = username;
      throw e;
    }
    else if (!response.ok) {
      let e = new Error(
        `There was an error logging in: ${
          response.json && response.json.msg
            ? response.json.msg
            : '(unknown error)'
        }`,
      );
      e.debug = e.debug || {};
      e.debug.response = response;
      e.debug.username = username;
      throw e;
    }
    else {
      let e = new Error(
        `Unable to login, please make sure that your username and password are correct. ${
          response.json && response.json.msg
            ? '(' + response.json.msg + ')'
            : ''
        }`,
      );
      e.debug = e.debug || {};
      e.debug.response = response;
      e.debug.username = username;
      throw e;
    }
  }

  /**
   * Authenticate an MFA code (after login).
   *
   * @param {string} code Authentication code
   * @param {any} eventOptions Any options to send to the login event
   * @fires User#login
   * @returns {string} What happened, see AUTHENTICATION_LEVELS in
   *   constants
   */
  async authenticateMfa(code, eventOptions = {}) {
    // Check values
    if (!code || !isString(code)) {
      throw new Error('Authentication code is required to authenticate MFA.');
    }

    // Make request
    let response;
    try {
      response = await apiRequest('authenticate_mfa', {
        body: {
          username: this.username,
          authentication_code: code,
          method: this.authorizationMfaType,
        },
      });
    }
    catch (e) {
      // More friendly for user
      throw new Error(
        `There was an error authenticating your code: ${e.toString()}`,
      );
    }

    // Successful login
    if (response.status === 200 && response.ok) {
      // Check json
      if (!response.json) {
        throw new Error(
          'There was an error logging in: no data returned from server.',
        );
      }

      // Check for any state access
      if (!this.checkRawProfileDataForAnyStateAccess(response.json)) {
        let e = new Error(
          'Your user does not have access to any state; this is an error on our part.',
        );
        e.debug = e.debug || {};
        e.debug.email = response.json.email;
        throw e;
      }

      // Attach to user and cache
      this.authorization = constants.AUTHORIZATION.AUTHENTICATED;
      this.attachRawProfileData(response.json);
      this.updateCache();

      /**
       * Login event.
       *
       * @event User#login
       * @type {object}
       * @property {object} params Values passed along
       * @property {User} params.user User object
       * @property {string} params.username Username that was used
       * @property {object} params.response Response from API
       */
      this.emit('login', {
        user: this,
        username: this.username,
        response,
        options: eventOptions,
      });
      return this.authorization;
    }

    // Code mismatch
    if (
      response &&
      response.json &&
      response.json.error_class.match(/codemismatch/i)
    ) {
      let e = new Error(
        'Code did not match; please make sure it is correct and try again.',
      );
      e.debug = e.debug || {};
      e.debug.response = response;
      throw e;
    }
    // Other error
    else {
      let e = new Error(
        `Unable to authenticate, please make sure that your code is correct. ${
          response.json && response.json.msg
            ? '(' + response.json.msg + ')'
            : ''
        }`,
      );
      e.debug = e.debug || {};
      e.debug.response = response;
      throw e;
    }
  }

  /**
   * Get profile data and update user object and cache
   *
   * @param {object} apiOptions Object of options to send to
   *   profile endpoint
   * @returns {boolean} true if profile update was succesful,
   *   otherwise throws error.
   */
  async fetchProfile(apiOptions = {}) {
    apiOptions.bypassCache = apiOptions.bypassCache === false ? false : true;

    // Make request
    let response;
    try {
      response = await this.api('get_account_info', apiOptions);
      handleFormattedResponseErrors(response, 'get_account_info');

      // Attach to user and cache
      this.attachRawProfileData(response.json);
      this.updateCache();
      return true;
    }
    catch (e) {
      // Capture exception here but pass on more friendly error
      console.error(e);
      captureException(e, { tags: { feature: 'user action - fetch profile' } });
      throw new Error('There was a problem getting your profile data.');
    }
  }

  /**
   * Update profile.  See constructor for translation
   * of fields and full list of options
   *
   * @param {object} profileData Data to change in profile
   * @param {string} profileData.first First name
   * @param {string} profileData.last Last name
   * @param {string} profileData.state Preferred state
   * @returns {object} Response from API
   */
  async updateProfile(profileData = {}) {
    // Filter incoming data
    let filteredProfileData = {};
    _each(this.profileProperties, (v, k) => {
      if (profileData && profileData[v] !== undefined) {
        filteredProfileData[v] = profileData[v];
      }
    });

    // Translate profile data
    let body = {};
    _each(this.profileProperties, (v, k) => {
      if (filteredProfileData && filteredProfileData[v] !== undefined) {
        body[k] = profileData[v];
      }
    });

    // Transform
    body = this.transformProfileDataToSend(body);

    // Make request
    let response;
    try {
      response = await this.api('update_account_info', {
        body,
      });
      handleFormattedResponseErrors(response, 'get_account_info', null);

      // Update profile
      this.profile = defaults(filteredProfileData, this.profile);
      this.updateCache();

      return response;
    }
    catch (e) {
      // Capture exception here but pass on more friendly error
      console.error(e);
      captureException(e, {
        tags: { feature: 'user action - update profile' },
      });
      throw new Error('There was a problem updating your profile.');
    }
  }

  /**
   * Check authentication via API
   *
   * @returns {boolean} User still has valid login tokens
   */
  async checkAuthentication() {
    let response;

    try {
      response = await this.api('is_authenticated');
      handleFormattedResponseErrors(response, 'is_authenticated', null);
      return true;
    }
    catch (e) {
      // If there is an error in getting this status, there is not
      // likely a UI component, so we send to Sentry.
      // We will also logout the user, as the API is likely not working
      // properly in this instance.
      // TODO: Is this the best behavior?
      console.error(e);
      captureException(e, {
        tags: { feature: 'user action - check authentication' },
      });

      this.initiateLogout();
      return false;
    }
  }

  /**
   * Is authenticated checks to see if authorization is defined.
   * Note that this is not an actual check, the API is needed for
   * that and can see @checkAuthentication()
   *
   * @param {boolean} logoutOnExpired Whether to logout if expired
   * @returns {boolean} True if user is current authenticated.
   */
  isAuthenticated(logoutOnExpired = false) {
    // TODO: This needs to be updated but not sure how…
    if (!!this.authorization && !!this.id) {
      return true;
    }
    else if (logoutOnExpired) {
      this.initiateLogout();
    }

    return false;
  }

  /**
   * Check if requires password update, which will get set
   * after initial login.
   *
   * TODO: Needs better name
   *
   * @returns {boolean} True if user requires password change.
   */
  isPasswordChangeRequired() {
    return (
      this.authorization === constants.AUTHORIZATION.REQUIRES_PASSWORD_CHANGE
    );
  }

  /**
   * Check to see if profile is filled out
   *
   * @returns {boolean} True if user has a first name and state
   */
  hasProfileFilled() {
    return this.profile && this.profile.first;
  }

  /**
   * Check to see if tos has been agreed to
   *
   * @returns {boolean} True if user has agreed to tos/pp
   */
  hasLegalAgreedTo() {
    return this.profile && this.profile.agreedTos;
  }

  /**
   * Determines if in a specific role
   *
   * @param {string} role Role to look for
   * @returns {boolean} True if user has role.
   */
  hasRole(role) {
    if (!isString(role)) {
      return false;
    }
    if (!this.roles) {
      return false;
    }

    return !!~this.roles.indexOf(role);
  }

  /**
   * Determines if the user has any of these roles
   *
   * @param {Array} roles Roles to look for
   * @param {boolean} permissiveOnNoRoles If true, an empty roles value
   *   will allow any user to return true.  Defaults to false.
   * @returns {boolean} True if user has any role in roles.
   */
  hasAnyRole(roles, permissiveOnNoRoles = false) {
    return (
      !!_find(roles, (r) => this.hasRole(r)) ||
      (permissiveOnNoRoles && (!roles || !roles.length))
    );
  }

  /**
   * Get array of state details of states that the user has access to.
   *
   * @returns {Array} Array of state config objects.
   */
  stateAccessOptions() {
    if (this.hasAnyRole(allStateAccessRoles)) {
      return _map(statesConfig);
    }

    if (this.stateAccess?.length) {
      return _filter(
        sortBy(
          _map(this.stateAccess, (s) => _find(statesConfig, { apiId: s })),
          'name',
        ),
      );
    }

    return [];
  }

  /**
   * Get the state config for the preferred state, or the first state that the
   * user has access to.
   *
   * Note that if the user does not have access to any state, then this will
   * return undefined, and the interface may not handle that situation well.
   *
   * @returns {object} Object from state config if found, otherwise undefined.
   */
  preferredStateAccessDetails() {
    let options = this.stateAccessOptions();

    // No options available (no state access)
    if (!options?.length) {
      return undefined;
    }

    // Preference from profile
    if (this.profile.state && _find(options, { apiId: this.profile.state })) {
      return _find(options, { apiId: this.profile.state });
    }

    // No preference, just send along the first one
    return options[0];
  }

  /**
   * Can access a specific state.
   *
   * @param {string|object} state State can be an two letter abbreviation, api ID, or
   *   a state details config object.
   * @returns {boolean} Whether the user has access to that state.
   */
  hasStateAccess(state) {
    let accessOptions = this.stateAccessOptions();

    if (!state || !accessOptions?.length) {
      return false;
    }
    else if (isString(state) && state.length === 2) {
      return !!_find(accessOptions, { id: state });
    }
    else if (isString(state)) {
      return !!_find(accessOptions, { apiId: state });
    }
    else if (isObject(state)) {
      return !!_find(accessOptions, { id: state.id });
    }

    return false;
  }

  /**
   * Can access any state.
   *
   * @returns {boolean} Whether the user has access to any state.
   */
  hasAnyStateAccess() {
    let accessOptions = this.stateAccessOptions();

    return !!accessOptions?.length;
  }

  /**
   * Looks at a raw profile response (when logging in) to determine
   * if the user has access to any state.
   *
   * @param {object} response Raw profile response from API.
   * @returns {boolean} Whether the user has access to any state.
   */
  checkRawProfileDataForAnyStateAccess(response) {
    let roles = response['cognito:groups'];

    if (!roles || !roles.length) {
      return false;
    }

    // Check for all state access roles
    let allStateAccess = !!_find(
      roles,
      (r) => !!~allStateAccessRoles.indexOf(r),
    );
    if (allStateAccess) {
      return true;
    }

    // Parse state specific roles
    let stateAccess = _filter(
      _map(roles, (r) => {
        let m = r.match(this.stateAccessRoleMatch);
        return snakeCase(m?.[1]);
      }),
    );

    return !!stateAccess?.length;
  }

  /**
   * Set initial password or otherwise required password change.
   * The API requires that the user has been logged in to do
   * this.
   *
   * @param {string} username Username to set password for.
   * @param {string} oldPassword Old password.
   * @param {string} newPassword New password.
   * @param {any} loginEventOptions Any options to pass to the login event, defaults
   *   to false to not fire the login event.
   * @fires User#required-password-change
   * @returns {boolean} True if successful, throws error if not.
   */
  async requiredPasswordChange(
    username,
    oldPassword,
    newPassword,
    loginEventOptions = false,
  ) {
    username = username || this.username;

    if (!username) {
      throw new Error('Username needed to initialize password');
    }
    if (!oldPassword) {
      throw new Error('Old password needed to initialize password');
    }
    if (!newPassword) {
      throw new Error('New password needed to initialize password');
    }

    try {
      let response = await this.api('set_initial_password', {
        body: {
          username,
          password: oldPassword,
          new_password: newPassword,
        },
      });
      handleFormattedResponseErrors(response, 'set_initial_password', null);

      // Login to get profile data
      await this.login(username, newPassword, loginEventOptions);

      /**
       * Initialize password event.
       *
       * @event User#required-password-change
       * @type {object}
       * @property {object} params Values passed along
       * @property {User} params.user User object
       * @property {string} params.username Username that was used
       * @property {object} params.response Response from API
       */
      this.emit('required-password-change', {
        user: this,
        username,
        response,
      });
    }
    catch (e) {
      console.error(e);
      captureException(e, {
        tags: { feature: 'user action - set initial password' },
      });

      if (e.toString().match(/access.*state/i)) {
        throw e;
      }
      else {
        throw new Error(
          'Initialize password failed; Make sure you have entered in your temporary password correctly and try again.',
        );
      }
    }
  }

  /**
   * Change password for a logged in user
   *
   * @param {string} oldPassword Old password.
   * @param {string} newPassword New password.
   * @fires User#change-password
   * @returns {boolean} True if successful, throws error if not.
   */
  async changePassword(oldPassword, newPassword) {
    if (!oldPassword) {
      throw new Error('Old password needed to update password');
    }
    if (!newPassword) {
      throw new Error('New password needed to update password');
    }

    try {
      let response = await this.api('change_password', {
        body: {
          password: oldPassword,
          new_password: newPassword,
        },
      });
      handleFormattedResponseErrors(response, 'change_password', null);

      /**
       * Change password event.
       *
       * @event User#change-password
       * @type {object}
       * @property {object} params Values passed along
       * @property {User} params.user User object
       * @property {string} params.username Username that was used
       * @property {object} params.response Response from API
       */
      this.emit('password-change', {
        user: this,
        username: this.username,
        response,
      });

      return true;
    }
    catch (e) {
      console.error(e);
      captureException(e, {
        tags: { feature: 'user action - change password' },
      });
      throw new Error(
        'Change password failed; make sure your old password was entered correctly and please try again.',
      );
    }
  }

  /**
   * Require password change; will trigger requires-password-change event.
   *
   * @param {object} params Parameters to pass to the event.
   * @fires User#requires-password-change
   */
  requiresPasswordChange(params) {
    this.authorization = constants.AUTHORIZATION.REQUIRES_PASSWORD_CHANGE;

    /**
     * Requires password change event.
     *
     * @event User#requires-password-change
     * @type {object}
     * @property {object} params Values passed along
     * @property {User} params.user User object
     * @property {string} params.username Username that was used
     */
    this.emit(
      'requires-password-change',
      params || {
        user: this,
        username: this.username,
      },
    );
  }

  /**
   * Require MFA authentication; will trigger requires-mfa-authorization event.
   *
   * @fires User#requires-mfa-authorization
   * @param {string} mfaType MFA type
   * @property {string} mfaType MFA type
   */
  requiresMfaAuthorization(mfaType) {
    this.authorization = constants.AUTHORIZATION.REQUIRES_MFA;
    this.authorizationMfaType = mfaType;

    /**
     * Requires MFA event.
     *
     * @event User#requires-mfa-authorization
     * @type {object}
     * @property {object} params Values passed along
     * @property {User} params.user User object
     * @property {string} params.username Username that was used
     * @property {string} params.mfaType MFA type
     */
    this.emit('requires-mfa-authorization', {
      user: this,
      username: this.username,
      mfaType,
    });
  }

  /**
   * Attach API and wrap a little
   */
  attachApi() {
    // Create method
    this.api = async (...args) => {
      // Check args
      if (args.length === 0) {
        throw new Error('API request requires at least a path.');
      }
      else if (args.length === 1) {
        args.push(undefined);
      }
      else if (args.length > 2) {
        args = args.slice(0, 2);
      }

      // Options
      let options = args[1] || {};
      options.logoutOn401 = options.logoutOn401 === false ? false : true;

      // Make request
      let response = await apiRequest(...args, this);

      // Check for 401 and errors that mean the user does not have access at all
      if (
        options.logoutOn401 &&
        response.status === 401 &&
        response.json?.error_class?.match(this.logoutErrorTypeMatch)
      ) {
        this.initiateLogout();
      }
      else if (response.status === 403) {
        this.requiresPasswordChange();
      }

      return response;
    };

    // Bind
    this.api = bind(this.api, this);
  }

  /**
   * Load cached values and attach to object.
   */
  loadCache() {
    // Assume cache has valid properties
    let c = window.localStorage.getItem(this.cacheKey);

    let parsed;
    try {
      parsed = JSON.parse(c);
    }
    catch (e) {
      // Possible corruption
      this.clearCache();
    }

    // Attach data
    if (isPlainObject(parsed)) {
      Object.keys(parsed).forEach((k) => {
        this[k] = parsed[k];
      });
    }
  }

  /**
   * Delete cache
   */
  clearCache() {
    window.localStorage.removeItem(this.cacheKey);
  }

  /**
   * Update cache
   */
  updateCache() {
    window.localStorage.setItem(
      this.cacheKey,
      JSON.stringify(pick(this, this.cachedProperties)),
    );
  }
}

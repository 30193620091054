/**
 * Configuration for the locale matrix metrics to choose
 * from.
 *
 * Note: Demographics.js aligns with most of the metrics and
 * those do not need to be duplicated here.
 */

const localeMatrixMetrics = [
  {
    id: 'spacing',
    apiId: ['spacing'],
    name: 'Snapshot Spacing',
    description:
      'The amount of time between voter registration data snapshots.',
    units: 'days',
    noCheckVotersAffected: false,
    weight: 9999,
  },
  {
    id: 'voters-affected',
    apiId: ['voters_affected'],
    name: 'Voters Affected',
    description: 'The total number of voters affected by this change set.',
    units: 'voters',
    noCheckVotersAffected: true,
    weight: -10,
  },
  // {
  //   id: 'temporal-deviations',
  //   apiId: ['temporal_deviations'],
  //   name: 'Temporal Deviations',
  //   units: 'unknown'
  // },
  // {
  //   id: 'total',
  //   apiId: ['total_count'],
  //   name: 'Total Count',
  //   units: 'records changed'
  // },
];

export default localeMatrixMetrics;

// Dependencies
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import moment from 'moment';

/**
 * Tries to turn input into moment object.
 *
 * @param {*} input Input to try to make into a Moment
 * @param {string} inputLayout The input layout format when trying to parse a string.
 *   For default see: https://momentjs.com/docs/#/parsing/string/
 * @param {boolean} [utc=true] Boolean whether to assume UTC time or local time.
 * @param {string} [timestamp='milliseconds'] Type of timestamp to parse, defaults
 *   to milliseconds which is what moment and JS date uses by defaults, but can
 *   also be 'seconds'.
 * @returns {moment|undefined} Moment object or throws error if
 *   not valid or empty.
 */
export default function toMoment(
  input,
  inputLayout,
  utc = true,
  timestamp = 'milliseconds',
) {
  let m;
  let parser = utc ? moment.utc : moment;

  if (isNumber(input)) {
    m = timestamp === 'seconds' ? moment.unix(input).utc() : parser(input);
  }
  else if (isString(input)) {
    m = parser(input, inputLayout);
  }
  else if (input) {
    m = parser(input);
  }

  if (!m || !m.isValid()) {
    throw new Error(`Unable to parse number from ${input}`);
  }

  return m;
}

/**
 * Method to attach all locales to states
 */

import clone from 'lodash/clone';
import _each from 'lodash/each';
import orderBy from 'lodash/orderBy';

import toStringSort from '../convert/to-string-sort';

/**
 * Takes the states config object, and attached an allLocales array
 * that is a combination of all the locales.
 *
 * @param {object} states States config
 * @param {Array} localeTypes Locale types config
 * @param {string} idSeparator Separator to use for id
 * @returns {Array} An updated version of the states;
 */
export default function statesAttachLocales(
  states,
  localeTypes,
  idSeparator = '---',
) {
  _each(states, (state) => {
    state.allLocales = [];

    localeTypes.forEach((localeType) => {
      if (state[localeType.set]) {
        state[localeType.set].forEach((locale) => {
          let origLocale = clone(locale);
          // Note that this will change the locales in their separate arrays, since they are
          // objects and passed by reference.

          locale.apiId = locale.id;
          locale.id = `${localeType.id}${idSeparator}${locale.id}`;
          locale.sourceName = locale.name;
          locale.name = localeType.outputLocale
            ? localeType.outputLocale(origLocale, state.id)
            : locale.name;
          locale.nameShort = localeType.outputLocaleShort
            ? localeType.outputLocaleShort(origLocale, state.id)
            : locale.name;
          locale.sort = localeType.outputLocaleSort
            ? localeType.outputLocaleSort(origLocale, state.id)
            : toStringSort(locale.name);
          locale.localeTypeId = localeType.id;

          // Repeated for use in certain components
          locale.group = localeType.id;

          state.allLocales.push(locale);
        });
      }
    });

    // Sort
    state.allLocales = orderBy(state.allLocales, ['localeTypeId', 'sort']);
  });

  return states;
}

/**
 * Forgot password page
 */

// Dependencies
import React from 'react';
import { Link } from 'react-router-dom';

import { accountEmail } from '../../../config';
import CoreComponent from '../../../extensions/CoreComponent';
import PageTitle from '../../document/PageTitle';
import EmailLink from '../../links/EmailLink';
import ForgotPasswordConfirmForm from '../../login/ForgotPasswordConfirmForm';
import ForgotPasswordForm from '../../login/ForgotPasswordForm';

// Forgot password page component
export default class ForgotPasswordPage extends CoreComponent {
  static propTypes = {};
  static defaultProps = {};

  // Default state
  state = {
    initiated: false,
    confirmed: false,
  };

  // Main render
  render() {
    const { initiated, confirmed, username } = this.state || {};

    return (
      <div className="container-lg pt-2 pt-md-4 mb-2">
        <PageTitle title="Forgot password" />

        <h1 className="h2">Forgot your password</h1>

        <div className="row">
          <div
            className="col-auto col-md-4"
            style={{ opacity: initiated ? 0.5 : 1 }}
          >
            <h2 className="h3">Step 1: Reset</h2>

            <ForgotPasswordForm
              onResetSuccess={(values) =>
                this.setState({
                  initiated: true,
                  username: values.username,
                })
              }
              onResetError={() => this.setState({ initiated: false })}
            />

            <p>
              If you do not remember your username,{' '}
              <EmailLink
                email={accountEmail}
                subject="Forgotten username"
                body="I need help remembering my account details."
              >
                contact an administrator
              </EmailLink>
              . Already{' '}
              <a
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ initiated: true });
                }}
                href="#reset"
                className="like-text"
              >
                have a verification code
              </a>
              ?
            </p>
          </div>

          {!!initiated && (
            <div
              className="col-auto col-md-4"
              style={{ opacity: confirmed ? 0.5 : 1 }}
            >
              <h2 className="h3">Step 2: Set new password</h2>

              <ForgotPasswordConfirmForm
                username={username}
                onConfirmSuccess={() => this.setState({ confirmed: true })}
                onConfirmError={() => this.setState({ confirmed: false })}
                onConfirmCancel={() =>
                  this.setState({
                    initiated: false,
                    confirmed: false,
                  })
                }
              />
            </div>
          )}

          {!!confirmed && (
            <div className="col-auto col-md-4">
              <h2 className="h3">Step 3: Login</h2>

              <p>
                Your password has been reset. Now you can log into VoteShield.
              </p>

              <Link to="/login" className="button">
                Login
              </Link>
            </div>
          )}
        </div>
      </div>
    );
  }
}

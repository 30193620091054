/**
 * New features banner.
 *
 * To update content:
 *
 * 1. Update the cookie name
 * 2. Update banner content
 *
 * OR
 *
 * To turn off:
 *
 * 1. Set bannerEnabled state to false
 */

// Dependencies
// Styles
import './NewFeaturesBanner.scss';

import React from 'react';
import { Link } from 'react-router-dom';

import { cookies } from '../../config';
import CoreComponent from '../../extensions/CoreComponent';
import IconMaterial from '../icons/IconMaterial';

// Header component
export default class NewFeaturesBanner extends CoreComponent {
  constructor(props) {
    super(props);

    // Set what cookie name we are using
    this._bannerClosedCookieName = 'vs-nfbanner-cl-2020-09-a';
    this._bannerClosedCookieOptions = {
      maxAge: 60 * 60 * 24 * 30,
    };

    // Default state
    this.state = {
      // Use this option to enable or disable the banner; make sure to update the content.
      bannerEnabled: false,
      bannerClosed: cookies.get(
        this._bannerClosedCookieName,
        this._bannerClosedCookieOptions,
      ),
    };

    // Listen for cookie changes
    this._cookieListener = cookies.addChangeListener(this.handleCookieChange);
  }

  // Props
  static propTypes = {};
  static defaultProps = {};

  // Handle cooke change
  handleCookieChange = ({ name, value }) => {
    if (name === this._bannerClosedCookieName) {
      this.setState({
        bannerClosed: !!value,
      });
    }
  };

  // Close banner
  closeBanner = () => {
    cookies.set(
      this._bannerClosedCookieName,
      '1',
      this._bannerClosedCookieOptions,
    );
  };

  render() {
    const { bannerClosed, bannerEnabled } = this.state || {};

    if (bannerClosed || !bannerEnabled) {
      return '';
    }

    return (
      <div className="new-features-banner container-lg">
        <p>
          <strong>New Feature</strong>: Receive county-level anomalies in your
          email notifications by adding your preferred county to your profile.
        </p>

        <Link
          className="button compact success new-feature-action"
          role="button"
          to="/account/profile"
        >
          Select your county
        </Link>

        <button
          className="button compact link"
          onClick={this.closeBanner}
          title="Close new features message"
        >
          <IconMaterial id="clear" />
        </button>
      </div>
    );
  }
}

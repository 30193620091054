/**
 * The labels that will show up by default.
 *
 * TODO: Make this customizable by change type.
 */

const primaryLabels = [
  'no_activity_mailing_undeliverable',
  'no_activity_mailing_responded',
  'deceased',
  'election_day',
  'criminal_conviction',
  'data_normalization',
  'eric',
  'ncoa_initial_processing',
  'ncoa_or_similar_mailing_response_processing',
  'followup_requested',
  'forwarded_ncoa_mailing_no_response',
];

export default primaryLabels;
